import React,{useState, useEffect} from 'react';
import {doGet, doPatch, doDelete, doDownloadPdf} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Search from '@material-ui/icons/Search';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { format, getYear, getMonth, lastDayOfMonth } from 'date-fns'
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme'
import numeral from 'numeral'
import TablePagination   from '@material-ui/core/TablePagination';
import { generalListOptionMapper } from 'utils/mappers.js';
import ProductQuantityTable from './ProductQuantityTable';
import ProductOmsetTable from './ProductOmsetTable';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';


const Summary =(props)=> {

  let { path, url } = useRouteMatch();
  let history = useHistory();
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const [summaries, setsummaries] = useState([]);
  const [selectedSummary, setselectedSummary] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)

  const [summaryOptions, setsummaryOptions] = useState([
    {label:'Product Quantity', value:"product_quantity"},
    {label:'Product Omset', value:"product_omset"}
  ]);


  const [filters, setfilters] = useState({
    start:new Date(getYear(new Date()),getMonth(new Date()),1),
    end: lastDayOfMonth(new Date()), 
    type: null
  });
  const [openDialog, setopenDialog] = React.useState(false);
  const [dialogWidth, setdialogWidth] = useState('sm');
  const [dialogContent, setdialogContent] = useState(null);
  const [dialogTitle, setdialogTitle] = useState('title');
 
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  
  const back =()=>{
    history.goBack()
  }


  const getsummary =async()=>{
    const params={
      type:filters.type?filters.type.value:null,
      start: format(filters.start, 'yyyy-MM-dd'),      
      end:format(filters.end, 'yyyy-MM-dd')
    }
 
    const response = await doGet(endpoint.summary, params, getDefaultHeader());        
    if(response && response.data ){
      const summaries = response.data      
      setsummaries(summaries.map(summary=>({
        name:summary.name,
        quantity:summary.quantity,
        omset:summary.omset        
        })))
    }      
    
  }  

  const closeDialog =()=>{    
    setopenDialog(false)
    setselectedSummary(null)
  }  
  
  const filtersChange =(fieldName, event)=>{    
    let newFilters = {...filters}
    let value = event

    newFilters[fieldName] = value    
    setfilters(newFilters)
    setsummaries([])
  }

  const SummaryTable=(props)=>{
    const {summaries, type} = props
    if(type=="product_quantity"){
      return <ProductQuantityTable summaries={summaries}/>
    }else
    if(type=="product_omset"){
      return <ProductOmsetTable summaries={summaries}/>
    }else{
      return <div>{type}</div>
    }
  }

  useEffect(() => {
    if(filters){
     //console.log(getYear(new Date()),getMonth(new Date()),);
    }        
   }, [filters])


  if(user && user.token){
    return (
      <React.Fragment>
        <CssBaseline/>
        <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
            <Grid item xs={1}>              
                <IconButton color="primary" aria-label="go back" component="span" onClick={back}>
                  <ArrowBack />
                </IconButton>              
            </Grid>
            <Grid container item xs={11} justify="flex-end" alignItems="center" >                                                    
                
            </Grid>          
            
            <Paper elevation={1} style={{width:'100%', margin:'4px 0', padding:8}}>
                <Grid container spacing={1} alignItems="center" >                                                                                      

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item xs={12} md={5} style={{paddingTop:8}}>                      
                        <DatePicker
                          autoOk={true}
                          value={filters.start}
                          onChange={(e)=>filtersChange("start",e)}
                          label="Start"
                          variant="inline"
                        />
                        <DatePicker
                          autoOk={true}
                          value={filters.end}
                          onChange={(e)=>filtersChange("end",e)}
                          label="End"
                          variant="inline"
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>

                    <Grid item xs={6} md={3} style={{paddingTop:8}}>                      
                      <Select                
                          name="summary"
                          placeholder = "Summary Type"
                          options={summaryOptions}
                          onChange={(e)=>filtersChange("type",e)}
                          value={filters.type} 
                          styles={selectCustomZindex}
                          isClearable
                      />
                    </Grid>                    

                    <Grid item xs={3} >
                      <IconButton onClick={getsummary} size="small" color="primary" aria-label="go back" component="span" style={{margin:'0 8px'}}>
                        <Search />
                      </IconButton>   
                    </Grid>      
                    
                </Grid>
                                     
            </Paper>            
            
                <Grid container justify="center" style={{margin:'4px 0'}}>                                           
                    <TableContainer component={Paper}>
                        {
                          filters.type && 
                          <SummaryTable summaries={summaries} type={filters.type.value}/>                        
                        }
                        
                    </TableContainer>
                </Grid>            
            
            
        </Grid>

        <Dialog
        fullWidth={true}
        maxWidth={dialogWidth}
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

const useStyles = makeStyles((theme) => ({
    marginTop16:{
      marginTop:16  
    },  
    marginH4:{
      marginLeft:4,
      marginRight:4,
    },  
    root: {
      padding: theme.spacing(1)
    }
    
    
  }));


export default Summary;

import React,{useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Confirmation from 'components/Confirmation.js';

const ApprovalForm = (props)=>{
    const {action, closeDialog, approvalDetailChange}=props
    const [reason, setreason] = useState('');
    
    const reasonChange=(eventValue)=>{
      let value = eventValue.target.value
      setreason(value)
    }

    return(
    <Confirmation 
    action={()=>action(reason)} 
    actionName="Lanjutkan" 
    handleClose={closeDialog} 
    message="Berikan alasan atau keterangan"
    title="Lanjutkan"
    >
      <Grid container item xs={12}>
        <TextField
            id="Reason"
            label="Keterangan"
            multiline
            rows={4}
            defaultValue=""
            variant="outlined"
            fullWidth
            value={reason}
            onChange={reasonChange}
          />
      </Grid>
    </Confirmation>
    )
  }

  export default ApprovalForm;
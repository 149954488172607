import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const Pill = (props) => {
    
    const {label, value} = props

    const classes = useStyles();

    return(
        <Grid container justify="space-evenly" wrap="nowrap" className={classes.sizePill}>
            <Grid container justify="center" alignItems="center" className={classes.Pillabel}>
                <span style={{margin:'0 4px'}}>{label}</span>
            </Grid>
            <Grid container justify="center" alignItems="center" style={{padding:4}}>
                {value}
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    sizePill:{        
        height:'fit-content',
        minWidth:75,
        width:"auto",        
        borderRadius: 50,
        border:'1px solid #e5e5e5',
        margin: '0 2px 0 2px'
    },
    Pillabel:{        
        whiteSpace:'nowrap',
        background:'blue',
        color:'white',
        borderTopLeftRadius: 50,
        borderBottomLeftRadius: 50,
        padding:'2px 0 2px 0'
    }
}))

export default Pill




import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {cartDummy} from 'utils/dummy'
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Remove from '@material-ui/icons/Remove';
import Search from '@material-ui/icons/Search';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import DebouncedTextField from 'components/DebouncedTextField.js';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Pill from 'components/Pill';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { format } from 'date-fns'
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme'
import numeral from 'numeral'
import Confirmation from 'components/Confirmation.js';
import Conditional from 'components/Conditional';
import Protected from 'components/Protected';


const DeliveryForm = (props)=>{
  const {deliverOrder, closeDialog, orderDetailChange}=props
  const [pickupBy, setpickupBy] = useState('');
  
  const pickupByChange=(eventValue)=>{
    let value = eventValue.target.value
    setpickupBy(value)
    orderDetailChange('pickupBy',eventValue)
  }

  return(
  <Confirmation 
  action={()=>deliverOrder(pickupBy)} 
  actionName="Lanjutkan" 
  handleClose={closeDialog} 
  message="Pastikan mencatat nama penerima"
  title="Serah terima barang"
  >
    <Grid container item xs={12}>
      <TextField
          id="Reason"
          label="Nama Penerima"
          multiline
          rows={4}
          defaultValue=""
          variant="outlined"
          fullWidth
          value={pickupBy}
          onChange={pickupByChange}
        />
    </Grid>
  </Confirmation>
  )
}

export default DeliveryForm;
import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from 'react-select'
import {productsDummy,sizeOptionsDummy} from 'utils/dummy'
import { generalListOptionMapper } from 'utils/mappers.js';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DebouncedTextField from 'components/DebouncedTextField.js';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';

const ReduceStock = (props) => {
    const {closeDialog, product, user, getProducts} = props

    const classes = useStyles();
    const [state, setstate] = useState([]);
    const [reasonOptions, setreasonOptions] = useState([
        { label:"rusak", value:1},
        { label:"hilang", value:2}
    ]);
    
    const getDefaultHeader =()=>{
        return {"Authorization":`Bearer ${user.token}`}
    }

    const submit =async()=>{
        
        const valids = state.filter(stock=>(stock.reason!==null && parseInt(stock.quantity) > 0))
        
        if(valids.length<1){
            return
        }
        
        const payload = {
            id:product.id,
            stocks:valids
                .filter(stock=>parseInt(stock.quantity) > 0)
                .map(stock=>({
                sizeId : stock.size.id,
                reason : stock.reason.value,
                quantity : parseInt(stock.quantity)
            }))
        }                
        
        const response = await doPost(endpoint.product_reduce, payload,"reduce stock",getDefaultHeader())
        
        if(response && response.error){
            
        }else
        {      
            closeDialog()
            getProducts()
        }
    }
    
    const handleChange =(stock,fieldName,e)=>{
        let newStock = state.filter(item => ( stock.id === item.id))[0]
        
        newStock[fieldName] = e
        
        const otherStocks = state.filter(item => !( stock.id === item.id ))
        const sorted = [...otherStocks, newStock].sort((a, b) => (a.size.order > b.size.order) ? 1 : -1)
        setstate(sorted)        
    }
    
    
    useEffect(() => {
        if(product){
            const stocks = product.stocks.map(stock=>({
                id:stock.id,
                size:stock.size,
                quantity:0,
                reason:null
            }))
            setstate(stocks)
        }
    }, [product])
    

    return(
        <Grid container alignItems="flex-start" alignContent="flex-start" className={classes.root}>
            
            <Grid container justify="center" alignItems="flex-start" style={{marginTop:16}}>
                {
                    state.map(stock=>(
                        <Paper elevation={2} style={{width:'100%', margin:'8px 0', padding:8}}>
                            <Grid container >
                                <Grid container item xs={6} md={5} alignItems="center">
                                    <Typography variant="h6">{product.name}</Typography>
                                </Grid>
                                <Grid container item xs={6} md={2} alignItems="center">
                                    <Typography variant="h6">{stock.size.size_label}</Typography>
                                </Grid>
                                
                                <Grid container item xs={6} md={3} alignItems="center">
                                    <Select                
                                    name="size"
                                    placeholder = "pilih penyebab"
                                    options={reasonOptions}
                                    value={stock.reason}
                                    onChange={(e)=>{handleChange(stock,'reason',e)}}
                                    styles={selectCustomZindex}
                                    />
                                </Grid>
                                
                                <Grid container item xs={3} md={1} alignItems="center">
                                    <DebouncedTextField
                                        margin="dense"
                                        id="quantity"
                                        placeholder="quantity"                                                                                   
                                        fullWidth
                                        variant="outlined"
                                        value={""+stock.quantity}
                                        type="number"
                                        onChange={(e)=>{handleChange(stock,'quantity',e)}}
                                        style={{margin:'4px 0'}}
                                    />
                                </Grid>
                                
                            </Grid>
                        </Paper>
                        
                        
                    ))
                }
            </Grid>

            <Grid container justify="flex-end" alignItems="center" style={{margin:'8px 0'}}>                

                <Button  variant="contained" onClick={submit} color="primary">
                    Kurangi Stok
                </Button>

            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    root:{
        height:500
    }
}))

const selectCustomZindex = {
    control: (base, state) => ({
        ...base,
        minWidth: "150px",
        margin: "0 4px"
    }),
    container: (base, state) => {
        return {
            ...base,
            flex: 1,
            zIndex: state.isFocused ? "1100" : "1" //Only when current state focused
        };
    }
};

export default ReduceStock




import React,{ useState,useEffect }  from 'react';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserLogin, logout, setUnpaidBill } from "reduxs/actions";
import {doPost, doGet} from "utils/apiUtil.js"
import {endpoint} from 'utils/constants.js'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles.js';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useLocalStorage } from 'react-use';
import Information from 'components/Information';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import CircularProgress from '@material-ui/core/CircularProgress';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      kunjungi juga website sekolah di {' '}
      <Link color="inherit" href="https://pahoa.sch.id/">
        pahoa.sch.id
      </Link>
    </Typography>

  );
}

export default function Login() {
  const [persistedUser, persistUser, clearPersistedUser] = useLocalStorage('user', {});
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const unpaidBill = useSelector(state => state.unpaidBill);
  const [openDialog, setopenDialog] = React.useState(false);
  const [dialogWidth, setdialogWidth] = useState('sm');
  const [dialogContent, setdialogContent] = useState(null);
  const [dialogTitle, setdialogTitle] = useState('title');
  
  const [isLoading, setisLoading] = useState(false);
  const [state, setstate] = useState({
    username:"",
    password:"",
    showPassword:false
  });
  const [errors, seterrors] = useState({
    username:null,
    password:null
  });


  const getDefaultHeader =()=>{
    return {}
  }

  const closeDialog =()=>{    
    setopenDialog(false)
  }


  const submit = async()=>{    
    setisLoading(true)
    let valid = true
    let newErrors = {
      username:null,
      password:null
    }

    if(state.username.trim()  === ""){
      valid = false      
      newErrors['username'] = "email can not be empty"
    }
    if(state.password.trim()  === ""){
      valid = false
      newErrors['password'] = "password can not be empty"
    }

    if(valid){           
      const payload = {
        username:state.username,
        password:state.password
      }
      const response = await doPost(endpoint.login, payload,"login",getDefaultHeader())
      
      if(response && response.error){
        for (let key in response.error.errors) {
          newErrors[key] = response.error.errors[key]
        }
        showLoginError()
        
      }else
      if(response && response.data){
        
        let user = response.data.user
        
        user = {...user, token:response.data.token}
        persistUser(user)      
        dispatch(setUserLogin(user))  
        getUnpaidBill(user)
      }      
    }


    seterrors(newErrors)
    setisLoading(false)
    
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value=null

    if(fieldName==="showPassword"){
      value = eventValue
    }else{
      value = eventValue.target.value
    }
    
    newState[fieldName] = value
    setstate(newState)
  }

  const getUnpaidBill =async(user)=>{
    const defaultHeader =  {"Authorization":`Bearer ${user.token}`}
    const payload = {}

    const response = await doGet(endpoint.unpaid_bill, payload,defaultHeader)    
    
    if(response && !response.error){      
      const unpaids = response.data
      if(unpaids && unpaids.length > 0){
        clearPersistedUser()
        dispatch(logout())
        setUnpaidBill(unpaids)
        
      }
    }
  }

  const showUnpaidBillAlert = ()=>{
    setdialogTitle('Tagihan belum lunas')          
    setdialogContent(
      <Information 
      handleClose={closeDialog} 
      message="anda dapat melanjutkan pembelian setelah melunasi tagihan anda"
      />
    )    
    setdialogWidth('sm')
    setopenDialog(true)
  }

  const showLoginError = ()=>{
    setdialogTitle('Login failed')          
    setdialogContent(
      <Information 
      handleClose={closeDialog} 
      message="email atau password salah"
      />
    )    
    setdialogWidth('sm')
    setopenDialog(true)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if(persistedUser){
      dispatch(setUserLogin(persistedUser))  
    }
  }, [persistedUser])

  useEffect(() => {
    if(unpaidBill && unpaidBill > 0){
      showUnpaidBillAlert()
    }
  }, [unpaidBill])


  if(user && user.roles){
    if(user.roles.id==2)  {
      return <Redirect to={{ pathname: "/home/store" }} />
    }else{
      return <Redirect to={{ pathname: "/admin/product" }} />
    }
    
  }else{

  return (
    <>
    <Grid container>
      <Hidden xsDown>
        <Grid container justify="center" alignItems="center" item sm={6} className={classes.left} > 
          <Grid item xs={6} >         
          <img src='/storefront.svg' className={classes.image}/>
          </Grid>  
        </Grid>
      </Hidden>      
      
      <Grid container justify="center" item xs={12} sm={6} style={{padding:16}} >
      <div className={classes.paper}>
        <img src='/ujps.png' width="85" height="80"/>
        <Typography component="h1" variant="h5">
          Toko Pahoa
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            error={errors.username}
            helperText={errors.username}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="email"
            name="username"
            autoComplete="username"
            autoFocus
            value={state.username}
            onChange={(e)=>handleChange("username",e)}
          />

          <FormControl autoComplete="current-password" required error={errors.password} fullWidth className={{}} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            
            id="outlined-adornment-password"
            type={state.showPassword ? 'text' : 'password'}
            value={state.password}
            onChange={(e)=>handleChange("password",e)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e)=>handleChange("showPassword",!state.showPassword)}
                  onMouseDown={handleMouseDownPassword}
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
          <FormHelperText id="component-error-text">{errors.password}</FormHelperText>
        </FormControl>
         
          
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submit}
            disabled={isLoading}
          >

            {isLoading ? (
                 <Box sx={{ display: 'flex' }}>
                 <CircularProgress size={20}/> Processing....
                </Box>
            ):('Login')}
           
            
          </Button>
          <Grid container justify="space-between" style={{marginTop:24}}>
            <Grid item>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Register"}
              </Link>
            </Grid>
          </Grid>
          <Grid container style={{marginTop:24}}>
            <Grid item>
              <Link href="/resend-activation" variant="body2">
                Resend activation email
              </Link>
            </Grid>            
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      </Grid>
    </Grid>

    <Dialog
    fullWidth={true}
    maxWidth={dialogWidth}
    open={openDialog}
    onClose={closeDialog}
    aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle id="max-width-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
            {dialogContent}
        </DialogContent>
    </Dialog>
    </>
      
      
  );
  }
}

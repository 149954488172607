import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Protected from 'components/Protected';
import Link from '@material-ui/core/Link';


const Sidebar =(props)=> {
  const {user, itemClick, activeMenu, justify, onClose} = props
  const classes = useStyles();

  if(user){
    return (
      <Grid container justify={justify} alignItems='flex-start' alignContent="flex-start" className={classes.sideBarListContainer}>
        <Hidden lgUp>
          <Grid container justify="space-between" alignContent="center" alignItems="center" style={{padding:"0 8px 0 8px"}}>          
            <Typography variant="h6" color="primary">Toko Pahoa Menu</Typography>
            <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
              <CloseIcon color="primary"/>
            </IconButton>
          </Grid>
        </Hidden>
        <List className={classes.list}>          
          <Protected user={user} allowedCodes="std">
              <ListItem
              button
              onClick={()=>itemClick("/home/store")}
              key={1}
              selected={activeMenu==="/home/store"}>
              <ListItemIcon> <Icon>store</Icon> </ListItemIcon>
              <ListItemText primary="Store"/>
              </ListItem>
              <ListItem
                button
                onClick={()=>itemClick("/home/cart")}
                key={2}
                selected={activeMenu==="/home/cart"}>
                <ListItemIcon> <Icon>shopping_cart</Icon> </ListItemIcon>
                <ListItemText primary="Cart"/>
              </ListItem>   
              <ListItem
                button
                onClick={()=>itemClick("/home/order")}
                key={4}
                selected={activeMenu==="/home/order"}>
                <ListItemIcon> <Icon>shopping_bag</Icon> </ListItemIcon>
                <ListItemText primary="Orders"/>
              </ListItem>                  
          </Protected>

          <Protected user={user} allowedCodes="emp">
              <ListItem
                button
                onClick={()=>itemClick("/admin/product")}
                key={3}
                selected={activeMenu==="/admin/product"}>
                <ListItemIcon> <Icon>shopping_basket</Icon> </ListItemIcon>
                <ListItemText primary="Products"/>
              </ListItem>
              <ListItem
                button
                onClick={()=>itemClick("/admin/order")}
                key={4}
                selected={activeMenu==="/admin/order"}>
                <ListItemIcon> <Icon>shopping_bag</Icon> </ListItemIcon>
                <ListItemText primary="Orders"/>
              </ListItem>
              <ListItem
                button
                onClick={()=>itemClick("/admin/setting")}
                key={4}
                selected={activeMenu==="/admin/setting"}>
                <ListItemIcon> <Icon>settings</Icon> </ListItemIcon>
                <ListItemText primary="Settings"/>
              </ListItem>
              <ListItem
                button
                onClick={()=>itemClick("/admin/approval")}
                key={4}
                selected={activeMenu==="/admin/approval"}>
                <ListItemIcon> <Icon>assignment_turned_in</Icon> </ListItemIcon>
                <ListItemText primary="Approval"/>
              </ListItem>
              <ListItem
                button
                onClick={()=>itemClick("/admin/summary")}
                key={4}
                selected={activeMenu==="/admin/summary"}>
                <ListItemIcon> <Icon>insights</Icon> </ListItemIcon>
                <ListItemText primary="Summary"/>
              </ListItem>
          </Protected>              
          
        </List>
        <Grid container style={{padding:8}}>
          <Typography variant="body2" color="textSecondary" align="center">
            kunjungi juga website sekolah di {' '}
            <Link color="inherit" href="https://pahoa.sch.id/">
              pahoa.sch.id
            </Link>
          </Typography>
        </Grid>
        
      </Grid>
    );
  }else{
    return null
  }
  
}

export default Sidebar;





export const useStyles = makeStyles((theme) => ({
  sideBar:{
    height:'100vh',
    borderRight:'1px #e1e2e4 solid'
  },
  list:{
    width:"100%"
  }

}));

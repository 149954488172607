
export const userOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>({...row, label:row.username, value:row.id}))
  }
  else{
    return null
  }  
  
  }

export const generalListOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>({...row,label:row.name, value:row.id}))
  }
  else{
    return null
  }  
  
}

export const generalOptionMapper=(row)=>{
  if(row){
    return {...row,label:row.name, value:row.id}
  }
  else{
    return null
  }
  
}

export const parameterOptionMapper=(row)=>{
  if(row){
    return {...row,label:row.name, value:row.value}
  }
  else{
    return null
  }
  
}

export const parameterListOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>({...row,label:row.name, value:Number(row.value)}))
  }
  else{
    return null
  }
  
}

export const jenjangListOptionMapper=(rows)=>{
  if(rows){
    return rows.map(row=>({...row,id:Number(row.unit_id), name:row.unit_name, value:Number(row.unit_id), label:row.unit_name}))
  }
  else{
    return null
  }
  
}

export const sizeListOptionMapper=(rows)=>{
  if(rows){
    return rows.map(size=>({
      id:size.id,
      name:size.size_label,
      order:size.order
    }))
  }
  else{
    return null
  }
}
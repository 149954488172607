/*
author alka@2019
*/
import produce from "immer";
const initial  = {
    ui:{
        snack_show:false,
        snack_txt:'',
        snack_var:'info',
        loading:false,
        error:''
    },
    product_jenjang:null,
    product_kelas:null,
    product_jenjang_options:[],
    product_kelas_options:[],
    cartCount:0,    
    messageCount:0,
    unpaidBill:[],
    setting:{
        active_menu:{}
    },

}

export default function appReducer(prev=initial,action){
    switch (action.type) {
        case 'setUserLogin':
        return produce(prev, state =>{
            state.user= action.payload
        })

        case 'setUnpaidBill':
        return produce(prev, state =>{
            state.unpaidBill= action.payload
        })

        case 'setCategory':
        return produce(prev, state =>{
            state.category= action.payload
        })

        case 'setProductJenjang':
        return produce(prev, state =>{
            state.product_jenjang= action.payload
        })
        case 'setProductKelas':
        return produce(prev, state =>{
            state.product_kelas= action.payload
        })

        case 'setProductKelasOptions':
        return produce(prev, state =>{
            state.product_kelas_options= action.payload
        })

        case 'setProductJenjangOptions':
        return produce(prev, state =>{
            state.product_jenjang_options= action.payload
        })

        case 'setCartCount':
        return produce(prev, state =>{
            state.cartCount= action.payload
        })
        
        case 'setMessageCount':
        return produce(prev, state =>{
            state.messageCount= action.payload
        })

        case 'addCart':
        return produce(prev, state =>{
            state.cartCount= prev.cartCount + action.payload
        })

        case 'logout':
        return produce(prev, state =>{
            state.user= undefined
            state.ui={
                snack_show:false,
                snack_txt:'',
                snack_var:'info',
                loading:false,
                error:''
            }
            state.setting= {
              active_menu:{}
            }
        })

        case 'showSnackbar':
        return produce(prev, state =>{
            state.ui= {
                ...state.ui,
                snack_show:true,
                snack_txt:action.payload.t,
                snack_var:action.payload.v}
        })

        case 'hideSnackbar':
        return produce(prev, state =>{
            state.ui= {
                ...state.ui,
                snack_show:false}
        })

        case 'loading':
        return produce(prev, state =>{
            state.ui= {
                ...state.ui,
                loading:action.payload}
        })

        case 'setActiveMenu':
        return produce(prev, state =>{
            state.setting= {
                ...state.setting,
                active_menu:action.payload}
        })

        case 'setGlobalError':
        return produce(prev, state =>{
            state.ui= {
                ...state.ui,
              error:action.payload}
        })
        default:
            return prev
    }
}

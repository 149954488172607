export const userDummy = {        
    "id": 1,
    "username": "DjXbCKit5i7l",
    "type": {
      "value": "1",
      "name": "employee"
    },
    "roles": [
      {
        "id": 1,
        "code": "RADM",
        "name": "Super Admin"
      }
    ],
    "menus": [
      {
          "id": 1,
          "display": "Dashboard",
          "code": "MDASH",
          "webPath": "/home/dashboard",
          "androidActivity": "AndroidActivityx",
          "icon": "dashboard",
          "iosClass": null
        } ,
      {
        "id": 2,
        "display": "Store",
        "code": "MROLE",
        "webPath": "/home/store",
        "androidActivity": "assasass",
        "icon": "store",
        "iosClass": "dsdsdds"
      },
      {
          "id": 3,
          "display": "Cart",
          "code": "MCART",
          "webPath": "/home/cart",
          "androidActivity": "AndroidActivityx",
          "icon": "shopping_cart",
          "iosClass": null
      } ,
      {
        "id": 4,
        "display": "Products",
        "code": "MPROD",
        "webPath": "/admin/product",
        "androidActivity": "AndroidActivityx",
        "icon": "shopping_basket",
        "iosClass": null
      },
      {
        "id": 5,
        "display": "Orders",
        "code": "MORDER",
        "webPath": "/admin/order",
        "androidActivity": "AndroidActivityx",
        "icon": "shopping_bag",
        "iosClass": null
      },
      {
        "id": 6,
        "display": "Settings",
        "code": "MSETTING",
        "webPath": "/admin/setting",
        "androidActivity": "AndroidActivityx",
        "icon": "settings",
        "iosClass": null
      }
                 
    ],
    "actions": [
      {
        "id": 6,
        "code": "EROLE",
        "name": "update Role",
        "menuId": 1,
        "display": "edit",
        "pivot": {
          "roleId": 1,
          "actionId": 6
        }
      },
      {
        "id": 7,
        "code": "DROLE",
        "name": "delete Role",
        "menuId": 1,
        "display": "delete",
        "pivot": {
          "roleId": 1,
          "actionId": 7
        }
      },
      {
        "id": 19,
        "code": "CROLE",
        "name": "create Role",
        "menuId": 1,
        "display": "create",
        "pivot": {
          "roleId": 1,
          "actionId": 19
        }
      },
      
    ]
  ,
  "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZGY3ZTQzOGUzMmEwNjVmZmM1ZGNmMmZjNTgyZmQxMTA0NWQ2NWNmMDc3OTc5MTgwOTRlMmZiYzdmYTZlNzY5YWNjODBmMjc0ODJjMGQ0ZmIiLCJpYXQiOjE2MTAwMDI4MzUsIm5iZiI6MTYxMDAwMjgzNSwiZXhwIjoxNjQxNTM4ODM1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.AOGutkiS5d0w09gEREsAVEgP7nXqUAGWfUPqG34z44zKf7QGXsfqeLE1QhfM3O3J7vr3vt734ZyOKGzULyyXxt4OXVpUKIwPnhQo7VXL_ZdTD4S8U4vkqKOybhX3M60Cvy4KAl0H6lC5g7qWxav1gijoqoAuoiqWbENMVCFsjQ8rWGbpeKanbShVIVje0yJBH4gskoSAVCCbignvwAmZ8RTcpjnDOA6xcGiRNOeII-6gLomyWZviJrjZpC0ENcJQIzhM0LY_yFjnxAoueYRr7C7nJisILrxTtuo1LM9ZehQbA0URtkCVfbiuDGSyVRsi-SEHgH398W2nEtG2NhCjPQSoLaN95cgsPw-CB6andDohcf8gufHSlvTLozdiUXEEPdAmXCu8I7LFw81HhChBu-mpayLu5emmqJs6kLAeZO65DjvXTqL4jdm3g_8uWM237WYg_oPOP_KWHXn8Wj-4yPJyloW43erwBaI6XCcVZp8dP3J4ETv3Xup5B3RtJ40f6cVAvrRfUDNiB9MHgrl054lQ4pTNmRcL4xEG34DcPSPk9uSw7MGEfUfbOPsCmXwVVnsGfmI6WfofwPceEZsdBp_WWcXBTkabR8Ah_s-QklCPI7vGwyRWWF7hh4JIjdR-cUuEwpDjJEkstGyLhkA__ZM2T51aa_qzpdefTpJoysY"
}

export const maincategoriesDummy = [
  {
    id:1,
    name:"Seragam",
    image:"https://ecs7.tokopedia.net/img/cache/700/product-1/2020/7/18/104351877/104351877_c14bb1bb-4d39-4e06-9988-1a982ff84786_1000_1000.webp"    
  },
  {
    id:2,
    name:"Buku",
    image:"https://ecs7.tokopedia.net/img/cache/700/product-1/2020/6/2/83897787/83897787_560fed57-5d31-4890-9070-5815736f33de_1500_1500.webp"    
  },
  {
    id:3,
    name:"Sepatu",
    image:"https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/12/8/9ebaabb5-82c6-42d8-9a23-7a2ae70055b4.jpg.webp"    
  }
]

export const othercategoriesDummy = [
  {
    id:1,
    name:"Pin",
    image:"https://ecs7.tokopedia.net/img/cache/700/product-1/2019/9/7/2873985/2873985_4cec7aaa-e730-4d1c-9747-90e94b65aa53_1920_1920.jpg.webp"    
  },
  {
    id:2,
    name:"Tumblr",
    image:"https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/5/bf683948-529a-41c3-9cd1-149c23d93a27.jpg.webp"    
  },
  {
    id:3,
    name:"Masker",
    image:"https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/19/8620008d-4b05-4597-862b-74a949406a40.png.webp"    
  }
]

export const productsDummy = [
  {
    id:1,
    name:"Pin",
    description:"description description description description description description",
    image:"https://ecs7.tokopedia.net/img/cache/700/product-1/2019/9/7/2873985/2873985_4cec7aaa-e730-4d1c-9747-90e94b65aa53_1920_1920.jpg.webp"    ,
    images:[
      "https://ecs7.tokopedia.net/img/cache/700/product-1/2019/9/7/2873985/2873985_4cec7aaa-e730-4d1c-9747-90e94b65aa53_1920_1920.jpg.webp",
      "https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/5/bf683948-529a-41c3-9cd1-149c23d93a27.jpg.webp",
      "https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/19/8620008d-4b05-4597-862b-74a949406a40.png.webp",
      "https://ecs7.tokopedia.net/img/cache/700/product-1/2020/7/18/104351877/104351877_c14bb1bb-4d39-4e06-9988-1a982ff84786_1000_1000.webp"
    ],
    sizeType:1,
    sizeGroup:{
      id:1,
      name:'all size'
    },
    sizes:[
      {
        id:1,
        name:'all size'
      }
    ],
    priceType:1,
    price:10000000,
    prices:[
      {
        sizeId:1,
        sizeName:'all size',
        price:10000000
      }
    ],
    stocks:[
      {
        sizeId:1,
        sizeName:'all size',
        stock:100,
        order:1
      }
    ]
  },
  {
    id:2,
    name:"Tumblr",
    description:"description description description description description description",
    image:"https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/5/bf683948-529a-41c3-9cd1-149c23d93a27.jpg.webp"    ,
    images:[
      "https://ecs7.tokopedia.net/img/cache/700/product-1/2019/9/7/2873985/2873985_4cec7aaa-e730-4d1c-9747-90e94b65aa53_1920_1920.jpg.webp",
      "https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/5/bf683948-529a-41c3-9cd1-149c23d93a27.jpg.webp",
      "https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/19/8620008d-4b05-4597-862b-74a949406a40.png.webp",
      "https://ecs7.tokopedia.net/img/cache/700/product-1/2020/7/18/104351877/104351877_c14bb1bb-4d39-4e06-9988-1a982ff84786_1000_1000.webp"
    ],
    sizeType:2,
    sizeGroup:{
      id:2,
      name:'SML'
    },
    sizes:[
      {
        id:1,
        name:'S'
      },
      {
        id:2,
        name:'M'
      },
      {
        id:3,
        name:'L'
      },
      {
        id:4,
        name:'XL'
      }
    ],
    priceType:2,
    price:10000000,
    prices:[
      {
        sizeId:1,
        sizeName:'S',
        price:10000000
      },
      {
        sizeId:2,
        sizeName:'M',
        price:20000000
      },
      {
        sizeId:3,
        sizeName:'L',
        price:30000000
      },
      {
        sizeId:4,
        sizeName:'XL',
        price:40000000
      }
    ],    
    stocks:[
      {
        sizeId:1,
        sizeName:'S',
        stock:100,
        order:1
      },
      {
        sizeId:2,
        sizeName:'M',
        stock:50,
        order:2
      },
      {
        sizeId:3,
        sizeName:'L',
        stock:80,
        order:3
      },
      {
        sizeId:4,
        sizeName:'XL',
        stock:70,
        order:4
      }
    ]
  },
  {
    id:3,
    name:"Masker",
    description:"description description description description description description",
    image:"https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/19/8620008d-4b05-4597-862b-74a949406a40.png.webp"    ,
    images:[
      "https://ecs7.tokopedia.net/img/cache/700/product-1/2019/9/7/2873985/2873985_4cec7aaa-e730-4d1c-9747-90e94b65aa53_1920_1920.jpg.webp",
      "https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/5/bf683948-529a-41c3-9cd1-149c23d93a27.jpg.webp",
      "https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/19/8620008d-4b05-4597-862b-74a949406a40.png.webp",
      "https://ecs7.tokopedia.net/img/cache/700/product-1/2020/7/18/104351877/104351877_c14bb1bb-4d39-4e06-9988-1a982ff84786_1000_1000.webp"
    ],
    sizeType:2,
    sizeGroup:{
      id:2,
      name:'SML'
    },
    sizes:[
      {
        id:1,
        name:'S'
      },
      {
        id:2,
        name:'M'
      },
      {
        id:3,
        name:'L'
      },
      {
        id:4,
        name:'XL'
      }
    ],
    priceType:2,
    price:10000000,
    prices:[
      {
        sizeId:1,
        sizeName:'S',
        price:10000000
      },
      {
        sizeId:2,
        sizeName:'M',
        price:20000000
      },
      {
        sizeId:3,
        sizeName:'L',
        price:30000000
      },
      {
        sizeId:4,
        sizeName:'XL',
        price:40000000
      }
    ],
    stocks:[
      {
        sizeId:1,
        sizeName:'S',
        stock:100,
        order:1
      },
      {
        sizeId:2,
        sizeName:'M',
        stock:50,
        order:2
      },
      {
        sizeId:3,
        sizeName:'L',
        stock:80,
        order:3
      },
      {
        sizeId:4,
        sizeName:'XL',
        stock:70,
        order:4
      }
    ]
  },
  {
    id:4,
    name:"Seragam",
    description:"description description description description description description",
    image:"https://ecs7.tokopedia.net/img/cache/700/product-1/2020/7/18/104351877/104351877_c14bb1bb-4d39-4e06-9988-1a982ff84786_1000_1000.webp"    ,
    images:[
      "https://ecs7.tokopedia.net/img/cache/700/product-1/2019/9/7/2873985/2873985_4cec7aaa-e730-4d1c-9747-90e94b65aa53_1920_1920.jpg.webp",
      "https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/5/bf683948-529a-41c3-9cd1-149c23d93a27.jpg.webp",
      "https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/19/8620008d-4b05-4597-862b-74a949406a40.png.webp",
      "https://ecs7.tokopedia.net/img/cache/700/product-1/2020/7/18/104351877/104351877_c14bb1bb-4d39-4e06-9988-1a982ff84786_1000_1000.webp"
    ],
    sizeType:2,
    sizeGroup:{
      id:2,
      name:'SML'
    },
    sizes:[
      {
        id:1,
        name:'S'
      },
      {
        id:2,
        name:'M'
      },
      {
        id:3,
        name:'L'
      },
      {
        id:4,
        name:'XL'
      }
    ],
    priceType:2,
    price:10000000, 
    prices:[
      {
        sizeId:1,
        sizeName:'S',
        price:10000000
      },
      {
        sizeId:2,
        sizeName:'M',
        price:20000000
      },
      {
        sizeId:3,
        sizeName:'L',
        price:30000000
      },
      {
        sizeId:4,
        sizeName:'XL',
        price:40000000
      }
    ],   
    stocks:[
      {
        sizeId:1,
        sizeName:'S',
        stock:100,
        order:1
      },
      {
        sizeId:2,
        sizeName:'M',
        stock:50,
        order:2
      },
      {
        sizeId:3,
        sizeName:'L',
        stock:80,
        order:3
      },
      {
        sizeId:4,
        sizeName:'XL',
        stock:70,
        order:4
      }
    ]
  },
  {
    id:5,
    name:"Buku",
    description:"description description description description description description",
    image:"https://ecs7.tokopedia.net/img/cache/700/product-1/2020/6/2/83897787/83897787_560fed57-5d31-4890-9070-5815736f33de_1500_1500.webp"    ,
    images:[
      "https://ecs7.tokopedia.net/img/cache/700/product-1/2019/9/7/2873985/2873985_4cec7aaa-e730-4d1c-9747-90e94b65aa53_1920_1920.jpg.webp",
      "https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/5/bf683948-529a-41c3-9cd1-149c23d93a27.jpg.webp",
      "https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/19/8620008d-4b05-4597-862b-74a949406a40.png.webp",
      "https://ecs7.tokopedia.net/img/cache/700/product-1/2020/7/18/104351877/104351877_c14bb1bb-4d39-4e06-9988-1a982ff84786_1000_1000.webp"
    ],
    sizeType:2,
    sizeGroup:{
      id:2,
      name:'SML'
    },
    sizes:[
      {
        id:1,
        name:'S'
      },
      {
        id:2,
        name:'M'
      },
      {
        id:3,
        name:'L'
      },
      {
        id:4,
        name:'XL'
      }
    ],
    priceType:2,
    prices:[
      {
        sizeId:1,
        sizeName:'S',
        price:10000000
      },
      {
        sizeId:2,
        sizeName:'M',
        price:20000000
      },
      {
        sizeId:3,
        sizeName:'L',
        price:30000000
      },
      {
        sizeId:4,
        sizeName:'XL',
        price:40000000
      }
    ],
    price:10000000,    
    stocks:[
      {
        sizeId:1,
        sizeName:'S',
        stock:100,
        order:1
      },
      {
        sizeId:2,
        sizeName:'M',
        stock:50,
        order:2
      },
      {
        sizeId:3,
        sizeName:'L',
        stock:80,
        order:3
      },
      {
        sizeId:4,
        sizeName:'XL',
        stock:70,
        order:4
      }
    ]
  },
  {
    id:6,
    name:"Sepatu",
    description:"description description description description description description",
    image:"https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/12/8/9ebaabb5-82c6-42d8-9a23-7a2ae70055b4.jpg.webp"    ,
    images:[
      "https://ecs7.tokopedia.net/img/cache/700/product-1/2019/9/7/2873985/2873985_4cec7aaa-e730-4d1c-9747-90e94b65aa53_1920_1920.jpg.webp",
      "https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/5/bf683948-529a-41c3-9cd1-149c23d93a27.jpg.webp",
      "https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/19/8620008d-4b05-4597-862b-74a949406a40.png.webp",
      "https://ecs7.tokopedia.net/img/cache/700/product-1/2020/7/18/104351877/104351877_c14bb1bb-4d39-4e06-9988-1a982ff84786_1000_1000.webp"
    ],
    sizeType:2,
    sizeGroup:{
      id:2,
      name:'SML'
    },
    prices:[
      {
        sizeId:1,
        sizeName:'S',
        price:10000000
      },
      {
        sizeId:2,
        sizeName:'M',
        price:20000000
      },
      {
        sizeId:3,
        sizeName:'L',
        price:30000000
      },
      {
        sizeId:4,
        sizeName:'XL',
        price:40000000
      }
    ],
    priceType:2,
    price:10000000,    
    stocks:[
      {
        sizeId:1,
        sizeName:'S',
        stock:100,
        order:1
      },
      {
        sizeId:2,
        sizeName:'M',
        stock:50,
        order:2
      },
      {
        sizeId:3,
        sizeName:'L',
        stock:80,
        order:3
      },
      {
        sizeId:4,
        sizeName:'XL',
        stock:70,
        order:4
      }
    ]
  }
]


export const cartDummy = [
  {
    productId:1,
    productName:"Pin",
    productImage:"https://ecs7.tokopedia.net/img/cache/700/product-1/2019/9/7/2873985/2873985_4cec7aaa-e730-4d1c-9747-90e94b65aa53_1920_1920.jpg.webp"    ,
    productPrice:10000000,
    quantity:10
  },
  {
    productId:2,
    productName:"Sepatu",
    productImage:"https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/12/8/9ebaabb5-82c6-42d8-9a23-7a2ae70055b4.jpg.webp"    ,
    productPrice:10000000,
    quantity:10
  },{
    productId:3,
    productName:"Buku",
    productImage:"https://ecs7.tokopedia.net/img/cache/700/product-1/2020/6/2/83897787/83897787_560fed57-5d31-4890-9070-5815736f33de_1500_1500.webp"    ,
    productPrice:10000000,
    quantity:10
  },
  {
    productId:4,
    productName:"Masker",
    productImage:"https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/19/8620008d-4b05-4597-862b-74a949406a40.png.webp"    ,
    productPrice:10000000,
    quantity:10
  }
]


export const jenjangsDummy = [
  {
    id:1,
    name:"TK"
  },
  {
    id:2,
    name:"SD"
  },
  {
    id:3,
    name:"SMP"
  },
  {
    id:4,
    name:"SMA"
  }

]

export const kelasDummy = [
  {
    id:1,
    name:"1"
  },
  {
    id:2,
    name:"2"
  },
  {
    id:3,
    name:"3"
  },
  {
    id:4,
    name:"4"
  },
  {
    id:5,
    name:"9"
  },
  {
    id:6,
    name:"6"
  }

]


export const locationDummy = [
  {
    id:1,
    name:"Gedung A"
  },
  {
    id:2,
    name:"Gedung 2"
  },
  {
    id:3,
    name:"Gedung 3"
  }

]

export const scheduleDummy = [
  {
    id:1,
    locations:[
      {
      id:1,
      name:"Gedung A"
      },
      {
        id:1,
        name:"Gedung B"
      }
    ],
    date:new Date() ,
    open:new Date() ,
    close:new Date(),
    quota:100 
},
{
  id:2,
  locations:[
    {
    id:1,
    name:"Gedung A"
    },
    {
      id:1,
      name:"Gedung B"
    }
  ],
  date:new Date() ,
  open:new Date() ,
  close:new Date(),
  quota:100 
},
{
  id:3,
  locations:[
    {
    id:1,
    name:"Gedung A"
    },
    {
      id:1,
      name:"Gedung B"
    }
  ],
  date:new Date() ,
  open:new Date() ,
  close:new Date(),
  quota:100 
},
{
  id:4,
  locations:[
    
  ],
  date:new Date() ,
  open:new Date() ,
  close:new Date(),
  quota:100 
}
]


export const categoriesDummy = [
  {
    id:1,
    name:"Seragam",
    image:"https://ecs7.tokopedia.net/img/cache/700/product-1/2020/7/18/104351877/104351877_c14bb1bb-4d39-4e06-9988-1a982ff84786_1000_1000.webp"    ,
    isMain:1
  },
  {
    id:2,
    name:"Buku",
    image:"https://ecs7.tokopedia.net/img/cache/700/product-1/2020/6/2/83897787/83897787_560fed57-5d31-4890-9070-5815736f33de_1500_1500.webp"    ,
    isMain:1
  },
  {
    id:3,
    name:"Sepatu",
    image:"https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/12/8/9ebaabb5-82c6-42d8-9a23-7a2ae70055b4.jpg.webp"    ,
    isMain:1
  },
  {
    id:4,
    name:"Pin",
    image:"https://ecs7.tokopedia.net/img/cache/700/product-1/2019/9/7/2873985/2873985_4cec7aaa-e730-4d1c-9747-90e94b65aa53_1920_1920.jpg.webp"    ,
    isMain:0
  },
  {
    id:5,
    name:"Tumblr",
    image:"https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/5/bf683948-529a-41c3-9cd1-149c23d93a27.jpg.webp"    ,
    isMain:0
  },
  {
    id:6,
    name:"Masker",
    image:"https://ecs7.tokopedia.net/img/cache/700/VqbcmM/2020/11/19/8620008d-4b05-4597-862b-74a949406a40.png.webp"    ,
    isMain:0
  }

]

export const sizeGroupOptionsDummy = [
  {
    id:2,
    name:'SML'
  },
  {
    id:3,
    name:'angka'
  },
  {
    id:4,
    name:'besar_kecil'
  }
]

export const sizeOptionsDummy = [
  {
    id:1,
    name:'S',
    order:1
  },
  {
    id:2,
    name:'M',
    order:2
  },
  {
    id:3,
    name:'L',
    order:3
  },
  {
    id:4,
    name:'XL',
    order:4
  },
  {
    id:5,
    name:'XXL',
    order:5
  }
]
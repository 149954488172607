import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const Information =(props)=> {
  const {handleClose, message, title, children} = props

  return (
  <>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {
          children && children
        }               
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
  </>

    );
}

export default Information;

import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doSilentPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCartCount } from "reduxs/actions";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Remove from '@material-ui/icons/Remove';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import numeral from 'numeral'
import Conditional from 'components/Conditional';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReservationForm from './ReservationForm';
import PaymentConfirmationForm from './PaymentConfirmationForm';
import PaymentInstructionForm from './PaymentInstructionForm';
import { format,parse } from 'date-fns'
import NoteForm from './NoteForm';
import Information from 'components/Information';
import Tooltip from '@material-ui/core/Tooltip';




const Cart =(props)=> {
  
  let { path, url } = useRouteMatch();
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch()
  const user = useSelector(state => state.user);
  const ui = useSelector(state => state.ui);
  const [state, setstate] = useState({
    orders:[],
    session:null
  });
  
  const [emptyStocks, setemptyStocks] = useState([]);
  const [selectedOrders, setselectedOrders] = useState([]);
  const [openDialog, setopenDialog] = React.useState(false);
  const [dialogWidth, setdialogWidth] = useState('sm');
  const [dialogContent, setdialogContent] = useState(null);
  const [dialogTitle, setdialogTitle] = useState('title');
  

  const back =()=>{
    history.goBack()
  }

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const getOrders =async()=>{
    const params={}
        
    const response = await doGet(endpoint.cart, params, getDefaultHeader());        
    if(!response.error){
      const orders = response.data      

      setstate({
        orders  : orders.map(order=>(
                  {
                  ...order,
                  id       : order.id,
                  product  : {
                              ...order.product                    
                  },
                  quantity : order.qty,
                  size:{
                    id:order.size.id,
                    name:order.size.size_label
                  },
                  price: Number(order.price.price)
                  
                })),
        session : null
      })

      dispatch(setCartCount(orders.length)) 
      
    }
    
  } 


  const getEmptyStock =async()=>{
    const params={}
        
    const response = await doGet(endpoint.product_empty, params, getDefaultHeader());        
    if(!response.error){
      setemptyStocks(response.data)      
    }
    
  } 

  const checkStock=()=>{
    let unavailables = state.orders.map(cart=>cart.id).filter(cartId => emptyStocks.includes(cartId))
    
    if(unavailables.length === 0){
      return true
    }else{
      return false
    }
  }



  const selectAll =()=>{
    if(selectedOrders.length>0 && selectedOrders.length === state.orders.length){
      setselectedOrders([])
    }else{
      setselectedOrders(state.orders)
    }
    
  }

  
  const selectOrder =(order)=>{
    let existed = selectedOrders.filter(item=>(item.id===order.id))[0]
    if(existed){
      setselectedOrders(selectedOrders.filter(item=>(item.id!==order.id)))
    }else{
      setselectedOrders([...selectedOrders,order])
    }
  }

  
  const deleteSelectedOrders =async()=>{
    const payload = {
      orderIds:selectedOrders.map(order=>(order.id))
    }
    const response = await doDelete(endpoint.cart, payload,"delete order",getDefaultHeader())
    setselectedOrders([])
    getOrders()
  }

  const isSelected =(order)=>{
    if(selectedOrders.filter(item=>(item.id===order.id))[0]){
      return true
    }else{
      return false
    }
  }

  const updateOrder =async(order, action)=>{
    if(action==='delete'){
      const payload = {
        orderIds:[order.id]
      }
      const response = await doDelete(endpoint.cart, payload,"delete order",getDefaultHeader())
    }
    else 
    if(action==='add'){
      const payload = {
        id:order.id,
        qty:order.quantity+1
      }
      const response = await doSilentPut(endpoint.cart, payload,"update order",getDefaultHeader()) 
    }
    else 
    if(action==='reduce'){
      if(order.quantity>1){
        const payload = {
          id:order.id,
          qty:order.quantity-1
        }
        const response = await doSilentPut(endpoint.cart, payload,"update order",getDefaultHeader()) 
      }      
    } 
    else
    if(action==='note'){
      const payload = {
        id:order.id,
        note:order.note
      }
      const response = await doSilentPut(endpoint.cart, payload,"update order",getDefaultHeader()) 
    }
    
    getOrders()
  }

  const closeDialog =()=>{    
    setopenDialog(false)
  }

  const setSession =(session)=>{    
    setstate({
      ...state,
      session:{
        ...session,
        date:parse(session.open, 'yyyy-MM-dd HH:mm:ss', new Date())
      }
    })    
  }

  const gotoProduct =(product)=>{
    history.push(`/home/store/product/${product.id}`)
  }

  const checkOut = async(channel)=>{     
    
    const payload = {
      pickup_session:state.session.id,
      payment_type:channel
    }

    const response = await doPost(endpoint.order, payload,"checkout",getDefaultHeader())
    
    if(response && !response.error){      

      showPaymentInstruction({
        ...state.session,
        ...response.data, 
        channel:channel
      })
      getOrders()      
    } 
     
  }

  
  const btnPay =()=>{    
    let noteRequired = state.orders.map(order=>(
      {note:order.note, isRequired : order.product.isNoteRequired}
      )).filter(order=>order.isRequired===1 && (order.note===null || (order.note!==null && order.note.trim()==="")))

    if(noteRequired.length>0){
      setdialogTitle('belum ada catatan')          
      setdialogContent(
        <Information 
        handleClose={closeDialog} 
        message="mohon lengkapi catatan terlebih dahulu"
        />
      )    
      setdialogWidth('sm')
      setopenDialog(true)
      return
    }
    if(checkStock()){
      if(!state.session){
        setdialogTitle('Pilih Jadwal Pengambilan')
        
        setdialogContent(
          <ReservationForm 
          user={user} 
          handleClose={closeDialog} 
          setSession={setSession}
          />
        )
  
        setdialogWidth('md')
        setopenDialog(true)
      }else{
        showPaymentConfirmation()
      }    
    }else{
      setdialogTitle('stok tidak tersedia')        
      setdialogContent(
        <Information 
          handleClose={closeDialog} 
          message="ketersediaan stok dari produk berikut ini tidak mencukupi : "
        >
          <List component="nav" aria-label="main mailbox folders">
            {
            state.orders.filter(cart=>emptyStocks.includes(cart.id)).map(cart=>(
              <>
              <ListItem button>
                <ListItemText primary={cart.product.name} />
              </ListItem>
              <Divider />
              </>
            ))
            }
          </List>
        </Information>        
      )
      setdialogWidth('sm')
      setopenDialog(true)
    }
  
    
    
  }

  const showPaymentConfirmation =()=>{
    setdialogTitle('Pilih Cara Pembayaran')
    
    setdialogContent(
    <PaymentConfirmationForm 
    handleClose={closeDialog}     
    checkOut={checkOut}/>
    )

    setdialogWidth('sm')
    setopenDialog(true)        
  }

  const showPaymentInstruction =(instruction)=>{
    
    setdialogTitle('Instruksi cara pembayaran')
    setdialogContent(
    <PaymentInstructionForm 
    handleClose={closeDialog} 
    instruction={instruction}/>
    )
    setdialogWidth('sm')
    setopenDialog(true)        
  }

  const showProductNote =(order)=>{    
    setdialogTitle('Catatan Produk')
    setdialogContent(
    <NoteForm 
    order={order}
    onAction={updateOrder}
    handleClose={closeDialog}
    />
    )
    setdialogWidth('sm')
    setopenDialog(true)        
  }
  

  useEffect(() => {
    getOrders()
    getEmptyStock()
  }, [])

  useEffect(() => {
    if(state.session){
      showPaymentConfirmation()
    }
  }, [state])

       
  if(user && user.token){
    return (
      <React.Fragment>
        <Conditional condition={state.orders.length>0}>
        <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
            <Grid item xs={1}>              
                <IconButton color="primary" aria-label="go back" component="span" onClick={back}>
                  <ArrowBack />
                </IconButton>              
            </Grid>
            <Grid item xs={11} container spacing={2} justify="center" alignItems='flex-start' className={classes.chipWrapper}>
                     
            </Grid>            

            <Grid container item xs={12} md={8} style={{padding:'0 8px'}}>
                <Grid container justify="space-between">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(selectedOrders.length > 0 && selectedOrders.length === state.orders.length )}
                      onChange={selectAll}
                      name="selectAll"
                      color="secondary"
                    />
                  }
                  label="Pilih semua"
                  />
                  <Conditional condition={selectedOrders.length>0}>
                    <Button color="secondary" onClick={deleteSelectedOrders}>Hapus</Button>
                  </Conditional>
                </Grid>
                {
                  state.orders.map(order=>(
                    
                    <Paper elevation={3} style={{width:'100%', marginBottom:16, padding:'8px 0 8px 0'}}>
                      <Grid container justify="space-around" item xs={12}>
                        
                        <Grid container justify="center" alignItems="center" item xs={1}>
                          <Checkbox
                            checked={isSelected(order)}
                            onChange={()=>selectOrder(order)}                        
                          />
                        </Grid>
                        <Grid container justify="center" alignItems="center" item style={{width:100}}>
                            <img 
                            onClick={()=>gotoProduct(order.product)}
                            src={order.product.cover} 
                            width="100" height="100"
                            />
                        </Grid>
                        <Grid container  item xs={5} alignItems="flex-start" >
                          <Grid container item xs={12} alignItems="center" alignContent="center">
                            <Typography variant="body" className={classes.clickable} onClick={()=>gotoProduct(order.product)}>
                              {
                                order.product.name
                              }
                            </Typography>                          
                          </Grid>
                          <Grid container item xs={12} alignItems="flex-start">
                            <Typography variant="h6">
                              {
                                order.size.name
                              }
                            </Typography>                          
                          </Grid>
                          <Grid container alignItems="center">
                            <Typography variant="h6">                              
                                {numeral(order.price).format('0,0')}
                            </Typography>                          
                          </Grid>
                        </Grid>    
                        <Grid container item xs={4} md={2} justify="center" alignItems="center">
                          <Grid container className={classes.quantityControll}>
                            <Grid container item xs={4} justify="center" alignItems="center">
                              <IconButton
                              onClick={()=>updateOrder(order,'reduce')}
                              size="small"
                              aria-label="substract"
                              style={{margin:0}}>
                                <Remove fontSize="medium" />
                              </IconButton>
                            </Grid>                  
                            <Grid container item xs={4} justify="center" alignItems="center">
                            {order.quantity}
                            </Grid>                  
                            <Grid container item xs={4} justify="center" alignItems="center">
                              <IconButton
                              onClick={()=>updateOrder(order,'add')}
                              size="small"
                              aria-label="add"
                              style={{margin:0}}>
                                <Add fontSize="medium" />
                              </IconButton>
                            </Grid>                  
                          </Grid>                          
                        </Grid> 


                        <Grid container item xs={6} md={2} justify="flex-end" alignItems="center">
                          <Conditional condition={order.product.isNoteRequired===1}>
                            <Tooltip title="Catatan">
                              <IconButton
                                onClick={()=>showProductNote(order)}
                                size="medium"
                                aria-label="add"
                                style={{margin:0}}>
                                  <Edit fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Conditional>
                          
                          <IconButton
                            onClick={()=>updateOrder(order,'delete')}
                            size="medium"
                            aria-label="add"
                            style={{margin:0}}>
                              <Delete fontSize="inherit" />
                          </IconButton>
                        </Grid>              
                         
                      </Grid>
                      </Paper>  
                                     
                  ))
                }
            </Grid>

            <Grid container item xs={12} md={4} style={{padding:'0 8px'}}>        
              <Paper elevation={1} style={{width:'100%', padding:8}}>
                <Grid container justify="center">        
                  <Typography variant="h5" gutterBottom>
                    Ringkasan belanja
                  </Typography>
                </Grid>
                <Divider />
                <Grid container justify="space-between" alignItems="center" style={{minHeight:100}}>        
                  {
                    state.orders.map(order=>(
                      <>
                      <Grid item xs={5}>{order.product.name}</Grid>
                      <Grid container justify="flex-end" item xs={2}>{order.quantity}</Grid>
                      <Grid container justify="flex-end" item xs={5}>
                        {numeral(order.price * order.quantity).format('0,0')}
                      </Grid>
                      </>
                    ))
                  }
                  
                </Grid>
                <Grid container justify="space-between" alignItems="center">        
                  <Typography variant="h6">
                    Total harga
                  </Typography>
                  <Typography variant="h6">
                  {
                  numeral(
                    state.orders.map(order=>(order.price * order.quantity)).reduce((a, b) => a+b,0)
                  ).format('0,0')
                  }
                  </Typography>
                </Grid>
                <Grid container justify="center" alignItems="center">        
                  <Button onClick={btnPay} variant="contained" color="primary" style={{width:'100%'}}>
                    Bayar
                  </Button>
                </Grid>
              </Paper>
            </Grid>
        </Grid>
        </Conditional>

        <Conditional condition={ui.loading===false && state.orders.length===0}>
          <Grid container justify="center" alignItems="center"> 
            <Grid item xs={12} md={6} container justify="center">
              <Typography variant="h4" >oops, your cart is empty</Typography>
            </Grid>
            <Grid item xs={12} md={6} container justify="center" alignItems="center">         
              <img src='/empty_cart.svg' width="500" height="500" className={classes.image}/>
            </Grid>  
          </Grid>
        </Conditional>

        <Dialog
        fullWidth={true}
        maxWidth={dialogWidth}
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
        </Dialog>

      </React.Fragment>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

const useStyles = makeStyles((theme) => ({
    marginTop16:{
      marginTop:16  
    },  
    marginH4:{
      marginLeft:4,
      marginRight:4,
    },  
    root: {
      padding: theme.spacing(1)
    },
    chip:{
      margin:'0 8px 0 8px'
    },
    chipWrapper:{
      marginTop:8
    },
    productContainer:{
      marginTop:16
    },
    category_media: {
      width:'100%',
      height: 200,
    },
    productImage:{
      height:400,
      background:'linear-gradient(90deg, #fc466b 0%,#3f5efb 100% )'
    },
    thumbContainer:{
      marginTop:8
    },
    productThumb:{
      height:70,      
      background:'linear-gradient(90deg, #00b09b 0%,#96c93d 100% )'
    },
    quantityControll:{      
      border : '1px solid #c4c4c4',
      borderRadius:50  
    },
    image:{
      width:'80%',
      height: 'auto',
    }  ,
    clickable:{
      color:theme.palette.primary.main,
      fontWeight:'bold',
      cursor:'pointer'
    }

  }));


export default Cart;

import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doSilentPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { format, lastDayOfMonth, setDate } from 'date-fns'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import numeral from 'numeral'
import Conditional from 'components/Conditional';

const PaymentInstructionForm =(props)=> {
  const { user, handleClose, instruction} = props
  const classes = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  
  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }  
    
  const submit = ()=>{
    handleClose()     
  }

      
      if(instruction && instruction.channel===2){
      return (
        <>
          <DialogContent >
                      
            <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
              <Grid container>  
                Segera lakukan pembayaran sebagai berikut :
              </Grid>
              <Grid container>  
                Sebelum
              </Grid> 
              
              <Grid container alignItems="center">  
                <Grid item xs={4} md={2}>            
                  <Typography variant="h5">
                  {instruction.expired}
                  </Typography>
                </Grid>                
              </Grid>        
              
              <Grid container alignItems="center">  
                <Grid item xs={4} md={2}>            
                  jumlah
                </Grid>
                <Grid item xs={8}  md={10}>   
                  <Typography variant="h5">
                    Rp. {numeral(instruction.total_transfer).format('0,0')}
                  </Typography>          
                </Grid>          
              </Grid> 

              <Grid container alignItems="center">  
                transfer ke virtual account BCA :          
              </Grid> 
              <Grid container>  
                <Typography variant="h5">
                {instruction.virtual_account}
                </Typography>
              </Grid>         
              <Grid container alignItems="center">
                *Detail pesanan telah kami kirim ke email anda
              </Grid> 
                                  
                    
            </Grid> 
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={submit} color="primary">
              OK
            </Button>            
          </DialogActions>
        </>    
        );
    }
    else
    if(instruction && instruction.channel===1){
      return (
        <>
          <DialogContent>
            <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
              <Grid container>  
                <Typography variant="body">
                  Anda dapat melakukan pembayaran langsung di 
                </Typography>      
              </Grid>
              
              <Grid container>  
                <Typography variant="h5">
                  {instruction.location.name} 
                </Typography>      
              </Grid>

              <Grid container>  
                <Typography variant="body">
                  pada tanggal
                </Typography>      
              </Grid>
              <Grid container>  
                <Typography variant="h5">
                {format(instruction.date, 'dd MMMM yyyy')}
                </Typography>      
              </Grid>

              <Grid container>  
                <Typography variant="body">
                  pada Jam
                </Typography>      
              </Grid>
              <Grid container>  
                <Typography variant="h5">
                {`${instruction.open.substring(11,16)} sampai ${instruction.close.substring(11,16)} `}
                </Typography>      
              </Grid>

              <Grid container>  
                <Typography variant="body">
                dengan menunjukkan invoice      
                </Typography>      
              </Grid>                                                                      
            </Grid>

            <Grid container  alignItems="center" style={{marginTop:16}}>
              *Detail pesanan telah kami kirim ke email anda
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={submit} color="primary">
              OK
            </Button>            
          </DialogActions>
        </>    
        );
    }else{
      return null
    }
}

export default PaymentInstructionForm;

const useStyles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: "#0cdc09",
    color: "white"
  },
  timeLabelOpen:{
    backgroundColor:theme.palette.primary.main,
    color:'white'
  },
  timeLabelClosed:{
    backgroundColor:theme.palette.secondary.main,
    color:'white'
  },
  timeOpen:{
    color:theme.palette.primary.main,
    border:`1px solid ${theme.palette.primary.main}`
  },
  timeClosed:{
    color:theme.palette.secondary.main,
    border:`1px solid ${theme.palette.secondary.main}`
  }
}));

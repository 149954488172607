import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doSilentPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { format, lastDayOfMonth, setDate } from 'date-fns'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Conditional from 'components/Conditional';

const PaymentConfirmationForm =(props)=> {
  const { user, handleClose, checkOut} = props
  const classes = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [state, setstate] = useState({
    channel: 0
  })  
  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const setChannel =(channel)=>{
    setstate({...state, channel:channel})
  }
    
  const submit = ()=>{
    if(state.channel!==0){
      checkOut(state.channel)
    }    
  }




  
      if(state){
      return (
        <>
          <DialogContent >
            <Grid container justify="space-between" style={{minHeight:200}}>
                <Grid container justify="center"  alignItems="center" item xs={12} md={6}>
                  <Button 
                  onClick={()=>setChannel(1)} 
                  variant={state.channel===1 ? "contained" : "outlined"} 
                  size="large" 
                  color={state.channel===1 ? "primary" : "default"}  
                  disabled={true}
                  >
                    Bayar  di tempat
                  </Button>
                </Grid>     
                <Grid container justify="center"  alignItems="center" item xs={12} md={6}>
                  <Button 
                  onClick={()=>setChannel(2)}  
                  variant={state.channel===2 ? "contained" : "outlined"} 
                  size="large" 
                  color={state.channel===2 ? "primary" : "default"}  
                  
                  >
                    Virtual Account BCA
                  </Button>
                </Grid> 
                
            </Grid>            
          </DialogContent>
          <DialogActions>
            <Conditional condition={state.channel!==0}>
              <Button variant="contained" onClick={submit} color="primary">
                OK
              </Button>
            </Conditional>            
          </DialogActions>
        </>    
        );
    }else{
      return null
    }
}

export default PaymentConfirmationForm;

const useStyles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: "#0cdc09",
    color: "white"
  },
  timeLabelOpen:{
    backgroundColor:theme.palette.primary.main,
    color:'white'
  },
  timeLabelClosed:{
    backgroundColor:theme.palette.secondary.main,
    color:'white'
  },
  timeOpen:{
    color:theme.palette.primary.main,
    border:`1px solid ${theme.palette.primary.main}`
  },
  timeClosed:{
    color:theme.palette.secondary.main,
    border:`1px solid ${theme.palette.secondary.main}`
  }
}));

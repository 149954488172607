import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doDownloadPdf} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Search from '@material-ui/icons/Search';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { format, parse, isAfter } from 'date-fns'
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme'
import ApprovalDetail from './ApprovalDetail';
import TablePagination   from '@material-ui/core/TablePagination';
import ApprovalChip from 'components/ApprovalChip';


const Approval =(props)=> {

  let { path, url } = useRouteMatch();
  let history = useHistory();
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const [approvals, setapprovals] = useState([]);
  const [selectedApproval, setselectedApproval] = useState(null);
  const [statusOptions, setstatusOptions] = useState([
    {label:'rejected', value:2},
    {label:'approved', value:1},
    {label:'waiting', value:0}
  ]);
  const [filters, setfilters] = useState({
    status:null
  });
  const [openDialog, setopenDialog] = React.useState(false);
  const [dialogWidth, setdialogWidth] = useState('sm');
  const [dialogContent, setdialogContent] = useState(null);
  const [dialogTitle, setdialogTitle] = useState('title');
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const changePage=(event, newPage)=>{
    setPage(newPage+1)
  }

  const changeRowsPerPage = (event)=> {
      setRowsPerPage(+event.target.value);
      setPage(1);
  }
  
  const back =()=>{
    history.goBack()
  }

  const getapprovals =async()=>{
    const params={
      status:filters.status?filters.status.value:null,
      page:page,
      limit:rowsPerPage
    }
        
    const response = await doGet(endpoint.approval, params, getDefaultHeader());        
    if(response && response.data && response.data.data){
      const approvals = response.data.data 
      setapprovals(approvals.map(approval=>({
        id       : approval.id,  
        action : approval.action,      
        creator : approval.creator,
        approver : approval.approver,
        reason : approval.reason,
        status:approval.status,
        note : approval.note,
        approverNote : approval.approver_note,
        created_at : new Date(approval.created_at),
        decided_at : approval.decided_at ? parse(approval.decided_at, 'yyyy-MM-dd HH:mm:ss', new Date()) : null
      })))
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    }else{
      setapprovals([])
    }
  }  

  const showDetail =(approval)=>{
    setselectedApproval(approval)    
  }

  const closeDialog =()=>{    
    setopenDialog(false)
    setselectedApproval(null)
  }
  
  const filtersChange =(fieldName, event)=>{    
    let newFilters = {...filters}
    let value = event

    newFilters[fieldName] = value    
    setfilters(newFilters)
  }
  

  useEffect(() => {
    getapprovals()
  }, [page,rowsPerPage]) 

  
  useEffect(() => {    
    if(selectedApproval){
      setdialogTitle('Detail Approval')
      setdialogContent(<ApprovalDetail getapprovals={getapprovals} user={user} selectedApproval={selectedApproval} handleClose={closeDialog}/>)
      setdialogWidth('md')
      setopenDialog(true)
    }
  }, [selectedApproval])
  
  

  if(user && user.token){
    return (
      <React.Fragment>
        <CssBaseline/>
        <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
            <Grid item xs={1}>              
                <IconButton color="primary" aria-label="go back" component="span" onClick={back}>
                  <ArrowBack />
                </IconButton>              
            </Grid>
            <Grid container item xs={11} justify="flex-end" alignItems="center" >                                                    
                
            </Grid>          
            
            <Paper elevation={1} style={{width:'100%', margin:'4px 0', padding:8}}>
                <Grid container spacing={1} alignItems="center" >                                                    
                    <Grid item xs={6} md={2}>
                      <DebouncedTextField
                          margin="dense"
                          id="keyword"
                          placeholder="keyword"                                                                                   
                          fullWidth
                          variant="outlined"
                          value={filters.keyword}
                          onChange={(e)=>{filtersChange('keyword',e)}}
                      />
                    </Grid>
                    <Grid item xs={6} md={2} >
                      <Select                
                          name="status"
                          placeholder = "pilih status"
                          options={statusOptions}
                          onChange={(e)=>filtersChange("status",e)}
                          value={filters.status} 
                          styles={selectCustomZindex}
                          isClearable
                      />      
                    </Grid>
                    
                    <IconButton onClick={getapprovals} size="small" color="primary" aria-label="go back" component="span" style={{margin:'0 8px'}}>
                      <Search />
                    </IconButton>   
                </Grid>
                                     
            </Paper>
            
            
                <Grid container justify="center" style={{margin:'4px 0'}}>                                           
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow className={classes.rowHead}>
                                    <TableCell align="center" className={classes.cellHead}>Action</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Creator</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Created</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Decided</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {approvals.map((approval) => (
                                <TableRow key={approval.id}>
                                    <TableCell align="center" onClick={()=>showDetail(approval)} className={classes.clickable}>{approval.action}</TableCell>
                                    <TableCell align="center">{approval.creator && approval.creator.name}</TableCell>
                                    <TableCell align="center">{approval.created_at && format(approval.created_at, 'yyyy-MM-dd HH:mm')}</TableCell>
                                    <TableCell align="center">{approval.decided_at && format(approval.decided_at, 'yyyy-MM-dd HH:mm')}</TableCell>                                    
                                    <TableCell align="center"> <ApprovalChip status={approval.status}/> </TableCell>                                    
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <Grid container className={classes.paginationContainer}>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={totalRows}
                                rowsPerPage={rowsPerPage}
                                page={page-1}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onChangePage={changePage}
                                onChangeRowsPerPage={changeRowsPerPage}
                            />
                          </Grid>                        
                    </TableContainer>
                </Grid>            
            
            
        </Grid>

        <Dialog
        fullWidth={true}
        maxWidth={dialogWidth}
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

const useStyles = makeStyles((theme) => ({
    marginTop16:{
      marginTop:16  
    },  
    marginH4:{
      marginLeft:4,
      marginRight:4,
    },  
    root: {
      padding: theme.spacing(1)
    },
    rowHead:{
      backgroundColor:'#d5ecff'
    },
    cellHead:{
      color:'#2532d4',
      fontWeight:'bold'
    },
    clickable:{
      color:theme.palette.primary.main,
      fontWeight:'bold',
      cursor:'pointer'
    },
    paginationContainer:{
      width:'max-content'
    }
    
    
  }));


export default Approval;

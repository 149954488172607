import React from 'react'
import { makeStyles } from '@material-ui/styles';

import Chip from '@material-ui/core/Chip';

const ApprovalChip = ({status}) => {

    let label;
    let color;
    switch (status) {
      case 0:
      label = "waiting"  
      color = "grey"
      break;
      case 1:
      label = "approved"  
      color = "#16cd90"
      break;
      case 2:
      label = "rejected"  
      color = "red"
      break;
    
      default:
        break;
    }
    const classes = useStyles({color})
    return (
        <Chip size="small" label={label} className={classes.chip} />
    );
}

const useStyles = makeStyles(theme => ({
    chip:{
      backgroundColor:({color})=>{return color;},
      color:'white'
    }

  }));

export default ApprovalChip;

import React from 'react'
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import numeral from 'numeral'

const ProductOmsetTable = (props) => {
    const {summaries} = props
    const classes = useStyles()
    return (
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow className={classes.rowHead}>
                    <TableCell align="center" className={classes.cellHead}>Product</TableCell>
                    <TableCell align="center" className={classes.cellHead}>Omset</TableCell>                    
                </TableRow>
            </TableHead>
            <TableBody> 
            {summaries.map((summary) => (
                <TableRow key={summary.name}>
                    <TableCell align="center" >{summary.name}</TableCell>
                    <TableCell align="center">{numeral(summary.omset).format('0,0')}</TableCell>                    
                </TableRow>
            ))}
            </TableBody>
        </Table>
    );
}

const useStyles = makeStyles(theme => ({    
    rowHead:{
      backgroundColor:'#d5ecff'
    },
    cellHead:{
      color:'#2532d4',
      fontWeight:'bold'
    },
    clickable:{
      color:theme.palette.primary.main,
      fontWeight:'bold',
      cursor:'pointer'
    }
  }));

export default ProductOmsetTable;

import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory,withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCategory } from "reduxs/actions";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';


const Store =(props)=> {

    let { path, url } = useRouteMatch();
    let history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const [categories, setcategories] = useState([]);
    const [mainCategories, setmainCategories] = useState([]);
    const [otherCategories, setotherCategories] = useState([]);
    const [categoryType, setcategoryType] = useState("main");

    const getDefaultHeader =()=>{
        return {"Authorization":`Bearer ${user.token}`}
    }   
        
    
    const getcategories =async()=>{
        const params={}
            
        const response = await doGet(endpoint.category, params, getDefaultHeader());        
        if(!response.error){
          const mains = response.data.filter(category=>category.isMain===1)
          const others = response.data.filter(category=>category.isMain!==1)
          
          setmainCategories(mains)
          setotherCategories(others)

          if(categoryType==="main"){
            setcategories(mains)
           }else{
            setcategories(others)   
           }
        }
       
      }
    
    const changeCategoryType =()=>{
        if(categoryType==="main"){
            setcategoryType("other")
            setcategories(otherCategories)
        }else{
            setcategoryType("main")   
            setcategories(mainCategories)
        }         
    }

    const getImageCategory= ()=>{
        if(categoryType==="main"){
            return "https://tokopahoa.com/asset/next.jpeg"
        }else{
            return "https://tokopahoa.com/asset/back.jpeg"
        }         
    }

    const gotoProductList =(cat)=>{       
        dispatch(setCategory(cat))
        history.push(`${url}/product`);        
    }
    
    useEffect(() => {
        getcategories()
    }, [])


  if(user && user.token){
    return (
      <React.Fragment>
        <CssBaseline/>
        <Grid container justify='center' alignItems='flex-start' className={classes.root}>
            <Grid container spacing={2} justify='center' alignItems='flex-start' className={classes.categories}>
                {
                    categories.map(cat=>(
                        <Grid item xs={12} md={6} lg={4} >
                        <Card key={cat.id} onClick={()=>gotoProductList(cat)}>
                            <CardActionArea >
                                <CardMedia
                                className={classes.category_media}
                                image={cat.image}
                                title={cat.name}
                                />
                                <CardContent>
                                <Typography variant="h5" component="h2">
                                    {cat.name}
                                </Typography>                                
                                </CardContent>
                            </CardActionArea>                            
                        </Card>
                        </Grid>
                    ))
                }
                    <Grid item xs={12} md={6} lg={4}>
                        <Card onClick={changeCategoryType}>
                            <CardActionArea>
                                <CardMedia
                                className={classes.category_media}
                                image={getImageCategory()}
                                title="Product lainnya"
                                />
                                <CardContent>
                                <Typography variant="h5" component="h2">
                                    {
                                        (categoryType==="main" && "Product lainnya") ||
                                        (categoryType==="other" && "Product utama")
                                    }                                    
                                </Typography>                                
                                </CardContent>
                            </CardActionArea>                            
                        </Card>
                        </Grid>
            </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(1)
    },
    categories:{

    },
    category_media: {
        width:'100%',
        height: 200,
      }
  }));


export default withRouter(Store);

import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addCart } from "reduxs/actions";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {jenjangsDummy, kelasDummy, productsDummy} from 'utils/dummy'
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import numeral from 'numeral'
import Conditional from 'components/Conditional';
import Chip from '@material-ui/core/Chip';



const UserProductDetail =(props)=> {
  const {getCartCount} = props
  let { path, url } = useRouteMatch();
  const dispatch = useDispatch()
  let { id } = useParams();
  let history = useHistory();
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const [state, setstate] = useState({
    cover:"",
    name:"",
    description:"",
    sizes:[],     
    stocks:[],
    prices:[],
    images:[],
    jenjang:"",
    kelas:"",
    orders:[],
    priceType:1
  });

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  const getProductDetail =async(id)=>{
    const response = await doGet(endpoint.product+'/'+id, {}, getDefaultHeader());        
    if(!response.error){
      const product = response.data
      setstate({
        id : product.id,
        name : product.name,
        description : product.description,
        jenjang : product.stage_name,
        kelas : product.class_name,
        cover : product.cover,
        prices:product.prices.map(price=>({
          id:price.id,
          sizeId:price.size.id,
          sizeName:price.size.size_label,
          order:price.size.order,
          price:price.price
        })),
        sizes:product.sizes.map(size=>({
          id:size.id,
          name:size.size_label,
          order:size.order
        })),
        stocks:product.stocks.map(stock=>({
          id:stock.id,
          sizeId:stock.size.id,
          sizeName:stock.size.size_label,
          order:stock.size.order,
          stock:parseInt(stock.stock)
        })),
        images : product.images,
        orders:[
          {
            size:{
              id:product.sizes[0].id,
              name:product.sizes[0].size_label,
              order:product.sizes[0].order
            },
            quantity:1
          }
        ],
        priceType:product.priceType
      })      
    }
    
  }   


  const back =()=>{
    history.goBack()
  }

  const setCover =(path)=>{
    setstate({
      ...state,
      cover:path
    })
  }

  const addOrder =(size)=>{
    const existed = state.orders.filter(order=>(order.size.id===size.id))

    if(existed[0]){
      
      if(state.orders.length>1){
        setstate({
          ...state,
          orders:state.orders.filter(order=>(order.size.id!==size.id))
        })
      }
      
    }else{
      let order = {
        size:size,
        quantity:1
      }
  
      setstate({
        ...state,
        orders:[...state.orders, order].sort((a, b) => (a.size.order > b.size.order) ? 1 : -1)
      })
    }    
  }

  const removeOrder =(order)=>{
    if(state.orders.length>1){
      setstate({
        ...state,
        orders:state.orders.filter(existing=>(existing.size.id!==order.size.id))
      })
    }    
  }

  

  const isSizeInclude=(size)=>{
    let result = state.orders.filter(order=>(order.size.id===size.id))
    
    if(result[0]){
      return true
    }else{
      return false
    }
  }

  

  const changeQuantity=(order,action)=>{
    let newOrder = state.orders.filter(item=>(item.size.id===order.size.id))[0]
    let others = state.orders.filter(item=>(item.size.id!==order.size.id))

    if(action==="add"){
      newOrder = {...newOrder, quantity:newOrder.quantity+1}      
    }else{
      if(newOrder.quantity>1){
        newOrder = {...newOrder, quantity:newOrder.quantity-1}
      }      
    }

    setstate({
      ...state,
      orders:[...others,newOrder].sort((a, b) => (a.size.order > b.size.order) ? 1 : -1)
    })

  }

  const submit=async()=>{
    const payload = {
      orders : state.orders.map(order=>({
        product_id : state.id,
        size_id : order.size.id,
        qty : order.quantity
      }))
    }

    const response = await doPost(endpoint.cart, payload,"add to cart",getDefaultHeader())
    if(response && !response.error){
      getCartCount()
    }        
  } 


  const getDescription=(description)=>{
    if(description){
      return {__html: description}
      
    }else{
      return {__html: "<p></p>"}
    }
  }
  
  
  useEffect(() => {
    getProductDetail(id)    
  }, [id])

  

  if(user && user.token && state){
    return (
      <React.Fragment>
        <CssBaseline/>
        <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
            <Grid item xs={1}>              
                <IconButton color="primary" aria-label="go back" component="span" onClick={back}>
                  <ArrowBack />
                </IconButton>              
            </Grid>
            <Grid item xs={11} container spacing={2} justify="center" alignItems='flex-start' className={classes.chipWrapper}>
                                  
            </Grid>            

            
            <Grid container item xs={12} md={6} style={{marginBottom:16}}>
              <Grid container justify="center" alignItems="center" item xs={12} className={classes.productImage}>   
                <img 
                src={state.cover} 
                width="400" height="400"/>             
              </Grid>  
              <Grid container justify="center" className={classes.thumbContainer}>
                {
                  state.images.map(image=>(
                    <Grid onClick={()=>setCover(image.path)} container item justify="center" alignContent="stretch" className={classes.productThumb}>                
                      <img src={image.path} width="50" height="50" className={classes.image} />
                    </Grid>
                  ))                              
                }
              </Grid>             
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container>
                <Typography variant="h4">
                  {state.name}
                </Typography>
              </Grid>
              
              <div className={classes.marginTop16} dangerouslySetInnerHTML={getDescription(state.description)}/>                  
              
              
              <Grid container className={classes.marginTop16}>
                <Grid container item xs={3} md={2} alignItems="flex-start">
                  <Typography variant="body1">
                    Harga
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  <Conditional condition={state.priceType===1}>
                    <Grid container alignItems="center" style={{marginBottom:8}}>              
                      <Grid item xs={8}>
                        <Typography variant="h5">
                        Rp. {state.prices[0] && numeral(state.prices[0].price).format('0,0')}
                        </Typography>
                      </Grid>                          
                    </Grid>
                  </Conditional>

                  <Conditional condition={state.priceType===2}>
                    {
                      state.prices.map(price=>(
                        <Grid container alignItems="center" style={{marginBottom:8}}>              
                          <Grid item xs={3}>
                            {price.sizeName}
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="h5">
                            Rp. {price.price}
                            </Typography>
                          </Grid>                          
                        </Grid>
                      ))                              
                    }
                  </Conditional>
                  
                </Grid>
                
              </Grid>

              <Grid container className={classes.marginTop16}>
                <Grid item container alignContent="center" xs={3} md={2}>
                  <Typography variant="body1">
                    Ukuran
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  {
                    state.sizes.map(size=>(
                      <Chip
                      label={size.name}
                      clickable
                      color="primary"                      
                      variant={isSizeInclude(size)?"default":"outlined"}
                      style={{margin:4}}
                      onClick={()=>addOrder(size)}
                      />                    
                    ))                              
                  }
                  
                </Grid>                  
              </Grid>

              

              <Grid container className={classes.marginTop16}>
                <Grid container item xs={3} md={2} alignItems="flex-start" style={{paddingTop:4}}>
                  <Typography variant="body1" >
                    Jumlah
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                  {
                    state.orders.map(order=>(
                      <Grid container alignItems="center" style={{marginBottom:8}}>
                        <Grid item xs={3} md={3}>
                          {order.size.name}
                        </Grid>
                        <Grid container className={classes.quantityControll}>
                          <Grid container item xs={4} justify="center" alignItems="center">
                            <IconButton
                            onClick={()=>changeQuantity(order,'reduce')}
                            size="small"
                            aria-label="substract"
                            style={{margin:0}}>
                              <Remove fontSize="medium" />
                            </IconButton>
                          </Grid>                  
                          <Grid container item xs={4} justify="center" alignItems="center">
                          {order.quantity}
                          </Grid>                  
                          <Grid container item xs={4} justify="center" alignItems="center">
                            <IconButton
                            onClick={()=>changeQuantity(order,'add')}
                            size="small"
                            aria-label="add"
                            style={{margin:0}}>
                              <Add fontSize="medium" />
                            </IconButton>
                          </Grid>  
                        </Grid>  
                        <Grid container item xs={2} justify="flex-end" alignItems="center">
                          <IconButton
                          onClick={()=>removeOrder(order)}
                          size="small"
                          aria-label="substract"
                          style={{margin:0}}>
                            <Close fontSize="medium" />
                          </IconButton>
                        </Grid>              
                      </Grid>
                    ))                              
                  }                                      
                </Grid>                  
              </Grid>

              <Grid container xs={12} md={6} className={classes.marginTop16}>
                {
                  state.orders.length > 0 &&
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.addToCart}
                    startIcon={<AddShoppingCart />}
                    onClick={submit}
                    fullWidth
                  >
                    Masukkan Keranjang
                  </Button>
                }
                
              </Grid>
            </Grid>

            
            
        </Grid>
      </React.Fragment>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

const useStyles = makeStyles((theme) => ({
    marginTop16:{
      marginTop:16  
    },  
    marginH4:{
      marginLeft:4,
      marginRight:4,
    },      
    root: {
      padding: theme.spacing(1)
    },
    chip:{
      margin:'0 8px 0 8px'
    },
    chipWrapper:{
      marginTop:8
    },
    productContainer:{
      marginTop:16
    },
    category_media: {
      width:'100%',
      height: 200,
    },
    image:{
      width:'100%',   
      cursor:'pointer'    
    },
    productImage:{
            
    },
    thumbContainer:{
      marginTop:8
    },
    productThumb:{
      padding:8,
      width:70,
      height:70,            
      border : '1px solid #c4c4c4',
      borderRadius: 16,
      margin:4      
    },
    quantityControll:{      
      width:120,
      border : '1px solid #c4c4c4',
      borderRadius:50  
    }  

  }));


export default UserProductDetail;


export const endpoint = {
  register:'register',
  login:'login',
  approval:'approval',
  activation:'verified_email',
  forgot_password:'forgot_password',
  update_password:'update_password',
  resend_activation:'resend_email_activated',
  category:'category',
  product:'product',
  product_add:'product/add',
  product_reduce:'product/reduce',
  product_empty:'product/empty',
  size_group_options:'group_size',
  size_options:'size',
  size_group:'size/group',
  size_order:'size/order',
  size:'size',
  jenjang_options:'units',
  admin_kelas_options:'admin/class',
  user_kelas_options:'user/class',
  cart:'cart',
  cart_count:'cart/count',
  order:'order',
  order_receive:'order/receive',
  order_cancel:'order/cancel',
  user_order_return:'user/order/return',
  admin_order_return:'admin/order/return',
  order_deliver:'order/deliver',
  order_admin:'order/admin',
  user_order_id_invoice:'user/order/id/invoice',
  admin_order_id_invoice:'admin/order/id/invoice',
  admin_order_pack:'admin/order/pack',
  order_to_paid:'admin/order/paid',
  reservation:'reservation',
  user_reservation:'user/reservation',
  user_reservation_quota:'user/reservation/quota',
  admin_reservation:'admin/reservation',
  location:'location',
  summary:'summary',
  unpaid_bill:'unpaid_bill',
  
}
export const answerTypes = [
  {
    "value_code": "D",
    "value_name": "Date",
    "value": "D",
    "label": "Date"
  },
  {
    "value_code": "M",
    "value_name": "Multiple Choice",
    "value": "M",
    "label": "Multiple Choice"
  },
  {
    "value_code": "N",
    "value_name": "Number",
    "value": "N",
    "label": "Number"
  },
  {
    "value_code": "S",
    "value_name": "Single Choice",
    "value": "S",
    "label": "Single Choice"
  },
  {
    "value_code": "T",
    "value_name": "Text",
    "value": "T",
    "label": "Text"
  },
  {
    "value_code": "V",
    "value_name": "Need Sub Question",
    "value": "V",
    "label": "Need Sub Question"
  },
  {
    "value_code": "Y",
    "value_name": "Yes or No",
    "value": "Y",
    "label": "Yes or No"
  }
];

import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';



const DeleteConfirmation =(props)=> {
  const {handleClose, handleDelete} = props

  const onDelete = ()=>{
    handleDelete()
    handleClose()
  }

  return (
  <>      
      <DialogContent>
        <DialogContentText> apakah anda yakin menghapus ?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  onClick={onDelete} color="secondary">
          Hapus
        </Button>
        <Button variant="contained" onClick={handleClose} color="primary">
          Batal
        </Button>
      </DialogActions>
  </>

    );
}

export default DeleteConfirmation;

import React,{ useState, useEffect }  from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams,useHistory } from "react-router-dom";
import { setUserLogin } from "reduxs/actions";
import {doPut} from "utils/apiUtil.js"
import {endpoint} from 'utils/constants.js'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Assignment from '@material-ui/icons/Assignment';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useStyles } from './styles.js';
import {userDummy} from 'utils/dummy'



export default function ChangePassword() {
  const classes = useStyles();
  let {id} = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [submitted, setsubmitted] = useState(false);
  const [state, setstate] = useState({
    token:"",
    password:"",
    confirmPassword:""    
  });

  const [errors, seterrors] = useState({    
    password:null,
    confirmPassword:null    
  });

  const getDefaultHeader =()=>{
    return {}
  }

  const submit = async()=>{
    let valid = true
    let newErrors = {
      password:null,
      confirmPassword:null
    }
    
    if(state.password.trim()  === ""){
      valid = false
      newErrors['password'] = "password can not be empty"
    }
    if(state.confirmPassword.trim()  === ""){
      valid = false
      newErrors['confirmPassword'] = "confirmed password can not be empty"
    }
    if(state.confirmPassword.trim()  !== state.password.trim()){
      valid = false
      newErrors['confirmPassword'] = "confirmed password and password can not be different"
    }
    
    if(valid){           
      const payload = {
        token:state.token,
        password:state.password
      }
      const response = await doPut(endpoint.update_password, payload,"update password",getDefaultHeader())
      
      if(response && response.error){
        for (let key in response.error.errors) {
          newErrors[key] = response.error.errors[key]
        }
        setsubmitted(false)
      }
      else
      if(response && response.data){
        setsubmitted(true)
      }
    }

    seterrors(newErrors)
      
  }

  const goLogin =()=>{
    history.push("/login")
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue.target.value

    newState[fieldName] = value
    setstate(newState)
  }

  useEffect(() => {    
    if(typeof id === 'string'){
      setstate({...state, token:id})
    }
  }, [id])

  
  if(submitted){
    return (
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Grid container justify="center" alignItems="center" style={{height:'100vh'}}>
        <Typography variant="h3"> Congratulation </Typography>
          <img src='/welcome.svg' width="300" height="300"/>
          <Grid container justify="center" item xs={12} md={6}>
            <Typography variant="h5"> your password has been changed </Typography>            
            <Typography variant="h6"> we also send the new password to your email </Typography>
          
          </Grid>
          
          <Grid container justify="center" item xs={12} md={4}>
          <Button
            type="button"            
            fullWidth
            variant="contained"
            color="primary"        
            onClick={goLogin}
          >
            Go to Login Page
          </Button>
          </Grid>
          
        </Grid>
        
      </Container>
    )
  }else{
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Assignment />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change to new password
          </Typography>
          
          <form className={classes.form} noValidate>
            
            <TextField
              error={errors.password}
              helperText={errors.password}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="new password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={state.password}
              onChange={(e)=>handleChange("password",e)}
            />

            <TextField
              error={errors.confirmPassword}
              helperText={errors.confirmPassword}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="confirm new password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={state.confirmPassword}
              onChange={(e)=>handleChange("confirmPassword",e)}
            />
            
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={submit}
            >
              Change Password
            </Button>
            
            <Grid container>            
              <Grid item>
                <Link href="/login" variant="body2">
                  {"Already have an account? Login"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        
      </Container>
    );
  }
  
  
  }


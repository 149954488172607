import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { format } from 'date-fns'
import numeral from 'numeral'


const PayInstruction =(props)=> {
  const {handleClose, order, status} = props
  const classes = useStyles();


  if(status==="expired"){
    return (
      <>
      <DialogContent>
        <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
          <Grid container>  
            <Typography variant="body">
              Pembayaran sudah kadaluarsa 
            </Typography>      
          </Grid>
        </Grid>          
      </DialogContent>
      <DialogActions>
      <Button onClick={handleClose} color="primary" variant="contained">
        OK
      </Button>      
    </DialogActions>
    </>
    );
  } 
  else
  if(order && order.paymentType==="2" && status!=="expired"){
    return (
      <>
      <DialogContent>
      <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
        <Grid container>  
          Segera lakukan pembayaran sebagai berikut :
        </Grid>
        <Grid container>  
          Sebelum
        </Grid> 
        
        <Grid container alignItems="center">  
          <Grid item xs={4} md={2}>            
            Pukul
          </Grid>
          <Grid item xs={8}  md={10}>   
            <Typography variant="h5">
              {format(order.expired, 'HH:mm')}
            </Typography>
          </Grid>          
        </Grid>        

        <Grid container>  
          <Grid item xs={4} md={2}>            
            Tanggal
          </Grid>
          <Grid item xs={8}  md={10}>   
          {format(order.expired, 'dd MMMM yyyy')}
          </Grid>          
        </Grid>  
        <Grid container alignItems="center">  
          <Grid item xs={4} md={2}>            
            jumlah
          </Grid>
          <Grid item xs={8}  md={10}>   
            <Typography variant="h5">
              Rp. {numeral(order.price).format('0,0')}
            </Typography>          
          </Grid>          
        </Grid> 

        <Grid container alignItems="center">  
          transfer ke virtual account BCA :          
        </Grid> 
        <Grid container>  
          <Typography variant="h5">
            {order.orderNumber}
          </Typography>
        </Grid>         

                             
               
      </Grid>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleClose} color="primary" variant="contained">
        OK
      </Button>      
    </DialogActions>
    </>
    );
  }
  else
  if(order && order.paymentType==="1"){
    return (
      <>
      <DialogContent>
        <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
          <Grid container>  
            <Typography variant="body">
              Anda dapat melakukan pembayaran langsung di 
            </Typography>      
          </Grid>
          
          <Grid container>  
            <Typography variant="h5">
              {order.pickup.location && order.pickup.location.name} 
            </Typography>      
          </Grid>

          <Grid container>  
            <Typography variant="body">
              pada tanggal
            </Typography>      
          </Grid>
          <Grid container>  
            <Typography variant="h5">
            {format(order.pickupDate, 'dd MMMM yyyy')}
            </Typography>      
          </Grid>

          <Grid container>  
            <Typography variant="body">
              pada Jam
            </Typography>      
          </Grid>
          <Grid container >  
            <Typography variant="h5">
            {`${order.pickup.open.substring(11,16)} sampai ${order.pickup.close.substring(11,16)} `}
            </Typography>      
          </Grid>

          <Grid container>  
            <Typography variant="body">
            dengan menunjukkan invoice      
            </Typography>      
          </Grid>                                                                      
        </Grid>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleClose} color="primary" variant="contained">
        OK
      </Button>      
    </DialogActions>
    </>
    );
  }
  else{
    return null
  }

}

const useStyles = makeStyles((theme) => ({
    marginTop16:{
      marginTop:16  
    },      
    
    
  }));


export default PayInstruction;

/*
author alka@2019
*/
import { applyMiddleware,createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import appReducer from "./reducers";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage  
}

const persistedReducer = persistReducer(persistConfig, appReducer)

/*
export default () => {
  let store = createStore(persistedReducer, composeWithDevTools());
  let persistor = persistStore(store)
  return { store, persistor }  
}
*/



const store = createStore(appReducer, composeWithDevTools());
export default store;
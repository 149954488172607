import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {cartDummy} from 'utils/dummy'
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Remove from '@material-ui/icons/Remove';
import Search from '@material-ui/icons/Search';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import DebouncedTextField from 'components/DebouncedTextField.js';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Pill from 'components/Pill';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { format } from 'date-fns'
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme'
import numeral from 'numeral'
import Confirmation from 'components/Confirmation.js';
import Conditional from 'components/Conditional';
import Protected from 'components/Protected';



const ReturnForm =(props)=> {
  const {handleClose, user, selectedOrder, setReturnedProduct} = props
  const classes = useStyles();
  const [reasonOptions, setreasonOptions] = useState([
    { label:"ukuran tidak sesuai", value:1},
    { label:"rusak", value:2}
  ]);
  const [state, setstate] = useState({
    reason:reasonOptions[0],
    orders:[],
    selecteds:[]
  });  

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const selectOrder =(order)=>{
    let existed = state.selecteds.filter(item=>(item.id===order.id))[0]
    if(existed){
      setstate({
        ...state,
        selecteds:state.selecteds.filter(item=>(item.id!==order.id))
      })
    }else{
      setstate({
        ...state,
        selecteds:[...state.selecteds,order]
      })      
    }
  }

  const isSelected =(order)=>{
    if(state.selecteds.filter(item=>(item.id===order.id))[0]){
      return true
    }else{
      return false
    }
  }

  const changeQuantity=(order,action)=>{
    let newOrder = state.selecteds.filter(item=>(item.size.id===order.size.id))[0]
    let others = state.selecteds.filter(item=>(item.size.id!==order.size.id))

    if(action==="add"){
      let newQty = newOrder.qty+1 > newOrder.max ? newOrder.max : newOrder.qty+1
      newOrder = {...newOrder, qty:newQty}      
    }else{
      if(newOrder.qty>1){
        newOrder = {...newOrder, qty:newOrder.qty-1}
      }      
    }

    setstate({
      ...state,
      selecteds:[...others,newOrder].sort((a, b) => (a.size.order > b.size.order) ? 1 : -1)
    })

  }

  const userReturnOrder =async()=>{              
    
    const payload = {
        products:state.selecteds.map(order=>({
          order_id:order.order_id,
          product_id:order.product_id,
          size_id:order.size_id,
          quantity:order.qty,
          reason:state.reason.value
        }))
      }      

      const response = await doPost(endpoint.user_order_return, payload,"pengajuan pengembalian",getDefaultHeader())
      
      if(response && !response.error){
        handleClose()
        setReturnedProduct(state.selecteds)
      }
    
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}   
    
    newState[fieldName] = eventValue
    setstate(newState)
  }

  useEffect(() => {
    
    if(selectedOrder){
      setstate({
        ...state,
        selecteds:  selectedOrder.products.map(product=>({
          ...product,
          max:product.qty
        })),
        orders:[...selectedOrder.products]
      })
    }
    
  }, [selectedOrder])



  if(state){
    return (
      <>
      <DialogContent>
      <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>               
        
        <Grid container>  
          Produk yang ingin dikembalikan
        </Grid> 

        <Protected user={user} allowedCodes="std">        
        <Grid container>  
          {state.orders &&
                  state.orders.map(order=>(
                    
                    <Paper elevation={3} style={{width:'100%', margin:'8px 0', padding:'8px 0 8px 0'}}>
                      <Grid container justify="flex-start" item xs={12}>                                               
                        <Grid container item xs={2} justify="center" alignItems="center" item style={{width:50}}>
                          <Checkbox
                            checked={isSelected(order)}
                            onChange={()=>selectOrder(order)}                        
                          />
                        </Grid>
                        <Grid container xs={3} justify="flex-start" alignItems="center" item style={{width:100}}>
                            { order.product && 
                              <img src={order.product.cover} width="50" height="50"/>
                            }
                        </Grid>
                        <Grid container  justify="center" item xs={7} alignItems="center" >
                          <Grid container justify="center" item xs={12} md={6} alignItems="center">
                            <Typography variant="body2">
                              {
                                order.product && order.product.name
                              }
                            </Typography>                          
                          </Grid>
                          <Grid container justify="center"  item xs={12} md={2} alignItems="center">
                            <Typography variant="body2">
                              {
                                order.size && order.size.size_label
                              }
                            </Typography>                          
                          </Grid>
                          
                          <Conditional condition={isSelected(order)}>
                            <Grid container justify="center" item xs={12} md={3} className={classes.quantityControll}>
                              <Grid container item xs={4} justify="center" alignItems="center">
                                <IconButton
                                onClick={()=>changeQuantity(order,'reduce')}
                                size="small"
                                aria-label="substract"
                                style={{margin:0}}>
                                  <Remove fontSize="medium" />
                                </IconButton>
                              </Grid>                  
                              <Grid container item xs={4} justify="center" alignItems="center">
                              {order.qty}
                              </Grid>                  
                              <Grid container item xs={4} justify="center" alignItems="center">
                                <IconButton
                                onClick={()=>changeQuantity(order,'add')}
                                size="small"
                                aria-label="add"
                                style={{margin:0}}>
                                  <Add fontSize="medium" />
                                </IconButton>
                              </Grid>  
                            </Grid>                                                  
                          </Conditional>                          
                        </Grid>                            
                      </Grid>
                      </Paper>  
                                     
                  ))
                }
        </Grid> 

        <Grid container item xs={12}>
          
          <Select                
          name="size"
          placeholder = "pilih alasan"
          options={reasonOptions}
          value={state.reason}
          onChange={(e)=>{handleChange('reason',e)}}
          styles={selectCustomZindex}
          />       
        </Grid>    
        </Protected>

        <Protected user={user} allowedCodes="emp">
        <Grid container>  
          {state.returned &&
                  state.returned.map(product=>(                    
                    <Paper elevation={3} style={{width:'100%', margin:'8px 0', padding:'8px 0 8px 0'}}>
                      <Grid container justify="space-around" item xs={12}>                                               
                        
                        <Grid container  item xs={6} alignItems="flex-start" >
                          <Grid container item xs={12} md={6} alignItems="center">
                            <Typography variant="body2">
                              {
                                product.product && product.product.name
                              }
                            </Typography>                          
                          </Grid>
                          <Grid container item xs={12} md={5} alignItems="center">
                            <Typography variant="body2">
                              {
                                product.size && product.size.size_label
                              }
                            </Typography>                          
                          </Grid>
                          
                          
                          <Grid container className={classes.quantityControll}>
                            <Grid container item xs={4} justify="center" alignItems="center">
                            {product.qty}
                            </Grid>                                               
                          </Grid>                                                  
                          
                        </Grid>                            
                      </Grid>
                      </Paper>  
                                     
                  ))
                }
        </Grid> 
        </Protected> 
      </Grid>
      </DialogContent>
      <DialogActions>
      <Conditional condition={state.selecteds.length > 0}>
        <Button onClick={userReturnOrder} color="secondary">
          Kembalikan
        </Button>
      </Conditional>      
      <Button onClick={handleClose} color="primary" variant="contained">
        Batal
      </Button>      
    </DialogActions>

    </>
    );
  }
  else{
    return null
  }

}

const useStyles = makeStyles((theme) => ({
    root:{
      height:250
    },
    marginTop16:{
      marginTop:16  
    },      
    quantityControll:{      
      width:120,
      border : '1px solid #c4c4c4',
      borderRadius:50  
    }
    
    
  }));


export default ReturnForm;

import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

const SizeGroupForm =(props)=> {
  const { handleClose, actionType, sizeGroup, user, getSizeGroups, clearGroupSizeSelection} = props
  const classes = useStyles();
  const [state, setstate] = useState({})

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    newState[fieldName] = value
    setstate(newState)
  }

  const add=async()=>{
    const payload = {
      name:state.name
    }
    const response = await doPost(endpoint.size_group, payload,"add group",getDefaultHeader())
    
    if(response.error){
      
    }else{
      getSizeGroups()
      handleClose()
    }      
  }

  const edit=async()=>{
    const payload = {
      id:state.id,
      name:state.name
    }
    const response = await doPut(endpoint.size_group, payload,"edit group",getDefaultHeader())
    
    if(response.error){
      
    }else{
      getSizeGroups()
      handleClose()
    }
  }
  
  const submit = ()=>{
    if(actionType==="add"){
        add()
    }else if(actionType==="edit"){
        edit()
    }
  }


  useEffect(() => {
    if(sizeGroup){
        setstate({
          id:sizeGroup.id, 
          name:sizeGroup.name
        })
    }else{
        setstate({
            name:""
        })
    }
  }, [sizeGroup])

  return (
    <>
      <DialogContent>
        <Grid container justify='center' alignItems='flex-start'>
          <DebouncedTextField
            margin="dense"
            id="Name"
            label="Name"
            fullWidth
            value={state.name}
            onChange={(e)=>handleChange("name",e)}
          />
        </Grid> 
        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Kembali
        </Button>
        <Button variant="contained" onClick={submit} color="primary">
          Simpan
        </Button>
      </DialogActions>
</>

    );
}

export default SizeGroupForm;

const useStyles = makeStyles((theme) => ({

}));

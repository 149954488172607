import React,{useState, useEffect} from 'react';
import clsx from 'clsx';
import {doGet, doPost, doDelete, doPatch} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {locationDummy, scheduleDummy} from 'utils/dummy'
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Remove from '@material-ui/icons/Remove';
import Search from '@material-ui/icons/Search';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import DebouncedTextField from 'components/DebouncedTextField.js';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import PickupLocationForm from './PickupLocationForm.js';
import PickupScheduleForm from './PickupScheduleForm.js';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { format,parse } from 'date-fns'
import { Group } from '@material-ui/icons';
import SizeGroupForm from './SizeGroupForm.js';
import SizeForm from './SizeForm.js';
import { generalOptionMapper } from 'utils/mappers.js';

const Setting =(props)=> {

  let { path, url } = useRouteMatch();
  let history = useHistory();
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const [locations, setlocations] = useState([]);
  const [schedules, setschedules] = useState([]);
  const [sizeGroups, setsizeGroups] = useState([]);
  const [openDialog, setopenDialog] = React.useState(false);
  const [expandSizeGroup, setexpandSizeGroup] = React.useState(0);
  const [dialogWidth, setdialogWidth] = useState('sm');
  const [dialogContent, setdialogContent] = useState(null);
  const [selectedGroup, setselectedGroup] = useState({});
  const [selectedSize, setselectedSize] = useState({});
  const [selectedLocation, setselectedLocation] = useState(null);
  const [selectedSchedule, setselectedSchedule] = useState(null);
  const [expandedSchedule, setexpandedSchedule] = useState(0);

  const [dialogTitle, setdialogTitle] = useState('title');
 
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  const back =()=>{
    history.goBack()
  }

  const getlocations =async()=>{
    const params={}
        
    const response = await doGet(endpoint.location, params, getDefaultHeader());        
    if(!response.error){
      const locations = response.data      
      setlocations(locations)
    }
  }

  
  const getschedules =async()=>{

    let params={      
    }

  
    const response = await doGet(endpoint.admin_reservation, params, getDefaultHeader());        
    
    if(!response.error){
      const reservations = response.data     
      setschedules(reservations.map(schedule=>({
        id:schedule.id,            
        date:parse(schedule.date, 'yyyy-MM-dd', new Date()),            
        sessions:schedule.sessions.map(session=>({
          id:session.id,
          number:session.number,
          open:parse(session.open, 'yyyy-MM-dd HH:mm:ss', new Date()),
          close:parse(session.close, 'yyyy-MM-dd HH:mm:ss', new Date()),
          quota:session.quota,
          location:generalOptionMapper(session.location)        
        }))
      })))       
    }
  
  }
  
  const getSizeGroups =async()=>{

    let params={      
    }  
    const response = await doGet(endpoint.size_group, params, getDefaultHeader());        
    if(!response.error){
      const groups = response.data     
      setsizeGroups(groups.map(group=>({
        id:group.id,
        name:group.name,
        sizes:group.sizes.sort((a, b) => (a.order > b.order) ? 1 : -1)
      })))
      if(selectedGroup.id){
        const group = groups.filter(group=>group.id===selectedGroup.id)[0]
        if(group){
          setselectedGroup(group)
        }
        
      }
    }
  }

  
  
  const toggleSession =(schedule, e)=>{    
    e.stopPropagation();
    setexpandedSchedule(expandedSchedule===0 || expandedSchedule!== schedule.id ? schedule.id : 0)
  }
  
  const closeDialog =()=>{    
    setopenDialog(false)
    setdialogContent(null)
  }

  const clearGroupSizeSelection =()=>{    
    setselectedGroup({})
    setselectedSize({})
  }

  const clickLocation =(location)=>{    
    if(selectedLocation && location.id===selectedLocation.id){
      setselectedLocation(null)
    }else{
      setselectedLocation(location)
    }
    
  }

  const clickSchedule =(schedule)=>{    
    if(selectedSchedule && schedule.id===selectedSchedule.id){
      setselectedSchedule(null)
    }else{
      setselectedSchedule(schedule)
    }
  }


  const deleteLocation =async()=>{    
    const payload = {
      id:[{
          id:selectedLocation.id
          }
        ]
      }
    const response = await doDelete(endpoint.location, payload,"delete location",getDefaultHeader())
    
    if(response && !response.errors){
      getlocations()    
      closeDialog()
    }
     
  }

  const deleteSchedule =async()=>{    
    const payload = {
      id:[selectedSchedule.id]
      }
      
    const response = await doDelete(endpoint.reservation, payload,"delete shcedule",getDefaultHeader())
    if(response && !response.errors){
      getschedules()    
      closeDialog()
    }
    
  }

  const deleteGroup =async()=>{    
    const payload = {
      id:[{
          id:selectedGroup.id
          }
        ]
      }
      
    const response = await doDelete(endpoint.size_group, payload,"delete group",getDefaultHeader())
    if(response && !response.errors){
      clearGroupSizeSelection()
      getSizeGroups()    
      closeDialog()
    }    
  }

  const deleteSize =async()=>{    
    const payload = {
      id:[{
          id:selectedSize.id
          }
        ]
      }
      
    const response = await doDelete(endpoint.size, payload,"delete size",getDefaultHeader())
    if(response && !response.errors){
      clearGroupSizeSelection()
      getSizeGroups()    
      closeDialog()
    }   
  }

  
  const btnAddLocation =()=>{    
    setopenDialog(true)
    setdialogTitle("Tambah Lokasi")
    setdialogContent(
      <PickupLocationForm       
      actionType="add"      
      handleClose={closeDialog}
      user={user}
      getlocations={getlocations}
      />
    )
  }
  const btnEditLocation =()=>{    
    setopenDialog(true)
    setdialogTitle("Ubah Lokasi")
    setdialogContent(
      <PickupLocationForm 
      location={selectedLocation}
      actionType="edit"      
      user={user}
      handleClose={closeDialog}
      getlocations={getlocations}
      />
    )
  }
  const btnDeleteLocation =()=>{    
    setopenDialog(true)
    setdialogTitle("Hapus Lokasi")
    setdialogContent(<DeleteConfirmation handleDelete={deleteLocation} handleClose={closeDialog} />)
  }

  const btnAddSchedule =()=>{    
    setopenDialog(true)
    setdialogTitle("Tambah Jadwal")
    setdialogWidth('lg')
    setdialogContent(
      <PickupScheduleForm       
      actionType="add"      
      handleClose={closeDialog}
      getschedules={getschedules}
      user={user}
      locations={locations}
      
      />
    )
  }
  const btnEditSchedule =()=>{    
    setopenDialog(true)
    setdialogTitle("Ubah Jadwal")
    setdialogWidth('lg')
    setdialogContent(
      <PickupScheduleForm 
      schedule={selectedSchedule}
      actionType="edit"      
      handleClose={closeDialog}
      getschedules={getschedules}
      user={user}
      locations={locations}
      setselectedSchedule={setselectedSchedule}
      />
    )
  }

  const btnAddGroup =()=>{    
    setopenDialog(true)
    setdialogTitle("Tambah Group")
    setdialogContent(
      <SizeGroupForm       
      actionType="add"      
      handleClose={closeDialog}
      user={user}
      getSizeGroups={getSizeGroups}
      clearGroupSizeSelection={clearGroupSizeSelection}
      />
    )
  }
  const btnEditGroup =()=>{    
    setopenDialog(true)
    setdialogTitle("Ubah Group")
    setdialogContent(
      <SizeGroupForm 
      sizeGroup={selectedGroup}
      actionType="edit"      
      user={user}
      handleClose={closeDialog}
      getSizeGroups={getSizeGroups}
      clearGroupSizeSelection={clearGroupSizeSelection}
      />
    )
  }

  const btnAddSize =()=>{    
    setopenDialog(true)
    setdialogTitle("Tambah Ukuran")
    setdialogContent(
      <SizeForm       
      actionType="add"      
      sizeGroup={selectedGroup}
      handleClose={closeDialog}
      user={user}
      getSizeGroups={getSizeGroups}
      clearGroupSizeSelection={clearGroupSizeSelection}
      />
    )
  }
  const btnEditSize =()=>{    
    setopenDialog(true)
    setdialogTitle("Ubah Ukuran")
    setdialogContent(
      <SizeForm 
      size={selectedSize}
      actionType="edit"      
      user={user}
      handleClose={closeDialog}
      getSizeGroups={getSizeGroups}
      clearGroupSizeSelection={clearGroupSizeSelection}
      />
    )
  }

  const upSizeOrder =async(size)=>{    
    const payload = {
      id:size.id,
      order: size.order-1
    }
      
    const response = await doPatch(endpoint.size_order, payload,"up size",getDefaultHeader())
    if(response && !response.errors){
      getSizeGroups()    
    } 
  }

  const downSizeOrder =async(size)=>{    
    const payload = {
      id:size.id,
      order: size.order+1
    }
      
    const response = await doPatch(endpoint.size_order, payload,"down size",getDefaultHeader())
    if(response && !response.errors){
      getSizeGroups()    
    } 
  }

  

  const btnDeleteSchedule =()=>{    
    setopenDialog(true)
    setdialogTitle("Hapus Jadwal")
    setdialogContent(<DeleteConfirmation handleDelete={deleteSchedule} handleClose={closeDialog} />)
  }
  
  const selectGroup =(group)=>{    
    setselectedGroup(group)
    setexpandSizeGroup(expandSizeGroup===0 || expandSizeGroup!== group.id ? group.id : 0)
    setselectedSize({})
  }
  
  const selectSize =(size)=>{    
    setselectedSize(size)
  }

  const btnDeleteGroup =()=>{    
    setopenDialog(true)
    setdialogTitle("Hapus Group")
    setdialogContent(<DeleteConfirmation handleDelete={deleteGroup} handleClose={closeDialog} />)
  }

  const btnDeleteSize =()=>{    
    setopenDialog(true)
    setdialogTitle("Hapus Ukuran")
    setdialogContent(<DeleteConfirmation handleDelete={deleteSize} handleClose={closeDialog} />)
  }

  useEffect(() => {
    getlocations()
    getschedules()
    getSizeGroups()
  }, [])

  useEffect(() => {
    /*const updateSizeOrder =async(size)=>{    
      const payload = {
        id:size.id,
        order: size.order
      }
        
      const response = await doPatch(endpoint.size_order, payload,"down size",getDefaultHeader())
      
    }
    if(sizeGroups){
      sizeGroups.map(group=>{
        let order = 1;
        group.sizes.map(size=>{
          updateSizeOrder({...size, order:order++})
        })
      })
    }
    */
  }, [sizeGroups])
  

  

  if(user && user.token){
    return (
      <React.Fragment>
        <CssBaseline/>
        <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
            <Grid item xs={1}>              
                <IconButton color="primary" aria-label="go back" component="span" onClick={back}>
                  <ArrowBack />
                </IconButton>              
            </Grid>
            <Grid container item xs={11} justify="flex-end" alignItems="center" >                                                    
                
            </Grid>          
            
            
            <Grid container item xs={12} md={6} justify="center"  style={{minHeight:400, margin:'4px 0', padding:8}}>
              <Paper elevation={1} style={{width:'100%', padding:8}}>
              <Grid container item xs={12} justify="center">
                <Typography variant="h6">Lokasi Ambil Barang</Typography>
                <Divider style={{width:'100%', padding:'0 8px'}} />
              </Grid>
              <Grid container item xs={12} justify="flex-end" style={{margin:'8px 0'}}>                
                {
                  selectedLocation &&
                  <>
                  <Button variant="contained" size="small" onClick={btnDeleteLocation} color="secondary" style={{margin:'0 8px'}}>
                    Delete
                  </Button>
                  <Button variant="contained" size="small" onClick={btnEditLocation} color="default" style={{margin:'0 8px'}}>
                    Edit
                  </Button>
                  </>
                }
                
                <Button variant="contained" size="small" onClick={btnAddLocation} color="primary" style={{margin:'0 8px'}}>
                  Add
                </Button>
              </Grid>    
              <Grid container item xs={12} alignContent="flex-start" justify="center" className={classes.itemsContainer}>
                {
                  locations.map(location=>(
                    
                      <Grid 
                      onClick={(e)=>clickLocation(location)} 
                      container 
                      item xs={12} 
                      justify="center" 
                      alignItems="center" 
                      className={clsx({
                        [classes.item]:!selectedLocation || selectedLocation.id!==location.id, 
                        [classes.selectedItem]:selectedLocation && selectedLocation.id===location.id})}
                      >
                        <Typography variant="h5">{location.name}</Typography>
                      </Grid>
                    
                  ))
                }
              </Grid> 
              </Paper>                                                                          
            </Grid> 

            <Grid container item xs={12} md={6} justify="center"  style={{minHeight:400, margin:'4px 0', padding:8}}>
              <Paper elevation={1} style={{width:'100%', padding:8}}>
              <Grid container item xs={12} justify="center">
                <Typography variant="h6">Jadwal Ambil Barang</Typography>
                <Divider style={{width:'100%', padding:'0 8px'}} />
              </Grid>
              <Grid container item xs={12} justify="flex-end" style={{margin:'8px 0'}}>
              {
                  selectedSchedule &&
                  <>
                  <Button variant="contained" size="small" onClick={btnDeleteSchedule} color="secondary" style={{margin:'0 8px'}}>
                    Delete
                  </Button>
                  <Button variant="contained" size="small" onClick={btnEditSchedule} color="default" style={{margin:'0 8px'}}>
                    Edit
                  </Button>
                  </>
                }<Button variant="contained" size="small" onClick={btnAddSchedule} color="primary" style={{margin:'0 8px'}}>
                  Add
                </Button>
              </Grid>    
              <Grid container item xs={12} alignContent="flex-start" justify="center" className={classes.itemsContainer}>
                {
                  schedules.map(schedule=>(
                    
                      <Grid onClick={(e)=>clickSchedule(schedule)} container item justify="space-between" alignItems="center" xs={12} 
                      className={clsx({
                        [classes.item]:!selectedSchedule || selectedSchedule.id!==schedule.id, 
                        [classes.selectedItem]:selectedSchedule && selectedSchedule.id===schedule.id})}
                      >
                        <Grid container justify="center" alignItems="center">
                          <Typography variant="h6">{format(schedule.date, 'd MMMM yyyy')}</Typography>
                        </Grid>                        
                          
                          <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"                    
                            className={classes.list}
                          >  
                              <ListItem button onClick={(e)=>{toggleSession(schedule,e)}}>
                                <ListItemText primary={"sessions"} />
                                {expandedSchedule===schedule.id? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              {
                                <Collapse in={expandedSchedule===schedule.id} timeout="auto" unmountOnExit>
                                  {
                                    schedule.sessions.map(session=>(
                                    <List component="div">
                                      <ListItem button className={classes.nested} onClick={()=>{}}>
                                        <Grid container justify="space-between">
                                            <Grid item>
                                              {session.number}
                                            </Grid>
                                            <Grid item>
                                              {format(session.open, 'HH:mm')}
                                              {` - `}
                                              {format(session.close, 'HH:mm')}
                                            </Grid>                                            
                                            <Grid item>
                                              {session.location.name}
                                            </Grid>
                                          </Grid>
                                      </ListItem>
                                    </List>
                                    ))
                                  }  
                                </Collapse>                    
                              }                                                                                                 
                                               
                          </List> 
                          
                  </Grid>
                    
                  ))
                }
              </Grid> 
              </Paper>                                                                          
            </Grid> 


            <Grid container item xs={12} md={6} justify="center"  style={{minHeight:400, margin:'4px 0', padding:8}}>
              <Paper elevation={1} style={{width:'100%', padding:8}}>
              <Grid container item xs={12} justify="center">
                <Typography variant="h6">Ukuran</Typography>
                <Divider style={{width:'100%', padding:'0 8px'}} />
              </Grid>
              <Grid container item xs={12} justify="flex-end" style={{margin:'8px 0'}}>                
                {
                  selectedGroup.id && (!selectedSize.id) &&
                  <>
                  <Button variant="contained" size="small" onClick={btnDeleteGroup} color="secondary" style={{margin:8}}>
                    Delete Group
                  </Button>
                  <Button variant="contained" size="small" onClick={btnEditGroup} color="default" style={{margin:8}}>
                    Edit Group
                  </Button>
                  </>
                }
                {
                  selectedSize.id &&
                  <>
                  <Button variant="contained" size="small" onClick={btnDeleteSize} color="secondary" style={{margin:8}}>
                    Delete Size
                  </Button>
                  <Button variant="contained" size="small" onClick={btnEditSize} color="default" style={{margin:8}}>
                    Edit Size
                  </Button>
                  </>
                }
                
                <Button variant="contained" size="small" onClick={btnAddSize} color="primary" style={{margin:8}}>
                  Add Size
                </Button>
                <Button variant="contained" size="small" onClick={btnAddGroup} color="primary" style={{margin:8}}>
                  Add Group
                </Button>

              </Grid>    
              <Grid container item xs={12} alignContent="flex-start" justify="center" className={classes.itemsContainer}>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"                    
                    className={classes.list}
                  >  
                  {sizeGroups.map(group=>(
                    <>
                      <ListItem selected={(!selectedSize.id) && selectedGroup.id===group.id} button onClick={()=>selectGroup(group)}>
                        <ListItemText primary={group.name} />
                        {expandSizeGroup===group.id ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      {
                        group.sizes &&
                        <Collapse in={expandSizeGroup===group.id} timeout="auto" unmountOnExit>
                          {
                            group.sizes.map(size=>(
                            <List component="div">
                              <ListItem selected={selectedSize.id===size.id} button className={classes.nested} onClick={()=>selectSize(size)}>
                                <ListItemAvatar>
                                  <IconButton size="small" onClick={()=>upSizeOrder(size)} edge="end" aria-label="up">
                                    <ArrowUpward fontSize="inherit" />
                                  </IconButton>
                                </ListItemAvatar>
                                
                                <ListItemText primary={`${size.order}) ${size.name}`} />

                                <ListItemSecondaryAction>
                                  <IconButton size="small" onClick={()=>downSizeOrder(size)} edge="end" aria-label="down">
                                    <ArrowDownward fontSize="inherit" />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            </List>
                            ))
                          }  
                        </Collapse>                    
                      }
                      
                    </>
                  ))                  
                  }                     
                  </List>                
              </Grid> 
              </Paper>                                                                          
            </Grid>                                    
            
            
        </Grid>

        <Dialog
        fullWidth={true}
        maxWidth={dialogWidth}
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

const useStyles = makeStyles((theme) => ({
    marginTop16:{
      marginTop:16  
    },  
    marginH4:{
      marginLeft:4,
      marginRight:4,
    },  
    root: {
      padding: theme.spacing(1)
    },
    itemsContainer:{
      margin:'8px 0',
      overflowY:'scroll',
      height:300,      
    },
    selectedItem:{
      cursor:'pointer',
      borderRadius:4,
      margin:'8px 0',
      background:'linear-gradient(90deg, #685dca 0%,#3023bb 50%,#4141ca 100% )',
      color:'white',
      minHeight:70,
      padding:8
    },
    item:{
      cursor:'pointer',      
      borderRadius:4,
      margin:'8px 0',
      background:'#d5ecff',
      color:'#2532d4',
      minHeight:70,
      padding:8
    },
    
    box:{
      borderRadius:4,
      background:'white',
      color:'#4141ca',
      width:'30%',
      padding:4
    },
    locationBox:{
      borderRadius:4,
      background:'white',
      color:'#4141ca',      
      padding:4,
      marginTop:8
    },
    paper:{
      width:'100%', 
      padding:8,
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      direction:'row'            
    },
    list:{
      width:'100%', 
    }
    
    
  }));


export default Setting;

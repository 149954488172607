import React,{ useState }  from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect,useHistory } from "react-router-dom";
import {doPost} from "utils/apiUtil.js"
import {endpoint} from 'utils/constants.js'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Assignment from '@material-ui/icons/Assignment';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useStyles } from './styles.js';



export default function ResendActivation() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [submitted, setsubmitted] = useState(false);
  const [state, setstate] = useState({
    email:""
  });

  const [errors, seterrors] = useState({
    email:null
  });

  const getDefaultHeader =()=>{
    return {}
  }

  const submit = async()=>{
    let valid = true
    let newErrors = {
      email:null
    }

    if(state.email.trim()  === ""){
      valid = false      
      newErrors['email'] = "email can not be empty"
    }
    
    
    if(valid){           
      const payload = {
        email:state.email
      }
      const response = await doPost(endpoint.resend_activation, payload,"resend email",getDefaultHeader())
      
      if(response.error){
        for (let key in response.error.errors) {
          newErrors[key] = response.error.errors[key]
        }
      }else{
        setsubmitted(true)            
      }      
    }

    seterrors(newErrors)
      
  }

  const goLogin =(path)=>{
    history.push("/login")
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue.target.value

    newState[fieldName] = value
    setstate(newState)
  }

  
  if(submitted){
    return (
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Grid container justify="center" alignItems="center" style={{height:'100vh'}}>
        <Typography variant="h3"> your request is being processed </Typography>
          <img src='/processed.png' width="300" height="300"/>
          <Grid container justify="center" item xs={12} md={6}>
          <Typography variant="h6"> we will send link to your email </Typography>
          <Typography variant="h5"> please check your email periodically</Typography>            
          </Grid>
          
          <Grid container justify="center" item xs={12} md={4}>
          <Button
            type="button"            
            fullWidth
            variant="contained"
            color="primary"        
            onClick={goLogin}
          >
            Go to Login Page
          </Button>
          </Grid>
          
        </Grid>
        
      </Container>
    )
  }else{
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Assignment />
          </Avatar>
          <Typography component="h1" variant="h5">
            Resend activation link to your email
          </Typography>
          
          <form className={classes.form} noValidate>
            <TextField
              error={errors.email}
              helperText={errors.email}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="email"
              name="email"
              value={state.email}
              onChange={(e)=>handleChange("email",e)}
            />
            
            
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={submit}
            >
              Send
            </Button>
            
            <Grid container>            
              <Grid item>
                <Link href="/login" variant="body2">
                  {"Back to login page"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        
      </Container>
    );
  }
  
  
  }


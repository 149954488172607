import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doSilentPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import ImageUploader from "react-images-upload";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Conditional from 'components/Conditional.js';
import Badge from "@material-ui/core/Badge";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { format, lastDayOfMonth, setDate, isAfter } from 'date-fns'
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme'
import Hidden from '@material-ui/core/Hidden';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { generalListOptionMapper } from 'utils/mappers.js';

const ReservationForm =(props)=> {
  const { user, handleClose, setSession} = props
  const classes = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [state, setstate] = useState({
    displayMonth: setDate(new Date(),1),
    date:new Date(),
    time:new Date(),
    availableDates:[],
    selectedAvailableDate:{},
    selectedSession:{},
    availableQuota:1
  })  
  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const getAvailableSchedules =async(date)=>{
    
    let params={
      startDate: format(date, 'yyyy-MM-dd'),      
      endDate:format(lastDayOfMonth(date), 'yyyy-MM-dd')
    }

  
    const response = await doGet(endpoint.user_reservation, params, getDefaultHeader());        
    if(!response.error){
      const reservations = response.data     
      setstate({...state,
        availableDates:reservations        
      })       
    }
  } 

  const getAvailableQuota =async(sesionId, newState)=>{
    let params={}
  
    const response = await doGet(endpoint.user_reservation_quota+"/"+sesionId, params, getDefaultHeader());        
    if(!response.error){
      const reservations = response.data     
      setstate({...newState,
        availableQuota:reservations        
      })       
      
    }
  } 


  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    
    if(fieldName==='displayMonth'){
      getAvailableSchedules(eventValue)
    }
    else
    if(fieldName==='date'){
      if(isAfter(value, new Date())){
        let available = state.availableDates.filter(item=>item.date === format(value,'yyyy-MM-dd'))[0]
        if(available){
          newState['selectedAvailableDate'] = available
        }else{
          newState['selectedAvailableDate'] = {}
          newState['selectedSession'] = {}
        }
      }else{
        newState['selectedAvailableDate'] = {}
        newState['selectedSession'] = {}
      }
    }
    else
    if(fieldName==="selectedSession"){
      newState[fieldName] = value
      getAvailableQuota(value.id, newState)
    }

    newState[fieldName] = value   
    setstate(newState)
  }

  const isSessionAvailabel = ()=>{
    if(state.selectedAvailableDate && state.selectedAvailableDate.sessions && state.selectedAvailableDate.sessions.length > 0){
      return true
    }
    return false
  }

  
  
  const submit = ()=>{
    
    if(state.selectedSession && state.availableQuota>0){
      setSession(state.selectedSession)           
    }       
  }


  useEffect(() => {    
    getAvailableSchedules(new Date())
  }, [])

  useEffect(() => {    
    //console.log(state);
  }, [state])

  

  
      if(state && state.availableDates){
      return (
        <>
          <DialogContent >
            <Grid container justify="space-between" alignItems="flex-start" alignContent="flex-start" style={{minHeight:300}}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container item xs={12} sm={5} md={4} alignContent="flex-start" alignItems="flex-start">                              
              Pilih tanggal dengan tanda titik hijau
              <div style={{marginBottom:16}}>
              <DatePicker
                autoOk
                orientation="portrait"
                variant={mdDown ? undefined : 'static'}
                openTo="date"
                label="Jadwal Tersedia"
                value={state.date}
                onChange={(e)=>{handleChange('date',e)}}
                onMonthChange={(e)=>{handleChange('displayMonth',e)}}
                renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                  let notAvailable = true
                  if(isAfter(day, new Date())){
                    if(state.availableDates.map(date=>(date.date)).includes(format(day, 'yyyy-MM-dd'))){
                      notAvailable=false
                    }
                  }
                  
                  
                  
                  return <Badge 
                        color="primary" 
                        variant="dot" 
                        invisible={notAvailable}
                        classes={{ badge: classes.customBadge }}
                        >
                    {dayComponent}
                  </Badge>  ;
                }}
              />   
              </div>
                
              </Grid>

              <Grid container xs={12} md={6}>
                <Conditional condition={!isSessionAvailabel()}>
                  Jadwal pengambilan barang tidak tersedia, mohon pilih hari lain
                </Conditional>

                <Conditional condition={isSessionAvailabel()}>
                  Pilih Sesi : 
                </Conditional>
                
                
              {
                state.selectedAvailableDate && state.selectedAvailableDate.sessions && 
                state.selectedAvailableDate.sessions.map(session=>(
                  <Grid 
                  container
                  justify="center"
                  onClick={()=>handleChange('selectedSession',session)}                    
                  className={clsx({
                    [classes.session]:state.selectedSession.number!==session.number, 
                    [classes.selectedSession]:state.selectedSession.number===session.number})}
                  >
                    
                    <Grid container justify="center">
                      <Grid container justify="flex-start" item xs={6}>
                        SESI {session.number}
                      </Grid>
                      <Grid container justify="flex-end" item xs={6}>
                        {session.location.name}
                      </Grid>
                    </Grid>
                    <Grid container item xs={6}>
                      <Grid container justify="center"  alignItems="center">
                      <Typography>
                        Buka
                      </Typography>
                      </Grid>     
                      <Grid container justify="center"  alignItems="center">
                      <Typography>
                        {session.open.substring(11,16)}
                      </Typography>
                      </Grid>                       
                    </Grid>
                    <Grid container item xs={6}>
                      <Grid container justify="center"  alignItems="center">
                      <Typography>
                        Tutup
                      </Typography>
                      </Grid>     
                      <Grid container justify="center"  alignItems="center">
                      <Typography>
                        {session.close.substring(11,16)}
                      </Typography>
                      </Grid>
                      
                    </Grid>                  
                  </Grid>
                )) 
              }  

                <Grid container >                                    
                    <Conditional condition={state.availableQuota < 1}>
                      <Typography>
                        Quota Penuh, mohon pilih sesi atau tanggal yang lain
                      </Typography> 
                    </Conditional>                                                       
                </Grid> 
 
              </Grid>
              
                            
            
              </MuiPickersUtilsProvider>   
            </Grid>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Kembali
            </Button>
            
            <Button variant="contained" onClick={submit} disabled={state.availableQuota<1} color="primary">
              Simpan
            </Button>
          </DialogActions>
        </>    
        );
    }else{
      return null
    }
}

export default ReservationForm;

const useStyles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: "#0cdc09",
    color: "white"
  },
  timeLabelOpen:{
    backgroundColor:theme.palette.primary.main,
    color:'white'
  },
  timeLabelClosed:{
    backgroundColor:theme.palette.secondary.main,
    color:'white'
  },
  timeOpen:{
    color:theme.palette.primary.main,
    border:`1px solid ${theme.palette.primary.main}`
  },
  timeClosed:{
    color:theme.palette.secondary.main,
    border:`1px solid ${theme.palette.secondary.main}`
  },
  session:{
    border: '1px solid #e5e5e5',
    borderRadius:4,
    marginBottom:16,
    padding:8,
    color:'black',
    cursor:'pointer'
  },
  selectedSession:{
    border: '1px solid #e5e5e5',
    borderRadius:4,
    marginBottom:16,
    padding:8,
    background:theme.palette.primary.main,
    color:'white',
    cursor:'pointer'
  }
}));

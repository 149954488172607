import React from "react";

const Protected = (props) => {
  const { user, children, allowedCodes, ownerOnly, ownerId } = props
  let granted = false
  
  if(user){
  	if(ownerOnly){
	    if(user.id===ownerId){
	      granted = true
	    }
	}

	if (allowedCodes) {
		if (allowedCodes.includes(user.roles.code)) {
		    granted = true;
		}		
	}
  }

  if (granted === true) {
  	return <>{children}</>
  }
  else{
  	return null
  }
};

export default Protected;

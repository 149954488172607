import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doDownloadPdf} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {cartDummy} from 'utils/dummy'
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Remove from '@material-ui/icons/Remove';
import Search from '@material-ui/icons/Search';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import Payment from '@material-ui/icons/Payment';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import DebouncedTextField from 'components/DebouncedTextField.js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Pill from 'components/Pill';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { format, parse, isAfter } from 'date-fns'
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme'
import numeral from 'numeral'
import OrderDetail from './OrderDetail';
import PayInstruction from './PayInstruction';
import TablePagination   from '@material-ui/core/TablePagination';


const UserOrder =(props)=> {

  let { path, url } = useRouteMatch();
  let history = useHistory();
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const [orders, setorders] = useState([]);
  const [selectedOrder, setselectedOrder] = useState(null);
  const [statusOptions, setstatusOptions] = useState([
    {label:'paid', value:2},
    {label:'unpaid', value:1}
  ]);
  const [filters, setfilters] = useState({
    status:null
  });
  const [openDialog, setopenDialog] = React.useState(false);
  const [dialogWidth, setdialogWidth] = useState('sm');
  const [dialogContent, setdialogContent] = useState(null);
  const [dialogTitle, setdialogTitle] = useState('title');
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const changePage=(event, newPage)=>{
    setPage(newPage+1)
  }

  const changeRowsPerPage = (event)=> {
      setRowsPerPage(+event.target.value);
      setPage(1);
  }
  
  const back =()=>{
    history.goBack()
  }

  const getorders =async()=>{
    const params={
      status:filters.status?filters.status.value:null,
      page:page,
      limit:rowsPerPage
    }
        
    const response = await doGet(endpoint.order, params, getDefaultHeader());            
    
    if(response && response.data && response.data.data){
      const orders = response.data.data 
      setorders(orders.map(order=>({
        id       : order.id,  
        orderNumber : order.virtual_account,      
        price : order.total_pay,
        statusName : order.status_name,
        statusCode : order.status_code,
        location:order.pickup_location ? order.pickup_location.name : "",
        pickup:order.pickup,
        pickupDate : parse(order.pickup_date, 'yyyy-MM-dd', new Date()),
        paymentType : order.payment_type,
        expired : parse(order.expired_checkout, 'yyyy-MM-dd HH:mm:ss', new Date()),
        user : order.user,
        session:order.session
      })))
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    }
  }  

  const showDetail =(order)=>{
    setselectedOrder(order)    
  }

  const downloadInvoice =async(order)=>{
    const params={}
        
    await doDownloadPdf(endpoint.user_order_id_invoice.replace("id",order.id), params, getDefaultHeader());        
    
  }  
  

  const getStatus = (order)=>{
    const expired  = isAfter(new Date(), order.expired)
    if(expired && order.statusName==="unpaid" && order.paymentType==="2"){
      return "expired"
    }else{
      return order.statusName
    }
  }  

  const closeDialog =()=>{    
    setopenDialog(false)
    setselectedOrder(null)
  }

  const showPayInstruction =(order)=>{    
    setdialogTitle('Detail Order')
    setdialogContent(<PayInstruction status={getStatus(order)} user={user} order={order} handleClose={closeDialog}/>)
    setdialogWidth('sm')
    setopenDialog(true)
  }
  
  const filtersChange =(fieldName, event)=>{    
    let newFilters = {...filters}
    let value = event

    newFilters[fieldName] = value    
    setfilters(newFilters)
  }
  

  useEffect(() => {
    getorders()
  }, [page,rowsPerPage]) 

  
  useEffect(() => {    
    if(selectedOrder){
      setdialogTitle('Detail Order')
      setdialogContent(<OrderDetail getorders={getorders} user={user} selectedOrder={selectedOrder} handleClose={closeDialog}/>)
      setdialogWidth('md')
      setopenDialog(true)
    }
  }, [selectedOrder])
  
  

  if(user && user.token){
    return (
      <React.Fragment>
        <CssBaseline/>
        <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
            <Grid item xs={1}>              
                <IconButton color="primary" aria-label="go back" component="span" onClick={back}>
                  <ArrowBack />
                </IconButton>              
            </Grid>
            <Grid container item xs={11} justify="flex-end" alignItems="center" >                                                    
                
            </Grid>          
            
            <Paper elevation={1} style={{width:'100%', margin:'4px 0', padding:8}}>
                <Grid container spacing={1} item xs={12} md={6} alignItems="center" >                                                    
                    <Select                
                        name="status"
                        placeholder = "pilih status"
                        options={statusOptions}
                        onChange={(e)=>filtersChange("status",e)}
                        value={filters.status} 
                        styles={selectCustomZindex}
                        isClearable
                    />      
                    <IconButton onClick={getorders} size="small" color="primary" aria-label="go back" component="span" style={{margin:'0 8px'}}>
                      <Search />
                    </IconButton>   
                </Grid>
                                     
            </Paper>
            
            
                <Grid container justify="center" style={{margin:'4px 0'}}>                                           
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow className={classes.rowHead}>
                                    <TableCell align="center" className={classes.cellHead}>Order Number</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Price</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Status</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Location</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Pembayaran</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Invoice</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {orders.map((order) => (
                                <TableRow key={order.id}>
                                    <TableCell align="center" onClick={()=>showDetail(order)} className={classes.clickable}>{order.orderNumber}</TableCell>
                                    <TableCell align="center">Rp.{numeral(order.price).format('0,0')}</TableCell>
                                    <TableCell align="center">{getStatus(order)}</TableCell>
                                    <TableCell align="center">{order.session && order.session.location.name}</TableCell>
                                    <TableCell align="center">
                                      <IconButton
                                      onClick={()=>showPayInstruction(order)}
                                      size="small"
                                      aria-label="close"
                                      style={{margin:0}}>
                                        <Icon>payment</Icon>
                                      </IconButton>
                                    </TableCell>
                                    <TableCell align="center">
                                      <IconButton
                                      onClick={()=>downloadInvoice(order)}
                                      size="small"
                                      aria-label="close"
                                      style={{margin:0}}>
                                        <Icon>file_present</Icon>
                                      </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <Grid container className={classes.paginationContainer}>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={totalRows}
                                rowsPerPage={rowsPerPage}
                                page={page-1}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onChangePage={changePage}
                                onChangeRowsPerPage={changeRowsPerPage}
                            />
                          </Grid>                        
                    </TableContainer>
                </Grid>            
            
            
        </Grid>

        <Dialog
        fullWidth={true}
        maxWidth={dialogWidth}
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

const useStyles = makeStyles((theme) => ({
    marginTop16:{
      marginTop:16  
    },  
    marginH4:{
      marginLeft:4,
      marginRight:4,
    },  
    root: {
      padding: theme.spacing(1)
    },
    rowHead:{
      backgroundColor:'#d5ecff'
    },
    cellHead:{
      color:'#2532d4',
      fontWeight:'bold'
    },
    clickable:{
      color:theme.palette.primary.main,
      fontWeight:'bold',
      cursor:'pointer'
    },
    paginationContainer:{
      width:'max-content'
    }
    
    
  }));


export default UserOrder;

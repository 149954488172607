import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import numeral from 'numeral'
import Confirmation from 'components/Confirmation.js';
import Conditional from 'components/Conditional';
import Protected from 'components/Protected';
import ReturnForm from './ReturnForm';
import PaymentConfirmationForm from 'features/cart/PaymentConfirmationForm';
import PaymentInstructionForm from 'features/cart/PaymentInstructionForm';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import DeliveryForm from './DeliveryForm';
import { format, parse, isAfter } from 'date-fns'
import Edit from '@material-ui/icons/Edit';
import IconButton  from '@material-ui/core/IconButton';
import NoteForm from 'features/cart/NoteForm';


const OrderDetail =(props)=> {
  const {handleClose, user, selectedOrder, getorders} = props
  const classes = useStyles();
  const [openDialog, setopenDialog] = React.useState(false);
  const [dialogWidth, setdialogWidth] = useState('sm');
  const [dialogContent, setdialogContent] = useState(null);
  const [dialogTitle, setdialogTitle] = useState('title');
  const [state, setstate] = useState(null);
  const [tabIndex, settabIndex] = React.useState(0);
  

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue.target.value
    
    newState[fieldName] = value
    setstate(newState)
  }


  const tabChange = (event, index) => {    
    settabIndex(index);
  };

  const getOrderDetail =async(order)=>{    
    const params={}
        
    const response = await doGet(endpoint.order+"/"+order.id, params, getDefaultHeader());            

    if(!response.error){
      const details = response.data      
      setstate({
        ...order,
        products:details.products.map(o=>({
          ...o, 
          max:o.qty
        })) ,
        returned:details.returned,
        session:order.session ? {
          open:parse(order.session.open, 'yyyy-MM-dd HH:mm:ss', new Date()),
          close:parse(order.session.close, 'yyyy-MM-dd HH:mm:ss', new Date()),
          location:{...order.session.location}
        } : null
      })
      
    }
  }

  const closeDialog =()=>{    
    setopenDialog(false)
  }

  const receivePayment =async()=>{    
    
    const payload = {
      order_number:state.orderNumber
    }
    const response = await doPost(endpoint.order_receive, payload,"receive payment",getDefaultHeader())
    
    if(response && !response.error){      
      setstate({
        ...state,
        paymentDate:new Date()
      })
      getorders()
    }
    
  }

  const cancelOrder =async()=>{    
    const payload = {
      order_number:state.orderNumber
    }
    const response = await doPost(endpoint.order_cancel, payload,"cancel order",getDefaultHeader())
    
    if(response && !response.error){
      getorders()
      handleClose()
    }
  }

  const adminReturnOrder =async()=>{    
    const payload = {
      order_number:state.orderNumber
    }
    const response = await doPost(endpoint.admin_order_return, payload,"return order",getDefaultHeader())
    
    if(response && !response.error){
      getorders()
      handleClose()
    }
  }


  const deliverOrder =async(pickupBy)=>{ 
    
    
    if(pickupBy && pickupBy.trim()!==""){
      const payload = {
        order_number:state.orderNumber,
        name:pickupBy
      }
  
      const response = await doPost(endpoint.order_deliver, payload,"deliver order",getDefaultHeader())
      
      if(response && !response.error){
        getorders()
        handleClose()
      }
    }    
  }

  const cancelConfirmation =(e)=>{    
    e.stopPropagation();
    setopenDialog(true)
    setdialogContent(
    <Confirmation 
    action={cancelOrder} 
    actionName="Lanjutkan pembatalan" 
    handleClose={closeDialog} 
    message="Apakah pesanan dibatalkan"
    title="Pembatalan Pesanan"
    />
    )    
  }

  const receiveConfirmation =(e)=>{    
    e.stopPropagation();
    setopenDialog(true)
    setdialogContent(
    <Confirmation 
    action={receivePayment} 
    actionName="Sudah diterima" 
    handleClose={closeDialog} 
    message="Apakah pembayaran sudah diterima"
    title="Penerimaan Pembayaran"
    />
    )    
  }

  const deliverConfirmation =(e)=>{    
    e.stopPropagation();
    setopenDialog(true)
    setdialogContent(
      <DeliveryForm 
      deliverOrder={deliverOrder} 
      closeDialog={closeDialog}
      orderDetailChange = {handleChange}
      />
    )   
    
    
  }

  const adminReturnConfirmation =(e)=>{    
    e.stopPropagation();
    setopenDialog(true)
    setdialogContent(
      <Confirmation 
      action={adminReturnOrder} 
      actionName="Lanjutkan Pengembalian Barang" 
      handleClose={closeDialog} 
      message="Apakah barang yang dikembalikan sudah sesuai"
      title="Pengembalian Barang"
      />
    
    )    
  }


  const showReturnForm =(e)=>{    
    e.stopPropagation();
    setopenDialog(true)
    setdialogWidth('md')
    setdialogContent(
    <ReturnForm 
    user={user} 
    selectedOrder={state} 
    handleClose={closeDialog}
    setReturnedProduct={setReturnedProduct}
    />
    )    
  }

  const getStatus = (order)=>{
    const expired  = isAfter(new Date(), order.expired)
    if(expired && order.statusName==="unpaid" && order.paymentType==="2"){
      return "expired"
    }else{
      return order.statusName
    }
  }

  const setReturnedProduct = (returned)=>{    
    setstate({
      ...state,
      returned:returned
    })
  }

  const checkOut = async(channel)=>{  
    
    const payload = {
      payment_type:channel
    }

    const response = await doPost(endpoint.order, payload,"checkout",getDefaultHeader())
    
    if(response && !response.error){
      
      showPaymentInstruction({
        ...response.data, 
        channel:channel
      })         
    } 
     
  }

  const showPaymentInstruction =(instruction)=>{
    setdialogTitle('Instruksi cara pembayaran')
    setdialogContent(
    <PaymentInstructionForm 
    handleClose={closeDialog} 
    instruction={instruction}/>
    )
    setdialogWidth('sm')
    setopenDialog(true)        
  }

  const showPaymentConfirmation =()=>{
    setdialogTitle('Pilih Cara Pembayaran')
    
    setdialogContent(
    <PaymentConfirmationForm 
    handleClose={closeDialog}     
    checkOut={checkOut}/>
    )

    setdialogWidth('sm')
    setopenDialog(true)        
  }


  const alreadyReturned=(returned)=>{
    return returned.filter(product=>product.received_at!==null).length>0
  }

  useEffect(() => {
    if(selectedOrder){
      getOrderDetail(selectedOrder)
    }
    
  }, [selectedOrder])

  useEffect(() => {
   if(state){
    //console.log(state);
   }        
  }, [state])


  if(state){
    return (
      <>
      <DialogContent>
      <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
        <Grid container justify="flex-end" spacing={1}>  
        <Protected user={user} allowedCodes="std">
          <Conditional condition={state.statusCode==="9" && state.returned.length===0}>
              <Grid item xs={6} md={2}>                        
                <Button onClick={showReturnForm} variant="contained" style={{color:'white',backgroundColor:'red'}}>
                  Pengembalian
                </Button>              
              </Grid>                         
          </Conditional>
          <Conditional condition={getStatus(state)==="expired"}>
              <Grid item xs={6} md={2}>                        
                <Button onClick={showPaymentConfirmation} variant="contained" style={{color:'white',backgroundColor:'green'}}>
                  Bayar
                </Button>              
              </Grid>                         
          </Conditional>
        </Protected>

        <Protected user={user} allowedCodes="emp">        
          <Conditional condition={state.statusCode==="1"}>
            <Grid item xs={6} md={2}>                     
              <Button onClick={cancelConfirmation} color="secondary" variant="contained">
                Batalkan Pesanan
              </Button>
            </Grid>                                           
          </Conditional>

          <Conditional condition={state.statusCode==="8"}>
            <Conditional condition={state.paymentType==="1" && state.paymentDate===null}>
              <Grid item xs={6} md={2}>                                        
                <Button onClick={receiveConfirmation} color="primary" variant="contained">
                  Terima Pembayaran
                </Button>                
              </Grid>          
            </Conditional>

            <Conditional condition={state.paymentDate!==null}>
              <Grid item xs={6} md={2}>                        
                <Button onClick={deliverConfirmation} variant="contained" style={{color:'white',backgroundColor:'green'}}>
                  Serah Terima
                </Button>              
              </Grid>                         
            </Conditional>
            
          </Conditional>

          <Conditional condition={state.statusCode==="9" && state.returned.length>0 && !alreadyReturned(state.returned)}>
            <Grid item xs={6} md={2}>                        
              <Button onClick={adminReturnConfirmation} color="secondary" variant="contained">
                Pengembalian Barang
              </Button>
            </Grid>                         
          </Conditional>

        </Protected>
        </Grid>
        
        <Grid container className={classes.marginTop16}>  
          <Grid item xs={4} md={2}>            
            Order Number
          </Grid>
          <Grid item xs={8}  md={10}>   
            {state.orderNumber}          
          </Grid>          
        </Grid>

        <Grid container>  
          <Grid item xs={4} md={2}>            
            Pelanggan
          </Grid>
          <Grid item xs={8}  md={10}>   
            {state.user && state.user.name_student}          
          </Grid>          
        </Grid>

        <Grid container>  
          <Grid item xs={4} md={2}>            
            Total Price
          </Grid>
          <Grid item xs={8}  md={10}>   
          Rp.{numeral(state.price).format('0,0')}
          </Grid>          
        </Grid> 

        <Grid container>  
          <Grid item xs={4} md={2}>            
            Status
          </Grid>
          <Grid item xs={8}  md={10}>   
            {getStatus(state)}          
          </Grid>          
        </Grid> 

        <Grid container>  
          <Grid item xs={4} md={2}>            
            Pickup Date
          </Grid>
          <Grid item xs={8}  md={10}>   
            {format(state.pickupDate, 'dd MMMM yyyy')}          
            { state.session &&
            `
               (${format(state.session.open, 'HH:mm')} - ${format(state.session.close, 'HH:mm')})
            `
            }
          </Grid>          
        </Grid>
   
        <Grid container>  
          <Grid item xs={4} md={2}>            
            Pickup Location
          </Grid>
          <Grid item xs={8}  md={10}>   
            {state.session && state.session.location.name}          
          </Grid>          
        </Grid> 

        <Grid container>  
          <Grid item xs={4} md={2}>            
            Pickup By
          </Grid>
          <Grid item xs={8}  md={10}>   
            {state.pickupBy && state.pickupBy.name}          
          </Grid>          
        </Grid>
        
        <Grid container>  
          Products          
        </Grid> 

        <Paper square>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={tabChange}
            aria-label="product tab"
          >
            <Tab label="Dibeli" />
            <Tab label="Dikembalikan" />
          </Tabs>
        </Paper>

        <Conditional condition={tabIndex===0}>
          <Grid container>  
            { state.products &&
                    state.products.map(order=>(
                      
                      <Paper elevation={3} style={{width:'100%', margin:'8px 0', padding:'8px 0 8px 0'}}>
                        <Grid container justify="space-around" item xs={12}>                                               
                          <Grid container justify="center" alignItems="center" item style={{width:100}}>
                              { order.product &&
                                <img src={order.product.cover} width="50" height="50"/>
                              }
                          </Grid>
                          <Grid container  item xs={6} alignItems="flex-start" >
                            <Grid container item xs={12} md={7} alignItems="center">
                              <Typography variant="body2">
                                {
                                  order.product && order.product.name
                                }
                              </Typography>                          
                            </Grid>
                            <Grid container item xs={12} md={4} alignItems="flex-start">
                              <Typography variant="body2">
                                {
                                  order.size && order.size.size_label
                                }
                              </Typography>                          
                            </Grid>
                            <Grid container item xs={12} md={1} alignItems="flex-start">
                              <Typography variant="body2">
                                {
                                  order.qty
                                }pcs
                              </Typography>                                        
                            </Grid>

                            
                            <Grid Grid container item xs={12} alignItems="flex-start">
                              <Conditional condition={order.product.isNoteRequired!==1}>
                                <Typography variant="body2">                                  
                                  {
                                    order.note
                                  }
                                </Typography> 
                              </Conditional>        
                            </Grid>
                          </Grid>                          
                                      
                          
                        </Grid>
                        </Paper>  
                                      
                    ))
                  }
          </Grid>     
        </Conditional> 

        <Conditional condition={tabIndex===1}>
        <Grid container>  
            { state.returned &&
                    state.returned.map(order=>(
                      
                      <Paper elevation={3} style={{width:'100%', margin:'8px 0', padding:'8px 0 8px 0'}}>
                        <Grid container justify="space-around" item xs={12}>                                               
                          <Grid container justify="center" alignItems="center" item style={{width:100}}>
                              { order.product &&
                                <img src={order.product.cover} width="50" height="50"/>
                              }
                          </Grid>
                          <Grid container  item xs={6} alignItems="flex-start" >
                            <Grid container item xs={12} md={6} alignItems="center">
                              <Typography variant="body2">
                                {
                                  order.product && order.product.name
                                }
                              </Typography>                          
                            </Grid>
                            <Grid container item xs={12} md={5} alignItems="flex-start">
                              <Typography variant="body2">
                                {
                                  order.size && order.size.size_label
                                }
                              </Typography>                          
                            </Grid>
                            <Grid containeritem xs={12} md={1} alignItems="flex-start">
                              <Typography variant="body2">
                                {
                                  order.qty
                                }pcs
                              </Typography>                                        
                            </Grid>
                            <Grid container item xs={12} md={12} alignItems="flex-start">
                              <Typography variant="body2">
                                dikembalikan pada : 
                                {" "}{order.received_at}
                              </Typography>                                        
                            </Grid>
                            <Grid alignItems="center">
                                                      
                            </Grid>
                          </Grid>                          
                                      
                          
                        </Grid>
                        </Paper>   
                                      
                    ))
                  }
          </Grid> 
        </Conditional>      
      </Grid>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleClose} color="primary" variant="contained">
        Back
      </Button>      
    </DialogActions>

    <Dialog
    fullWidth={true}
    maxWidth={dialogWidth}
    open={openDialog}
    onClose={closeDialog}
    aria-labelledby="max-width-dialog-title"
    >        
      {dialogContent}
    </Dialog>
    </>
    );
  }
  else{
    return null
  }

}

const useStyles = makeStyles((theme) => ({
    marginTop16:{
      marginTop:16  
    },      
    
    
  }));


export default OrderDetail;

import React,{useState, useEffect} from 'react';
import {doGet, doPatch, doDelete, doDownloadPdf} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Search from '@material-ui/icons/Search';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { format, parse, isAfter } from 'date-fns'
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme'
import numeral from 'numeral'
import OrderDetail from './OrderDetail';
import TablePagination   from '@material-ui/core/TablePagination';
import { generalListOptionMapper } from 'utils/mappers.js';


const AdminOrder =(props)=> {

  let { path, url } = useRouteMatch();
  let history = useHistory();
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const [orders, setorders] = useState([]);
  const [selectedOrder, setselectedOrder] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  
  const [statusOptions, setstatusOptions] = useState([
    {label:'Packed', value:8},
    {label:'Printed', value:7},
    {label:'Returned', value:6},
    {label:'Delivered', value:9},
    {label:'Cancel', value:3},
    {label:'Paid', value:2},
    {label:'Unpaid', value:1}
  ]);

  const [paymentTypeOptions, setpaymentTypeOptions] = useState([
    {label:'Manual', value:1},
    {label:'Transfer', value:2} 
  ]);

  const [sortOptions, setsortOptions] = useState([
    {label:'Harga Tertiggi', value:"total_pay-DESC"},
    {label:'Harga Terendah', value:"total_pay-ASC"},
    {label:'Pengambilan Terbaru', value:"pickup_date-DESC"},
    {label:'Pengambilan Terlama', value:"pickup_date-ASC"}
  ]);

  const [locationOptions, setlocationOptions] = useState([])

  const [filters, setfilters] = useState({
    status:null,
    keyword:""
  });
  const [openDialog, setopenDialog] = React.useState(false);
  const [dialogWidth, setdialogWidth] = useState('sm');
  const [dialogContent, setdialogContent] = useState(null);
  const [dialogTitle, setdialogTitle] = useState('title');
 
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const changePage=(event, newPage)=>{
    setPage(newPage+1)
  }

  const changeRowsPerPage = (event)=> {
      setRowsPerPage(+event.target.value);
      setPage(1);
  }

  
  const back =()=>{
    history.goBack()
  }

  const getlocationOptions =async()=>{
    const params={}
        
    const response = await doGet(endpoint.location, params, getDefaultHeader());        
    if(!response.error){
      const locations = response.data      
      setlocationOptions(generalListOptionMapper(locations))
    }
  }


  const getorders =async()=>{
    const params={
      status:filters.status?filters.status.value:null,
      location:filters.location?filters.location.value:null,
      payment_type:filters.paymentType?filters.paymentType.value:null,
      keyword : filters.keyword.trim()!==""?filters.keyword : null,
      sort:filters.sort?filters.sort.value:null,
      page:page,
      limit:rowsPerPage
    }
        
    const response = await doGet(endpoint.order_admin, params, getDefaultHeader());        
    if(response && response.data && response.data.data){
      const orders = response.data.data      
      setorders(orders.map(order=>({
        id       : order.id,  
        orderNumber : order.virtual_account,      
        price : order.total_pay,
        statusName : order.status_name,
        statusCode : order.status_code,
        location:order.pickup_location ? order.pickup_location.name : "",
        paymentType : order.payment_type,
        paymentDate: order.payment_date? parse(order.payment_date, 'yyyy-MM-dd HH:mm:ss', new Date()) : null,
        expired : parse(order.expired_checkout, 'yyyy-MM-dd HH:mm:ss', new Date()),
        session:order.session,
        pickupDate:parse(order.pickup_date, 'yyyy-MM-dd', new Date()), 
        user : order.user,
        pickupBy:order.pickup_by,
        printBy:order.printed_by
      })))
      setPage(response.data.current_page)
      setTotalRows(response.data.total)
    }    
    
  }  

  const showDetail =(order)=>{
    setselectedOrder(order)    
  }

    

  const getStatus = (order)=>{
    const expired  = isAfter(new Date(), order.expired)
    if(expired && order.statusName==="unpaid" && order.paymentType==="2"){
      return "expired"
    }else{
      return order.statusName
    }
  }  

  const getPaymentType = (order)=>{
    if(order.paymentType==="1"){
      return "manual"
    }else{
      return "transfer"
    }
  }

  const closeDialog =()=>{    
    setopenDialog(false)
    setselectedOrder(null)
  }  
  
  const filtersChange =(fieldName, event)=>{    
    let newFilters = {...filters}
    let value = event

    newFilters[fieldName] = value    
    setfilters(newFilters)
  }

  const packing =async(order)=>{    
    const payload = {
      id:order.id
    }
    const response = await doPatch(endpoint.admin_order_pack, payload,"pack order",getDefaultHeader())
    
    if(response && !response.error){
      getorders()
    }
  }

  const toPaid =async(order)=>{    
    const payload = {
      id:order.id
    }
    const response = await doPatch(endpoint.order_to_paid, payload, "paid order", getDefaultHeader())
    
    if(response && !response.error){
      getorders()
    }
  }

  const printInvoice=async(order)=>{
    const params={}        
    await doDownloadPdf(endpoint.admin_order_id_invoice.replace("id",order.id), params, getDefaultHeader());            
    getorders()
  }


  const Action =({order, printInvoice, packing})=>{    
    if(order){
      if(
        (!order.printBy) && 
        (order.statusCode==="2" || (order.statusCode==="1" && order.paymentType==="1"))
        ){
        return(
          <Button onClick={()=>printInvoice(order)} color="primary">
            Print
          </Button>
        ) 
      }
      else 
      if(order.statusCode==="7"){
        return(
          <>
            <Button onClick={()=>packing(order)} color="primary">
              Pack
            </Button>

            <Button variant="contained" size="small"   onClick={()=>toPaid(order)} color="primary" style={{margin:'0 8px'}}>
                  to paid
                </Button>
            
           </>
        ) 
      }
      else
      {
        return null
      }      
    }else{
      return null
    }
  }  
  
   
  useEffect(() => {
    getlocationOptions()
  }, []) 

  useEffect(() => {
    getorders()
  }, [page,rowsPerPage])  

  
  useEffect(() => {    
    if(selectedOrder){
      setdialogTitle('Detail Order')
      setdialogContent(<OrderDetail getorders={getorders} user={user} selectedOrder={selectedOrder} handleClose={closeDialog}/>)
      setdialogWidth('md')
      setopenDialog(true)
    }
  }, [selectedOrder])
  
  useEffect(() => {
    getorders()
  }, [filters.keyword])
  

  if(user && user.token){
    return (
      <React.Fragment>
        <CssBaseline/>
        <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
            <Grid item xs={1}>              
                <IconButton color="primary" aria-label="go back" component="span" onClick={back}>
                  <ArrowBack />
                </IconButton>              
            </Grid>
            <Grid container item xs={11} justify="flex-end" alignItems="center" >                                                    
                
            </Grid>          
            
            <Paper elevation={1} style={{width:'100%', margin:'4px 0', padding:8}}>
                <Grid container spacing={1} alignItems="center" >                                                    
                    <Grid item xs={6} md={3} >
                      <DebouncedTextField
                          margin="dense"
                          id="keyword"
                          placeholder="order number"                                                                                   
                          fullWidth
                          variant="outlined"
                          value={filters.keyword}
                          onChange={(e)=>{filtersChange('keyword',e)}}
                      />
                    </Grid>

                    <Grid item xs={6} md={3} style={{paddingTop:8}}>
                      <Select                
                          name="paymentType"
                          placeholder = "cara pembayaran"
                          options={paymentTypeOptions}
                          onChange={(e)=>filtersChange("paymentType",e)}
                          value={filters.paymentType} 
                          styles={selectCustomZindex}
                          isClearable
                      />
                    </Grid>

                    <Grid item xs={6} md={3} style={{paddingTop:8}}>
                      <Select                
                          name="location"
                          placeholder = "lokasi pengambilan"
                          options={locationOptions}
                          onChange={(e)=>filtersChange("location",e)}
                          value={filters.location} 
                          styles={selectCustomZindex}
                          isClearable
                      />
                    </Grid>

                    <Grid item xs={6} md={3} style={{paddingTop:8}}>
                      <Select                
                          name="status"
                          placeholder = "status"
                          options={statusOptions}
                          onChange={(e)=>filtersChange("status",e)}
                          value={filters.status} 
                          styles={selectCustomZindex}
                          isClearable
                      />
                    </Grid>

                    <Grid item xs={6} md={3} style={{paddingTop:8}}>
                      <Select                
                          name="sort"
                          placeholder = "Urutkan"
                          options={sortOptions}
                          onChange={(e)=>filtersChange("sort",e)}
                          value={filters.sort} 
                          styles={selectCustomZindex}
                          isClearable
                      />
                    </Grid>

                    

                    
                    <Grid item xs={3} >
                      <IconButton onClick={getorders} size="small" color="primary" aria-label="go back" component="span" style={{margin:'0 8px'}}>
                        <Search />
                      </IconButton>   
                    </Grid>      
                    
                </Grid>
                                     
            </Paper>
            
            
                <Grid container justify="center" style={{margin:'4px 0'}}>                                           
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow className={classes.rowHead}>
                                    <TableCell align="center" className={classes.cellHead}>Order Number</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Pelanggan</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Price</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Status</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Location</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Pengambilan</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Pembayaran</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Action</TableCell>
                                    <TableCell align="center" className={classes.cellHead}>Generate by</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {orders.map((order) => (
                                <TableRow key={order.id}>
                                    <TableCell align="center" onClick={()=>showDetail(order)} className={classes.clickable}>{order.orderNumber}</TableCell>
                                    <TableCell align="center">{order.user.name_student}</TableCell>
                                    <TableCell align="center">Rp.{numeral(order.price).format('0,0')}</TableCell>
                                    <TableCell align="center">{getStatus(order)}</TableCell>
                                    <TableCell align="center">{order.session && order.session.location.name} </TableCell>
                                    <TableCell align="center">{
                                    format(order.pickupDate, 'dd MMMM yyyy')
                                    }</TableCell>
                                    <TableCell align="center">{getPaymentType(order)}</TableCell>
                                    <TableCell align="center">                                      
                                        <Action order={order} printInvoice={printInvoice} packing={packing}/>                                      
                                    </TableCell>
                                    <TableCell align="center">                                      
                                        {order.printBy && order.printBy.name}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <Grid container justify="flex-end" className={classes.paginationContainer}>
                          <TablePagination
                              rowsPerPageOptions={[10, 25, 50]}
                              component="div"
                              count={totalRows}
                              rowsPerPage={rowsPerPage}
                              page={page-1}
                              backIconButtonProps={{
                                  'aria-label': 'previous page',
                              }}
                              nextIconButtonProps={{
                                  'aria-label': 'next page',
                              }}
                              onChangePage={changePage}
                              onChangeRowsPerPage={changeRowsPerPage}
                          />
                        </Grid>
                    </TableContainer>
                </Grid>            
            
            
        </Grid>

        <Dialog
        fullWidth={true}
        maxWidth={dialogWidth}
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

const useStyles = makeStyles((theme) => ({
    marginTop16:{
      marginTop:16  
    },  
    marginH4:{
      marginLeft:4,
      marginRight:4,
    },  
    root: {
      padding: theme.spacing(1)
    },
    rowHead:{
      backgroundColor:'#d5ecff'
    },
    cellHead:{
      color:'#2532d4',
      fontWeight:'bold'
    },
    clickable:{
      color:theme.palette.primary.main,
      fontWeight:'bold',
      cursor:'pointer'
    }
    
    
  }));


export default AdminOrder;

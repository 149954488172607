import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme'
import {locationDummy} from 'utils/dummy'
import { generalListOptionMapper, generalOptionMapper } from 'utils/mappers.js';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,MuiPickersUtilsProvider,TimePicker
} from '@material-ui/pickers';
import { format } from 'date-fns'
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';




const PickupScheduleForm =(props)=> {
  const { handleClose, actionType, schedule, user, locations, getschedules, setselectedSchedule} = props
  const classes = useStyles();
  const [state, setstate] = useState(
    {      
      date:new Date() ,
      sessions:[],
      deleteds:[] 
    }
  )
  const [locationOptions, setlocationOptions] = useState([])
  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue

    newState[fieldName] = value
    setstate(newState)
  }

  const sessionChange=(fieldName,session,eventValue)=>{
    let value = eventValue

    const oldSession = state.sessions.filter(item=>(session.number===item.number))[0]
    const newSession = {...oldSession, [fieldName]:value}

    let others = state.sessions.filter(item=>(session.number!==item.number))
    const sorted = [...others, newSession].sort((a, b) => (a.number > b.number) ? 1 : -1)

    console.log(sorted)
    setstate({
      ...state,
      sessions:sorted
    })
    
  }

  const add=async()=>{
    const payload = {
      ...state,
      date:format(state.date, 'yyyy-MM-dd'),
      sessions:state.sessions.map(session=>({
        number:session.number,
        open:format(session.open, 'yyyy-MM-dd HH:mm:ss'),
        close:format(session.close, 'yyyy-MM-dd HH:mm:ss'),
        quota:parseInt(session.quota),
        location:session.location.id
      }))      
    }

    const response = await doPost(endpoint.reservation, payload,"reservation",getDefaultHeader())
    
    if(response && response.error){
      
    }else
    {      
      handleClose()
      getschedules()
    }
  }

  const edit=async()=>{
    const payload = {
      ...state,
      date:format(state.date, 'yyyy-MM-dd'),
      sessions:state.sessions.map(session=>({
        id: session.id,
        number:session.number,
        open:format(session.open, 'yyyy-MM-dd HH:mm:ss'),
        close:format(session.close, 'yyyy-MM-dd HH:mm:ss'),
        quota:parseInt(session.quota),
        location:session.location.id
      })),
      deleteds:state.deleteds.filter(session=>(session.id)).map(session=>(session.id)) 
    }

    const response = await doPut(endpoint.reservation, payload,"reservation",getDefaultHeader())
    
    if(response && response.error){
      
    }else
    {
      setselectedSchedule(null)
      handleClose()
      getschedules()
    }
    
  }
  

  const addSession = ()=>{
    let newSessions = [
      ...state.sessions,
      {        
        number : state.sessions.length+1,
        open:state.date,
        close:state.date,
        quota:0,
        location:null
      }
    ]

    setstate({
      ...state,
      sessions:sortNumbers(newSessions)
    })
  }

  const deleteSession = (session)=>{    
    setstate({
      ...state,
      sessions:sortNumbers(state.sessions.filter(item=>item.number!==session.number)),
      deleteds:[...state.deleteds, session]
    })    
  }

  const submit = ()=>{
    
    if(actionType==="add"){
        add()
    }else if(actionType==="edit"){
        edit()
    }
  }

  const sortNumbers = (sessions)=>{
    if(sessions.length>0){
      let num=1      
      //setstate({...state, sessions:sortedNumbers})
      return sessions.map(session=>({
        ...session,
        number:num++
      }))
    }else{
      return sessions
    }
  }

  useEffect(() => {
    if(locations){
      setlocationOptions(generalListOptionMapper(locations))
    }
  }, [locations])
    
  useEffect(() => {
    if(schedule){
        setstate({
            id:schedule.id,            
            date:schedule.date,            
            sessions:schedule.sessions.map(session=>({
              id:session.id,
              number:session.number,
              open:session.open,
              close:session.close,
              quota:session.quota,
              location:generalOptionMapper(session.location)       
            })),
            deleteds:[]
        })
    }
  }, [schedule])

  if(state){
    return (
      <>
        <DialogContent style={{height:400}}>
          <Grid container justify="space-between" wrap="nowrap">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker                  
                  value={state.date}
                  onChange={(e)=>handleChange("date",e)}
                  label="Tanggal"
                  variant="inline"
                />
              </MuiPickersUtilsProvider>
              <Button variant="contained" onClick={addSession} color="primary">
                Tambah sesi
              </Button>
          </Grid>         
          {
            state.sessions.map(session=>(
              <Session locationOptions={locationOptions}  deleteSession={deleteSession} session={session} sessionChange={sessionChange}/>
            ))
          }                  
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Kembali
          </Button>
          <Button variant="contained" onClick={submit} color="primary">
            Simpan
          </Button>
        </DialogActions>
  </>
  
      );
      
  }else{
    return null
  }
  
}


const Session = (props)=>{
  const {session, sessionChange, locationOptions, deleteSession} = props

  return(
      <Grid container spacing={1} style={{marginTop:8}}>
        <Grid item xs={12} md={5} container justify="flex-start" alignContent="center" >
          <Grid item xs={1} container justify="center" alignContent="flex-end">
          <Typography variant="h5">{session.number}</Typography>          
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={11} md={11}>
              <TimePicker
                autoOk={true}
                clearable
                ampm={false}
                label="Jam Buka"
                value={session.open}
                variant="inline"
                onChange={(e)=>sessionChange("open",session,e)}
                
                style={{width:100}}
              />

              <TimePicker
                clearable
                autoOk={true}
                ampm={false}
                label="Jam Tutup"
                value={session.close}
                variant="inline"
                onChange={(e)=>sessionChange("close",session,e)}
                style={{width:100}}
              />
            </Grid>            
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={6} md={2} container justify="center" alignContent="center" >
            <FormControl component="fieldset">
              <FormLabel component="legend">Quota</FormLabel>
              <DebouncedTextField
                  margin="dense"
                  id="quota"
                  placeholder="quota"                                                                                   
                  fullWidth
                  variant="outlined"
                  value={""+session.quota}
                  type="number"
                  onChange={(e)=>{sessionChange('quota',session,e)}}
              />
            </FormControl>
            
        </Grid> 
        
        <Grid item xs={6} md={2} container justify="center" alignContent="flex-end" style={{paddingBottom:8}}>
          <Select              
            name="location"
            placeholder = "Lokasi"
            options={locationOptions}
            onChange={(e)=>sessionChange('location',session,e)}
            styles={selectCustomZindex}
            value={session.location}            
            isSearchable={false}
          />
        </Grid> 
        <Grid item xs={1} container justify="center" alignContent="flex-end">
          <IconButton
          onClick={()=>deleteSession(session)}
          size="medium"
          aria-label="add"
          style={{margin:0}}>
            <Delete fontSize="inherit" />
          </IconButton>
        </Grid>
        
      </Grid>  
  )
}


export default PickupScheduleForm;

const useStyles = makeStyles((theme) => ({

}));

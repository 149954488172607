import React,{ useState, useEffect }  from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams,useHistory } from "react-router-dom";
import {doGet} from "utils/apiUtil.js"
import {endpoint} from 'utils/constants.js'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useStyles } from './styles.js';



export default function Activation() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const loading = useSelector(state => state.ui.loading);
  const [activate, setactivate] = useState(false);
  let {id} = useParams();
  
  const getDefaultHeader =()=>{
    return { 'Access-Control-Allow-Origin': true}
  }

  const activating = async()=>{
         
      const payload = {
        id:id
      }
      const response = await doGet(endpoint.activation, payload,getDefaultHeader())
      
      if(response && response.error){
        setactivate(false)
      }
      else
      if(response && response.data){
        setactivate(true)
      }
               
      
  }

  const goLogin =()=>{
    history.push("/login")
  }

  const goRegister =()=>{
    history.push("/register")
  }


  useEffect(() => {    
    
    if(typeof id === 'string'){
      activating()
    }
  }, [id])

  if(loading){
    return(
      <Container component="main" maxWidth="md">
          <CssBaseline />
          <Grid container justify="center">
            <Typography variant="h1"> activating... </Typography>
          </Grid>
          
      </Container>
    )
  }else{
    if(activate){
      return (
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Grid container justify="center" alignItems="center" style={{height:'100vh'}}>
          <Typography variant="h3"> Congratulation </Typography>
            <img src='/welcome.svg' width="300" height="300"/>
            <Grid container justify="center" item xs={12} md={6}>
            <Typography variant="h6"> your account is active </Typography>
              <Typography variant="h5"> you can login now</Typography>              
            </Grid>
            
            <Grid container justify="center" item xs={12} md={4}>
            <Button
              type="button"            
              fullWidth
              variant="contained"
              color="primary"        
              onClick={goLogin}
            >
              Go to Login Page
            </Button>
            </Grid>
            
          </Grid>
          
        </Container>
      )
    }else{
      return (
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <Grid container justify="center" alignItems="center">
          <Grid container justify="center" alignItems="center">
          <Typography variant="h3"> Activation failed </Typography>
            <img src='/error.png' width="300" height="300" onClick={activating}/>
            <Grid container justify="center" item xs={12} md={6}>
            <Typography variant="h6"> your activation link is invalid </Typography>
              <Typography variant="h5"> please use the activation link from your email  </Typography>            
            </Grid>                      
          </Grid>
          <Grid container justify="center" item xs={6}>
            or
            <Button
              type="button"            
              fullWidth
              variant="contained"
              color="primary"        
              onClick={goRegister}
            >
              Go to Registration Page
            </Button>
          </Grid>
          </Grid>
          
          
        </Container>
      )
    }
  }
  
  
  
  
  }


import React,{ useState }  from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect,useHistory } from "react-router-dom";
import { setUserLogin } from "reduxs/actions";
import {doPost} from "utils/apiUtil.js"
import {endpoint} from 'utils/constants.js'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Assignment from '@material-ui/icons/Assignment';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useStyles } from './styles.js';
import Conditional from 'components/Conditional';
import { LiveTvRounded } from '@material-ui/icons';




export default function Register() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [submitted, setsubmitted] = useState(false);
  const [state, setstate] = useState({
    newStudent:false,
    niy:"",
    formulir:"",
    email:"",
    name_parent:"",
    telephone:"",
    password:"",
    confirmPassword:""    
  });

  const [errors, seterrors] = useState({
    niy:null,
    formulir:null,
    email:null,
    name_parent:null,
    telephone:null,
    password:null,
    confirmPassword:null    
  });

  const getDefaultHeader =()=>{
    return {}
  }

  const submit = async()=>{
    let valid = true
    let newErrors = {
      niy:null,
      formulir:null,
      email:null,
      name_parent:null,
      telephone:null,
      password:null,
      confirmPassword:null
    }

    if(!state.newStudent && state.niy.trim()  === ""){
      valid = false      
      newErrors['niy'] = "niy can not be empty"
    }

    if(state.newStudent && state.formulir.trim()  === ""){
      valid = false      
      newErrors['formulir'] = "formulir can not be empty"
    }

    if(state.email.trim()  === ""){
      valid = false
      newErrors['email'] = "email can not be empty"
    }
    if(state.telephone.trim()  === ""){
      valid = false
      newErrors['telephone'] = "phone can not be empty"
    }
    if(state.name_parent.trim()  === ""){
      valid = false
      newErrors['name_parent'] = "parent name can not be empty"
    }
    if(state.password.trim()  === ""){
      valid = false
      newErrors['password'] = "password can not be empty"
    }
    if(state.confirmPassword.trim()  === ""){
      valid = false
      newErrors['confirmPassword'] = "confirmed Password can not be empty"
    }
    if(state.confirmPassword.trim()  !== state.password.trim()){
      valid = false
      newErrors['confirmPassword'] = "confirmed Password and password can not be different"
    }
    
    if(valid){           
      let payload = {
        telephone :state.telephone,
        email:state.email,
        name_parent:state.name_parent,
        password:state.password
      }

      if(state.newStudent){
        payload = {...payload,formulir:state.formulir }
      }else{
        payload = {...payload,niy:state.niy }
      }

      const response = await doPost(endpoint.register, payload,"register",getDefaultHeader())
      
      if(response.error){
        for (let key in response.error.errors) {
          newErrors[key] = response.error.errors[key]
        }
      }else{
        setsubmitted(true)            
      }      
    }

    seterrors(newErrors)
      
  }

  const goLogin =(path)=>{
    history.push("/login")
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue.target.value
    
    if(fieldName==='newStudent'){
      value = eventValue.target.checked
    }

    newState[fieldName] = value
    setstate(newState)
  }

  
  if(submitted){
    return (
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Grid container justify="center" alignItems="center" style={{height:'100vh'}}>
        <Typography variant="h3"> Congratulation </Typography>
          <img src='/welcome.svg' width="300" height="300"/>
          <Grid container justify="center" item xs={12} md={6}>
          <Typography variant="h6"> please check your email for activation </Typography>
            <Typography variant="h5"> you may login after your account is active </Typography>            
          </Grid>
          
          <Grid container justify="center" item xs={12} md={4}>
          <Button
            type="button"            
            fullWidth
            variant="contained"
            color="primary"        
            onClick={goLogin}
          >
            Go to Login Page
          </Button>
          </Grid>
          
        </Grid>
        
      </Container>
    )
  }else{
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Assignment />
          </Avatar>
          <Typography component="h1" variant="h5">
            Registration
          </Typography>
          
          <form className={classes.form} noValidate>
            <FormControlLabel
            control={
              <Checkbox
                checked={state.newStudent===true}
                onChange={(e)=>handleChange("newStudent",e)}
                name="newStudent"
                color="primary"
              />
            }
            label="new student"
            />

            <Conditional condition={state.newStudent===true}>
              <TextField
                error={errors.formulir}
                helperText={errors.formulir}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="formulir"
                label="Nomor Formulir"
                name="formulir"
                value={state.formulir}
                onChange={(e)=>handleChange("formulir",e)}
              />
            </Conditional>
            <Conditional condition={state.newStudent===false}>
              <TextField
                error={errors.niy}
                helperText={errors.niy}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="NIY"
                label="NIY / NIS"
                name="NIY"
                value={state.niy}
                onChange={(e)=>handleChange("niy",e)}
              />
            </Conditional>
            
            
            <TextField
              error={errors.name_parent}
              helperText={errors.name_parent}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name_parent"
              label="parent name"
              name="name_parent"
              value={state.name_parent}
              onChange={(e)=>handleChange("name_parent",e)}
            />
            <TextField
              error={errors.email}
              helperText={errors.email}              
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="email"
              name="email"
              value={state.email}
              onChange={(e)=>handleChange("email",e)}
            />
            <TextField
              error={errors.telephone}
              helperText={errors.telephone}              
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="telephone"
              label="Phone"
              name="telephone"
              value={state.telephone}
              onChange={(e)=>handleChange("telephone",e)}
            />
            
            <TextField
              error={errors.password}
              helperText={errors.password}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={state.password}
              onChange={(e)=>handleChange("password",e)}
            />

            <TextField
              error={errors.confirmPassword}
              helperText={errors.confirmPassword}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Confirm Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={state.confirmPassword}
              onChange={(e)=>handleChange("confirmPassword",e)}
            />
            
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={submit}
            >
              Register
            </Button>
            
            <Grid container>            
              <Grid item>
                <Link href="/login" variant="body2">
                  {"Already have an account? Login"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        
      </Container>
    );
  }
  
  
  }


import React,{useState, useEffect} from 'react';
import clsx from 'clsx';
import {doGet, doPost, doDelete, doPatch} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {productsDummy, messagesDummy} from 'utils/dummy'
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Remove from '@material-ui/icons/Remove';
import Search from '@material-ui/icons/Search';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import DebouncedTextField from 'components/DebouncedTextField.js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Pill from 'components/Pill';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MoreVert from '@material-ui/icons/MoreVert';
import StarOutline from '@material-ui/icons/StarOutline';
import Star from '@material-ui/icons/Star';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Visibility from '@material-ui/icons/Visibility';
import numeral from 'numeral'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import { generalListOptionMapper } from 'utils/mappers.js';
import Hidden from '@material-ui/core/Hidden';
import Conditional from 'components/Conditional';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';


const Message =(props)=> {

  let { path, url } = useRouteMatch();
  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const user = useSelector(state => state.user);
  const [messages, setmessages] = useState([]);
  const [openDialog, setopenDialog] = React.useState(false);
  const [dialogWidth, setdialogWidth] = useState('sm');
  const [dialogContent, setdialogContent] = useState(null);
  const [dialogTitle, setdialogTitle] = useState('title');
  const [selectedMessage, setselectedMessage] = useState(null);


  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }
  
  const back =()=>{
    if(selectedMessage){
        setselectedMessage(null)
    }else{
        history.goBack()
    }
    
  }

  const getMessages =async()=>{
    const params={}
        
    const response = await doGet(endpoint.category, params, getDefaultHeader());        
    if(!response.error){
      setmessages(response.data)
    }
  } 
  
  const closeDialog =()=>{    
    setopenDialog(false)
  }

  const selectMessage =(msg)=>{    
    setselectedMessage(msg)
  }


  

  useEffect(() => {
    getMessages()    
  }, [])  
  

  if(user && user.token){
    return (
      <React.Fragment>
        <CssBaseline/>
        <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
            <Grid item xs={1}>              
                <IconButton color="primary" aria-label="go back" component="span" onClick={back}>
                  <ArrowBack />
                </IconButton>              
            </Grid>                     
            <Grid container justify="center" style={{height:'92%'}}>
                <Conditional condition={selectedMessage===null || mdUp}>
                    <Grid container item alignItems="flex-start" xs={12} md={4} className={classes.left}>
                        <Grid container justify="space-between" alignItems="flex-end" style={{borderBottom:'solid 1px rgba(0, 0, 0, 0.12)'}}>              
                            <IconButton color="primary" aria-label="go back" component="span" onClick={back}>
                                <ChevronLeft />
                            </IconButton>              
                            <IconButton color="primary" aria-label="go back" component="span" onClick={back}>
                                <ChevronRight />
                            </IconButton>                                         
                        </Grid>
                        
                        <List component="nav" aria-label="messages" style={{width:'100%'}}>
                            {
                                [1,2,3,4,5,6,7,8,9,10].map(message=>(
                                    <>
                                    <ListItem 
                                    selected={false} 
                                    button 
                                    onClick={()=>selectMessage(message)}
                                    >
                                        <ListItemText primary={"message"} />                                            
                                    </ListItem>
                                    <Divider />
                                    </>
                                ))
                            }
                            
                        </List> 
                    </Grid>
                </Conditional>
                
                <Hidden mdDown>
                    <Grid container item xs={12} md={7} alignItems="flex-start" className={classes.right}>
                        <Grid container alignItems="center" style={{padding:8}}>                                                                    
                                                
                        </Grid>
                    </Grid>
                </Hidden>

                <Hidden mdUp>
                    <Conditional condition={selectedMessage!==null}>
                        <Grid container item xs={12} alignItems="flex-start" className={classes.right}>
                            <Grid container alignItems="center" style={{padding:8}}>                                                                    
                                    Detail               
                            </Grid>
                        </Grid>
                    </Conditional>
                </Hidden>
            </Grid>
            
        </Grid>

        <Dialog
        fullWidth={true}
        maxWidth={dialogWidth}
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
        </Dialog>
        
      </React.Fragment>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

const useStyles = makeStyles((theme) => ({
    marginTop16:{
      marginTop:16  
    },  
    marginH4:{
      marginLeft:4,
      marginRight:4,
    },  
    root: {
      padding: theme.spacing(1)
    },    
    left:{      
        background:'white'  ,
        padding:'0 8px',
        border:'1px solid #e5e5e5',
        borderRadius:4,
        height:'auto'
    },
    right:{
        background:'white'  ,
        padding:'0 8px',     
        border:'1px solid #e5e5e5',
        borderRadius:4   ,
        height:'auto',
        marginLeft:16
    },
    productItem:{
        width:'100%', 
        margin:'8px 0', 
        padding:8,
        cursor:'pointer'
    },
    selectedProductItem:{
      width:'100%', 
        margin:'8px 0', 
        padding:8,
        cursor:'pointer',
        background:'#ebebeb'
    }
    
  }));


export default Message;

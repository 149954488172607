import './App.css'
import { Provider } from "react-redux"
import { PersistGate } from 'redux-persist/integration/react'
//import returnStoreAndPersistor from 'reduxs/store'
import store from 'reduxs/store'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Login from "./features/login/Login.js"
import Register from "./features/register/Register.js"
import Activation from "./features/activation/Activation.js"
import ForgotPassword from "./features/password/ForgotPassword.js"
import ChangePassword from "./features/password/ChangePassword.js"
import Home from "./features/home/Home.js"
import {ThemeProvider} from '@material-ui/core/styles';
import theme from 'utils/theme.js'
import ResendActivation from 'features/activation/ResendActivation'


function App() {
  //const { store, persistor } = stores()

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>        
          <Router>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/home" component={Home} />
                <Route path="/admin" component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route path="/activation/:id" component={Activation} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/change-password/:id" component={ChangePassword} />
                <Route path="/resend-activation" component={ResendActivation} />
              </Switch>
          </Router>        
        
      </Provider>
    </ThemeProvider>
  );
}

/*
<ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/home" component={Home} />
                <Route path="/admin" component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route path="/activation/:id" component={Activation} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/change-password/:id" component={ChangePassword} />
                <Route path="/resend-activation" component={ResendActivation} />
              </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
*/

export default App;

/*
function name = action name
author alka@2019
*/

export function setUserLogin(user){
    return{type:'setUserLogin', payload:user}
}

export function setCategory(category){
    return{type:'setCategory', payload:category}
}

export function addCart(count){
    return{type:'addCart', payload:count}
}

export function setCartCount(count){
    return{type:'setCartCount', payload:count}
}

export function setMessageCount(count){
    return{type:'setMessageCount', payload:count}
}


export function logout(){
    return{type:'logout'}
}

//available v 'success', 'warning', 'error', 'info'
export function showSnackbar(v,t){
    return{type:'showSnackbar',payload:{v:v,t:t}}
}

export function successSnackbar(t){
    return{type:'showSnackbar',payload:{v:'success',t:t}}
}

export function errorSnackbar(t){
    return{type:'showSnackbar',payload:{v:'error',t:t}}
}

export function infoSnackbar(t){
    return{type:'showSnackbar',payload:{v:'info',t:t}}
}

export function hideSnackbar(){
    return{type:'hideSnackbar'}
}

export function loading(bool){
    return{type:'loading',payload:bool}
}

export function setActiveMenu(menu){
    return{type:'setActiveMenu',payload:menu}
}

export function setProductJenjang(data){
    return{type:'setProductJenjang',payload:data}
}

export function setProductKelas(data){
    return{type:'setProductKelas',payload:data}
}

export function setProductKelasOptions(data){
    return{type:'setProductKelasOptions',payload:data}
}

export function setProductJenjangOptions(data){
    return{type:'setProductJenjangOptions',payload:data}
}

export function setUnpaidBill(data){
    return{type:'setUnpaidBill',payload:data}
}

import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import AlkaSnackbar from 'components/AlkaSnackbar'
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import clsx from "clsx";
import Drawer from '@material-ui/core/Drawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Route,Redirect,Switch,useRouteMatch,useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, setActiveMenu,hideSnackbar, setCartCount, setMessageCount } from "reduxs/actions";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Dashboard from "features/dashboard/Dashboard.js"
import HomeContent from "./HomeContent.js"
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import Person from '@material-ui/icons/Person';
import Protected from 'components/Protected';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Profile from './Profile.js';
import Sidebar from './Sidebar.js';
import LinearProgress from '@material-ui/core/LinearProgress';
import Conditional   from 'components/Conditional.js';
import Store from 'features/store/Store';
import UserProductList from 'features/product/UserProductList';
import UserProductDetail from 'features/product/UserProductDetail';
import Approval from 'features/approval/Approval';
import Cart from 'features/cart/Cart';
import AdminProduct from 'features/product/AdminProduct';
import AdminOrder from 'features/order/AdminOrder';
import UserOrder from 'features/order/UserOrder';
import Badge from '@material-ui/core/Badge';
import Setting from 'features/setting/Setting';
import { useLocalStorage } from 'react-use';
import Message from 'features/message/Message.js';
import Summary from 'features/summary/Summary.js';


const Home =(props)=> {
  const [persistedUser, persistUser, clearPersistedUser] = useLocalStorage('user', {});
  const classes = useStyles()
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  let history = useHistory();
  let { path, url } = useRouteMatch();
  const dispatch = useDispatch()
  const closeSnackBar = () => {dispatch(hideSnackbar())}
  const ui = useSelector(state => state.ui);
  const cartCount = useSelector(state => state.cartCount);
  const messageCount = useSelector(state => state.messageCount);
  const user = useSelector(state => state.user);
  const isCartEmpty = useSelector(state => state.isCartEmpty);    
  const setting = useSelector(state => state.setting);
  const [anchorProfile, setAnchorProfile] = React.useState(null);
  const [openMenu, setopenMenu] = React.useState(false);
  const [activeMenu, setactiveMenu] = React.useState("/");

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  } 

  const closeMenu = () => {
    setopenMenu(false);
  };
  
  const profileClick = (event) => {
    setAnchorProfile(event.currentTarget);
  };

  const profileClose = () => {
    setAnchorProfile(null);
  };

  const onLogout = () => {
    clearPersistedUser()
    dispatch(logout());
  };

  const redirect =(path)=>{
    closeMenu()
    history.push(path)
  }

  const goHome =()=>{
    if(user.roles.id==2)  {
      history.push('/home/store')
    }else{      
      history.push('/admin/product')
    }
  }

  const gotoCart =()=>{
    history.push('/home/cart')
  }

  const gotoMessage =()=>{
    history.push('/home/message')
  }

  const getCartCount =async()=>{
    const payload = {}
    const response = await doGet(endpoint.cart_count, payload,getDefaultHeader())    
    
    if(response && !response.error){      
      dispatch(setCartCount(response.data))  
    }
  }

  const getMessageCount =async()=>{
    const payload = {}
    const response = await doGet(endpoint.cart_count, payload,getDefaultHeader())    
    
    if(response && !response.error){      
      dispatch(setMessageCount(response.data))  
    }
  }  
  
  
  useEffect(() => {
    if(user){
      getCartCount()
      getMessageCount()
    }
  }, [user])


  if(user && user.token){
  
    return (
      <Grid container justify="center" className={classes.root}>
        <Hidden mdDown>
          <Grid container alignItems="flex-start" alignContent="flex-start" item lg={2} className={classes.sideBar}>
            <Grid container justify="center" alignContent="center" className={classes.sideBarHeader}>
              <Typography onClick={goHome} color="primary" variant="h6" style={{fontWeight:900, cursor:'pointer'}}>
                Toko Pahoa
              </Typography>
            </Grid>
            <Grid container justify="center" alignItems='flex-start' alignContent="flex-start" className={classes.sideBarContent}>
              <Sidebar justify="center" user={user} itemClick={redirect} activeMenu={activeMenu}/>
            </Grid>
          </Grid>
        </Hidden>  

        <Grid container item xs={12} sm={12} md={12} lg={10} className={classes.main}>                    
          <Grid container alignContent="center" className={clsx({
              [classes.mainAppBarFull] : !lg,
              [classes.mainAppBar] : lg
          })}
          >
            <Grid xs={6} sm={6} md={6} lg={6} wrap="nowrap" container alignItems="center" alignContent="center" className={classes.sideBarHeader}>
              <Hidden lgUp>
                <IconButton
                onClick={()=>setopenMenu(true)}
                size="medium"
                aria-label="menu"
                style={{margin:0}}>
                  <MenuIcon color="primary" />
                </IconButton>
                  
                <Typography onClick={goHome} color="primary" variant="h6" style={{fontWeight:900, cursor:'pointer'}}>
                  Toko Pahoa
                </Typography>
              </Hidden>
            </Grid>


            <Grid xs={6} sm={6} md={6} lg={6} wrap="nowrap" container justify="flex-end" alignContent="center" alignItems="center">                              
              <Conditional  condition={"fitur" === "sudah ada"}>
                <IconButton
                onClick={gotoMessage}
                size="small"
                aria-label="close"
                style={{margin:'0 16px'}}>
                  <Badge color="secondary" badgeContent={messageCount} invisible={messageCount<1}>
                    <Icon >mail</Icon>
                  </Badge>
                </IconButton>
              </Conditional>
              

              <Protected user={user} allowedCodes="std">
                <IconButton
                onClick={gotoCart}
                size="small"
                aria-label="close"
                style={{margin:'0 16px'}}>
                  <Badge color="secondary" badgeContent={cartCount} invisible={cartCount<1}>
                    <Icon >shopping_cart</Icon>
                  </Badge>
                </IconButton>
              </Protected>
              
              
              <Avatar onClick={profileClick} className={classes.avatarProfile}>
                <Person />
              </Avatar>
            </Grid>   
            <Grid container className={classes.progressBarWrapper}>
              
              <Conditional condition={ui&&ui.loading===true}>
                <LinearProgress className={classes.progressBar}/>  
              </Conditional>
            </Grid>         
          </Grid>

          <Grid container className={classes.mainContent}>
            <Switch>
              <Route exact  path='/home' component={HomeContent}/>
              <Route        path='/home/dashboard' component={Dashboard}/>              
              <Route exact  path='/home/store' component={Store}/>  
              <Route exact  path='/home/store/product' component={UserProductList}/>              
              <Route        path='/home/store/product/:id'>              
                <UserProductDetail getCartCount={getCartCount}/>
              </Route>
              <Route        path='/home/cart'>              
                <Cart getCartCount={getCartCount}/>
              </Route>
              <Route exact  path='/home/message' component={Message}/>  
              <Route        path='/admin/product' component={AdminProduct}/>  
              <Route        path='/admin/order' component={AdminOrder}/>
              <Route        path='/home/order' component={UserOrder}/>  
              <Route        path='/admin/setting' component={Setting}/>    
              <Route        path='/admin/approval' component={Approval}/> 
              <Route        path='/admin/summary' component={Summary}/> 
                         
            </Switch>
          </Grid>
        </Grid>

        <AlkaSnackbar
          show={ui.snack_show}
          variant={ui.snack_var}
          txt={ui.snack_txt}
          close={closeSnackBar}
        />

        <Popover
          id="popUpProfile"
          open={Boolean(anchorProfile)}
          anchorEl={anchorProfile}
          onClose={profileClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={classes.popUpProfile}
          style={
            {marginTop:8}
          }
        >
          <Profile user={user} logout={onLogout}/>
        </Popover>

        <Drawer anchor="top" open={openMenu} onClose={closeMenu}>
          <Sidebar onClose={closeMenu} justify="flex-start" user={user} itemClick={redirect} activeMenu={setting.active_menu}/>
        </Drawer>

      </Grid>
    );
  }else{
    return <Redirect to={{ pathname: "/login" }} />
  }
}

export default Home




export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:theme.palette.defaultBackground,
    height:'100vh',
    overflowY:'hidden'
  },
  sideBar:{
    height:'100vh',
    borderRight:'1px #e1e2e4 solid'
  },
  sideBarHeader:{
    height:'3.5rem',
  },
  sideBarContent:{
    height:'91vh',
    overflowY:'scroll',
  },
  main:{
    height:'100vh',
    overflowY:'scroll'
  },
  mainAppBar:{
    position:'fixed',
    display: 'flex',
    padding:theme.spacing(2),
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    height:'3.5rem',
    left:'16.7%',
    width:'83.2%',
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow:'rgba(25, 42, 70, 0.13) 20px 8px 20px 0px',
    zIndex:1100
  },
  mainAppBarFull:{
    position:'fixed',
    display: 'flex',
    padding:theme.spacing(2),
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    height:'3.5rem',    
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow:'rgba(25, 42, 70, 0.13) 20px 8px 20px 0px',
    zIndex:1100
  },
  mainContent:{
    minHeight:'91vh',
    marginTop:'3.5rem'
  },

  avatarProfile:{

  },

  role:{
    padding:4,
    border:'1px solid',
    borderColor:theme.borderColor,
    margin:'0 2px 0 2px',
    borderRadius:4
  },
  popUpProfile:{
    top:theme.spacing(2),
  },
  progressBar:{
    zIndex:1300,
    width:'inherit'
  },
  progressBarWrapper:{
    height:1
  }

}));

import React,{useState, useEffect} from 'react';
import {doPost, doPut} from "utils/apiUtil.js"
import {endpoint} from 'utils/constants.js'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import ImageUploader from "react-images-upload";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Conditional from 'components/Conditional.js';

const CategoryForm =(props)=> {
  const { user, handleClose, actionType, category, getCategories} = props
  const classes = useStyles();
  const [state, setstate] = useState(null)
  const [errors, seterrors] = useState({
    username:null,
    password:null
  });
  const [browseFile, setbrowseFile] = useState(true)

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    
    if(fieldName==="isMain"){
      value = eventValue.target.checked? 1:0
      newState[fieldName] = value
    }
    else if(fieldName==="image"){
      newState[fieldName] = [eventValue]
    }else{
      newState[fieldName] = value
    }    
    setstate(newState)
  }

  const add=async()=>{
    let newErrors = []
    
    let payload = new FormData();
    payload.append("name", state.name);
    payload.append("isMain", state.isMain);
    if(state.image[0]){
      payload.append("image", state.image[0][0], state.image[0][0].name);
    }    
    
    const response = await doPost(endpoint.category, payload,"add category",getDefaultHeader())
    
    if(response.error){
      for (let key in response.error.errors) {
        newErrors[key] = response.error.errors[key]
      }
    }else{
      handleClose()        
      getCategories()
    }      
    
  }

  const edit=async()=>{
    let newErrors = []
    
    let payload = new FormData();
    payload.append("id", state.id);
    payload.append("name", state.name);
    payload.append("isMain", state.isMain);
    if(Array.isArray(state.image)){
      payload.append("image", state.image[0][0], state.image[0][0].name);
    }else{
      payload.append("image", state.image);
    }    
    
    const response = await doPost(endpoint.category, payload,"edit category",getDefaultHeader())
    
    if(response.error){
      for (let key in response.error.errors) {
        newErrors[key] = response.error.errors[key]
      }
    }else{
      handleClose()        
      getCategories()
    } 
  }
  
  const submit = ()=>{
    if(actionType==="add"){
        add()
    }else if(actionType==="edit"){
        edit()
    }
  }


  useEffect(() => {
    if(category){
        setbrowseFile(false)
        setstate({
            id:category.id,
            name:category.name,
            isMain:category.isMain,
            image:category.image
        })
    }else{
        setbrowseFile(true)
        setstate({
            name:"",
            isMain:0,
            image:""
        })
    }
  }, [category])

  
    if(state){
      return (
        <>
          <DialogContent>
            <Grid container justify='center' alignItems='flex-start'>
              <DebouncedTextField
                margin="dense"
                id="Name"
                label="Name"
                fullWidth
                value={state.name}
                onChange={(e)=>handleChange("name",e)}
              />
            </Grid> 
            

          <Conditional condition={browseFile===false || (state.image instanceof String && state.image!=="")}>
            <Grid container justify='center' alignItems='flex-start'>
              <img 
              onClick={()=>setbrowseFile(true)}
              src={state.image} 
              alt="image" 
              style={{height:200, cursor:'pointer'}} />
            </Grid>
          </Conditional>
          

          <Conditional condition={browseFile===true || state.image===""}>
            <Grid container justify='center' alignItems='flex-start'>
                <ImageUploader
                  singleImage={true}
                  withIcon={true}
                  buttonText="Choose images"
                  onChange={(e)=>handleChange('image',e)}
                  imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif",".webp"]}
                  maxFileSize={5242880}
                  withPreview={true}
                />
            </Grid>
          </Conditional> 

            <Grid container alignItems='flex-start' style={{marginTop:16}}>
            <FormControlLabel
                value="isMain"
                control={
                    <Switch 
                    color="primary" 
                    checked={Number(state.isMain)===1} 
                    onChange={(e)=>handleChange("isMain",e)}
                    />
                  }
                label="Main category"
                labelPlacement="start"
              />
          </Grid>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Kembali
            </Button>
            <Button variant="contained" onClick={submit} color="primary">
              Simpan
            </Button>
          </DialogActions>
        </>    
        );
    }else{
      return null
    }
}

export default CategoryForm;

const useStyles = makeStyles((theme) => ({

}));

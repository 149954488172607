import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {doGet, doPost, doDelete, doUpload} from 'utils/apiUtil.js'
import { useSelector } from 'react-redux';
import {endpoint} from 'utils/constants.js'
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DebouncedTextField from 'components/DebouncedTextField.js';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme'
import { generalListOptionMapper, generalOptionMapper,jenjangListOptionMapper,sizeListOptionMapper } from 'utils/mappers.js';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormatCustom2 from 'components/NumberFormatCustom2.js';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Conditional from 'components/Conditional';
import ImageUploader from "react-images-upload";
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import { SignalCellularNullSharp } from '@material-ui/icons';
import RichTextEditor from 'react-rte-17';


const ProductForm =(props)=> {
  const { getProducts, handleClose, actionType, product, category, categories } = props
  const user = useSelector(state => state.user);
  const classes = useStyles();
  const [openDialog, setopenDialog] = React.useState(false);
  const [dialogWidth, setdialogWidth] = useState('sm');
  const [dialogContent, setdialogContent] = useState(null);
  const [dialogTitle, setdialogTitle] = useState('title');  
  const [state, setstate] = useState(null)
  const [categoryState, setcategory] = useState(null)
  const [selectedImage, setselectedImage] = useState(null)
  const [sizeGroupOptions, setsizeGroupOptions] = useState([])  
  const [sizeOptions, setsizeOptions] = useState([])
  const [categoryOptions, setcategoryOptions] = useState([])
  const [jenjangOptions, setjenjangOptions] = useState([])
  const [kelasOptions, setkelasOptions] = useState([])
  const [browseFile, setbrowseFile] = useState(false)
  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

  
  const getsizeGroupOptions =async()=>{
    const params={}
        
    const response = await doGet(endpoint.size_group, params, getDefaultHeader());        
    if(!response.error){
      setsizeGroupOptions(generalListOptionMapper(response.data))      
    }
  }
  
  const getJenjangOptions =async()=>{
    const params={}
        
    const response = await doGet(endpoint.jenjang_options, params, getDefaultHeader());        
    if(!response.error){
      setjenjangOptions(jenjangListOptionMapper(response.data))      
    }
  }

  const getKelasOptions =async(jenjangId)=>{
    
    const params={
      stage_id:jenjangId
    }
        
    const response = await doGet(endpoint.admin_kelas_options, params, getDefaultHeader());        
    if(!response.error){
      setkelasOptions(generalListOptionMapper(response.data))      
    }
  }

  const getsizeOptions =async(sizeGroup)=>{    
    const params = {
      group_size_id:sizeGroup.id
    }
    const response = await doGet(endpoint.size_options, params, getDefaultHeader());        
    if(!response.error){
        return sizeListOptionMapper(response.data).sort((a, b) => (a.order > b.order) ? 1 : -1)
    }
    
  }

  const categoryChange =(e)=>{    
    setcategory(e)
  }
  
  const closeDialog =()=>{    
    setopenDialog(false)
  }

  const deleteImage =()=>{    
    let images = state.images.filter(image=>image.id!==selectedImage.id)
    let deletedImages = [...state.deletedImages, selectedImage]    

    setstate({
        ...state,
      images:images,
      deletedImages:deletedImages
    })

    setselectedImage(null)
    setopenDialog(false)
  }
  
  const deleteImageConfirmation =()=>{    
    setopenDialog(true)
    setdialogWidth('sm')
    setdialogTitle("Hapus Gambar")
    setdialogContent(<DeleteConfirmation handleDelete={deleteImage} handleClose={closeDialog} />)
    
  }



  const handleChange = async(fieldName,item,eventValue)=>{
    let newState = {...state}
    let value = eventValue
    
    if(fieldName==="sizeType"){
      value = parseInt(eventValue.target.value)
      
      if(value===1){
        const sizeGroup = sizeGroupOptions.filter(group=>group.single===1)[0]
        if(sizeGroup){
          newState['sizeGroup'] = sizeGroup

          const sizes = await getsizeOptions(sizeGroup)        
          //console.log(sizes)
          setsizeOptions(sizes)      
                  
          newState['sizes'] = sizes
      
          newState['stocks'] = sizes.map(size=>({
            sizeId:size.id,
            sizeName:size.name,
            order:size.order,
            stock:0
          }))
                    
      
          newState['prices'] = sizes.map(size=>({
            sizeId:size.id,
            sizeName:size.name,
            order:size.order,
            price:0
          }))
      
          newState['priceType'] = 1
        }
        
      }else{
        newState['sizeGroup'] = null
        newState['sizes'] = []
        newState['stocks'] = []
        newState['prices'] = []
        setsizeOptions([])
      }
    }

    if(fieldName==='prices'){
      if(state.priceType===1){
        value = state.prices.map(price=>({
          ...price,price:eventValue.target.value
        }))
      }else{
        const oldPrice = state.prices.filter(price=>(price.sizeId===item.sizeId))[0]
        const  newPrice = {...oldPrice, price:eventValue.target.value}

        let others = state.prices.filter(price=>(price.sizeId!==item.sizeId))
        const sorted = [...others, newPrice].sort((a, b) => (a.order > b.order) ? 1 : -1)
        value = sorted
      }      
      
    }

    if(fieldName==='priceType'){
      value = parseInt(eventValue.target.value)
      newState['prices'] = state.sizes.map(size=>({          
        sizeId:size.id,
        sizeName:size.name,
        price:0,
        order:size.order
      })).sort((a, b) => (a.order > b.order) ? 1 : -1)      
    }
    

    if(fieldName==='isNoteRequired'){
      value = eventValue.target.checked===true ? 1 : 0
    }

    if(fieldName==='sizes'){
      
      if(eventValue.target.checked===true){
        value = [...state.sizes, item]
         
        const sortedStocks = [...state.stocks, {
          sizeId:item.id,
          sizeName:item.name,
          stock:0,
          order: item.order
        }].sort((a, b) => (a.order > b.order) ? 1 : -1)
        newState['stocks'] = sortedStocks

        const sortedPrices = [...state.prices, {
          sizeId:item.id,
          sizeName:item.name,
          price:0,
          order:item.order
        }].sort((a, b) => (a.order > b.order) ? 1 : -1)
        newState['prices'] = sortedPrices       
        
        

      }else{
        value = state.sizes.filter(size=>(size.id!==item.id))
        newState['stocks'] = state.stocks.filter(stock=>(stock.sizeId!==item.id))
        newState['prices'] = state.prices.filter(price=>(price.sizeId!==item.id))
        
      }
      
    }

    if(fieldName==="sizeGroup"){
      
      let sizes = await getsizeOptions(value)
      setsizeOptions(sizes)      

      newState['sizes'] = sizes

      newState['stocks'] = sizes.map(size=>({
        sizeId:size.id,
        sizeName:size.name,
        stock:0,
        order:size.order
      }))

      newState['prices'] = sizes.map(size=>({
        sizeId:size.id,
        sizeName:size.name,
        price:0,
        order:size.order
      }))
    }

    if(fieldName==='jenjangs'){
      if(value){
        getKelasOptions(value.map(jenjang=>(jenjang.id)))
      }      
    }

    if(fieldName==='kelases'){
      if(value===null){
        value = []
      }      
    }

    
    newState[fieldName] = value
    
    setstate(newState)
    
  }

  const add=async()=>{    
    let newErrors = []          
    
    let payload = new FormData();
    payload.append("cover", state.cover);
    payload.append("category", categoryState.id);
    payload.append("name", state.name);
    payload.append("isNoteRequired", state.isNoteRequired);
    payload.append("noteIntruction", state.noteIntruction);    
    payload.append("description", state.description.toString('html'));
    payload.append("sizeType", state.sizeType);
    payload.append("sizeGroup", state.sizeGroup.id);        
    payload.append("priceType", state.priceType);
    if(state.jenjangs.length>0){
      payload.append("jenjangs", JSON.stringify(state.jenjangs.map(jenjang=>({id:jenjang.id, name:jenjang.name}))));
    }
    if(state.kelases.length>0){
      payload.append("kelases", JSON.stringify(state.kelases.map(kelas=>({id:kelas.id, name: kelas.name, stage_id:kelas.stage_id}))));
    }       
    
    payload.append("sizes", JSON.stringify(state.sizes.map(size=>(size.id))));
    payload.append("prices", JSON.stringify(state.prices.map(price=>({sizeId:price.sizeId, price:price.price}))));
    payload.append("deletedImages", JSON.stringify(state.deletedImages.map(img=>(img.id))));
    
    for( var i = 0; i < state.newImages.length; i++ ){
      let file = state.newImages[i];    
      payload.append('newImages[' + i + ']', file);
    }
               
    const response = await doUpload(endpoint.product, payload,getDefaultHeader())
    
    if(response && response.error){
      for (let key in response.error.errors) {
        newErrors[key] = response.error.errors[key]
      }
    }else{
      handleClose()        
      getProducts()
    } 
  }

  const edit=async()=>{
    
    
    let newErrors = []          
    
    let payload = new FormData();
    payload.append("cover", state.cover);
    payload.append("id", state.id);
    payload.append("category", categoryState.id);
    payload.append("name", state.name);
    payload.append("isNoteRequired", state.isNoteRequired);
    payload.append("noteIntruction", state.noteIntruction);
    payload.append("description", state.description.toString('html'));
    payload.append("sizeType", state.sizeType);
    payload.append("sizeGroup", state.sizeGroup.id);        
    payload.append("priceType", state.priceType);
    if(state.jenjangs.length>0){
      payload.append("jenjangs", JSON.stringify(state.jenjangs.map(jenjang=>({id:jenjang.id, name:jenjang.name}))));
    }
    if(state.kelases.length>0){
      payload.append("kelases", JSON.stringify(state.kelases.map(kelas=>({id:kelas.id, name: kelas.name, stage_id:kelas.stage_id}))));
    }    
    
    payload.append("sizes", JSON.stringify(state.sizes.map(size=>(size.id))));
    payload.append("prices", JSON.stringify(state.prices.map(price=>({sizeId:price.sizeId, price:price.price}))));
    payload.append("deletedImages", JSON.stringify(state.deletedImages.map(img=>(img.id))));
    
    for( var i = 0; i < state.newImages.length; i++ ){
      let file = state.newImages[i];    
      payload.append('newImages[' + i + ']', file);
    }
               
    const response = await doUpload(endpoint.product, payload,getDefaultHeader())
    
    if(response && response.error){
      for (let key in response.error.errors) {
        newErrors[key] = response.error.errors[key]
      }
    }else{
      handleClose()        
      getProducts()
    } 
  }

  const btnAddImage=()=>{
    setbrowseFile(true)
  }

  const imageClick=(image)=>{
    if(selectedImage===null || selectedImage!==image){
      setselectedImage(image)  
    }else{
      setselectedImage(null)  
    }    
    
  }
  
  const submit = ()=>{
    if(actionType==="add"){
        add()
    }else if(actionType==="edit"){
        edit()
    }
  }

  
  const isSizeInclude=(s)=>{
    let result = state.sizes.filter(size=>(size.id===s.id))
    
    if(result[0]){
      return true
    }else{
      return false
    }
  }
  
  const getSizeOptionTmp = async(sizeGroup)=>{
    let sizes = await getsizeOptions(sizeGroup)
    setsizeOptions(sizes)
  }

  const setAsCover = ()=>{
    setstate({...state, cover:selectedImage.path}) 
  }

  const reset=async()=>{
    
    if(product){
      
      getSizeOptionTmp(product.group_size)

      setstate({
          ...product,
          id:product.id,
          cover:product.cover,
          name:product.name,        
          noteIntruction:product.noteIntruction,  
          description:RichTextEditor.createValueFromString(product.description, 'html'),
          sizeType:product.sizeType,
          sizeGroup: generalOptionMapper(product.group_size),
          sizes:product.sizes.map(size=>({
            id:size.id,
            name:size.size_label,
            order:size.order
          })),
          stocks:product.stocks.map(stock=>({
            id:stock.id,
            sizeId:stock.size.id,
            sizeName:stock.size.size_label,
            order:stock.size.order,
            stock:parseInt(stock.stock)
          })),
          priceType:product.priceType,
          prices:product.prices.map(price=>({
            id:price.id,
            sizeId:price.size.id,
            sizeName:price.size.size_label,
            order:price.size.order,
            price:price.price
          })),
          images:product.images,
          newImages:[],
          deletedImages:[]          
      })

      if(product.jenjangs && product.jenjangs.length>0){
        getKelasOptions(product.jenjangs.map(jenjang=>(jenjang.id)))
      }
      
  }else{
      let initialState = {
        cover:"",
        name:"",
        description:RichTextEditor.createEmptyValue(),
        noteIntruction:"",
        sizeType:1,           
        sizeGroup: null, 
        sizes:[],     
        stocks:[],
        priceType:1,
        prices:[],
        images:[],
        newImages:[],
        deletedImages:[],
        jenjangs:[],
        kelases:[]
      }

        const sizeGroup = sizeGroupOptions.filter(group=>group.single===1)[0]
        if(sizeGroup){
          initialState['sizeGroup'] = sizeGroup

          const sizes = await getsizeOptions(sizeGroup)        
        
          setsizeOptions(sizes)      
                  
          initialState['sizes'] = sizes
      
          initialState['stocks'] = sizes.map(size=>({
            sizeId:size.id,
            sizeName:size.name,
            order:size.order,
            stock:0
          }))
                    
      
          initialState['prices'] = sizes.map(size=>({
            sizeId:size.id,
            sizeName:size.name,
            order:size.order,
            price:0
          }))
      
          initialState['priceType'] = 1
        }
        
        
        setstate(initialState)
      
  }
  }

  useEffect(() => {       

    if(sizeGroupOptions.length===0){
      getsizeGroupOptions() 
    } 
    getJenjangOptions()   
    if(sizeGroupOptions){
      reset()
    }    

  }, [product, sizeGroupOptions])

  useEffect(() => {       
    if(category){
      setcategory(generalOptionMapper(category))
    }    
  }, [category])

  useEffect(() => {       
    if(categories){
      setcategoryOptions(generalListOptionMapper(categories))
    }
  }, [categories])



  if(state){
    return (
      <>
        <DialogContent style={{height:400}}>        
        <Grid container justify="space-between" alignItems="flex-start">
          <Grid container  xs={2} className={classes.imageSelectedWrapper}>
            <img src={state.cover} width="100" height="100" className={classes.image}/>
          </Grid>
          <Grid container xs={9}>
            <Grid container xs={12}>
                <Select                
                  name="category"
                  placeholder = "pilih category"
                  options={categoryOptions}
                  onChange={categoryChange}
                  value={categoryState} 
                  styles={selectCustomZindex}
                />              
            </Grid>
            <Grid container item xs={12}>
              <DebouncedTextField
                margin="dense"
                id="Name"
                label="Name"  
                fullWidth            
                value={state.name}
                onChange={(e)=>handleChange("name",null,e)}
              />
            </Grid>
            <Grid container item xs={12}>                            
               <RichTextEditor
                value={state.description}
                onChange={(e)=>handleChange("description",null,e)}
              />
            </Grid>
          </Grid>
        </Grid>
          
          <Grid container alignItems="center" style={{marginTop:8}}>          
            <Grid container >
              <Grid item xs={2}>                            
                <FormControlLabel
                  control={<Checkbox checked={state.isNoteRequired===1} onChange={(e)=>handleChange('isNoteRequired',null,e)} name="Mandatory Note" />}
                  label="Mandatory Note"
                />                         
              </Grid>
              <Conditional condition={state.isNoteRequired===1}>
                <Grid item xs={5}>
                  <DebouncedTextField
                    margin="dense"
                    id="noteIntruction"
                    label="Note Instruction"  
                    fullWidth            
                    value={state.noteIntruction}
                    onChange={(e)=>handleChange("noteIntruction",null,e)}
                  />
                </Grid>
              </Conditional>
            </Grid>
            
            <Grid container item xs={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Ukuran</FormLabel>
                <RadioGroup value={state.sizeType} onChange={(e)=>handleChange("sizeType",null,e)} row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel
                    value={1}
                    control={<Radio color="primary" />}
                    label="Satu Ukuran"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio color="primary" />}
                    label="Banyak Ukuran"
                    labelPlacement="end"
                  />                
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container item xs={3}>
                <Select                
                  name="size"
                  placeholder = "pilih jenis ukuran"
                  options={sizeGroupOptions}
                  onChange={(e)=>handleChange("sizeGroup",null,e)}
                  value={state.sizeGroup}
                  styles={selectCustomZindex}
                />              
            </Grid>
            <Grid container item xs={12}>                            
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Pilihan Ukuran</FormLabel>
                <FormGroup row>
                  {
                    sizeOptions.map(size=>(
                      <FormControlLabel
                        control={<Checkbox checked={isSizeInclude(size)} onChange={(e)=>handleChange('sizes',size,e)} name={size.name} />}
                        label={size.name}
                      />
                    ))
                  }                                   
                </FormGroup>                
              </FormControl>  
                        
            </Grid>
                        
          </Grid>

          <Grid container >            
            <Grid container>              
              <Typography variant="subtitle1" style={{color:'#777582'}}>Stock</Typography>
            </Grid>
            <Grid container spacing={1}>              
              {
                state.stocks && state.stocks.map(stock=>(
                  <Grid item xs={2}>
                    <TextField
                      margin="dense"
                      id={"stock"+stock.sizeId}
                      name={"stock"+stock.sizeId}
                      label={stock.sizeName}
                      fullWidth
                      variant="outlined"
                      value={stock.stock}                  
                      readOnly
                    />
                  </Grid>
                ))
              }             
             
            </Grid>
          </Grid>
          
          <Grid container style={{marginTop:8}}>
            <Grid container item xs={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Harga</FormLabel>
                <RadioGroup value={state.priceType} onChange={(e)=>handleChange("priceType",null,e)} row aria-label="price Type" name="priceType">
                  <FormControlLabel
                    value={1}
                    control={<Radio color="primary" />}
                    label="Satu Harga"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio color="primary" />}
                    label="Sesuai Ukuran"
                    labelPlacement="end"
                  />                
                </RadioGroup>
              </FormControl>
            </Grid>
            
            <Grid container spacing={2} xs={12}>
              {
                state.prices.map(price=>(
                  <Grid item xs={2}>
                    <TextField
                      margin="dense"
                      id={"price"+price.sizeName}
                      name={price.sizeName}
                      label={price.sizeName}
                      fullWidth
                      value={price.price}                      
                      onChange={
                        (e)=>handleChange('prices',price,e)
                      }
                    />
                  </Grid>
                ))
              }              
            </Grid>            
          </Grid>
          
          <Grid container justify="space-between" style={{marginTop:8}}>

            <Grid container xs={12} md={5}>
              <Select                
                name="size"
                placeholder = "pilih jenjang"
                options={jenjangOptions}
                onChange={(e)=>handleChange("jenjangs",null,e)}
                value={state.jenjangs}
                styles={selectCustomZindex}
                isClearable={true}
                isMulti
              />
            </Grid>


            
            <Grid container xs={12} md={5}>
              <Select                
                name="size"
                placeholder = "pilih kelas"
                options={kelasOptions}
                onChange={(e)=>handleChange("kelases",null,e)}
                value={state.kelases}
                styles={selectCustomZindex}
                isClearable={true}
                isMulti
              />
            </Grid>
              
              
          </Grid>

          <Grid container spacing={1} justify="center" alignItems="center" className={classes.imageContainer}>            
            
            {
              state.images.map(image=>(
                <Grid container item justify="center" alignItems="center" className={clsx({
                  [classes.imageWrapper]:selectedImage==null || (selectedImage && selectedImage.id!==image.id), 
                  [classes.imageSelectedWrapper]:selectedImage && selectedImage.id===image.id})}>
                  <img onClick={()=>imageClick(image)} src={image.path} width="100" height="100" className={classes.image}/>
                </Grid>                
              ))
            }
            
            <Conditional condition={state.images.length===0}>
              <Grid container item xs={10} justify="flex-end" alignItems="center">
                no images... please add images
              </Grid>
            </Conditional>
            
            
            <Grid container item xs={2}  justify="center" alignItems="center" >
              <Conditional condition={selectedImage===null}>
                <IconButton onClick={btnAddImage} color="primary" aria-label="go back" component="span" >
                    <Add fontSize="large"/>
                </IconButton>
              </Conditional>
              <Conditional condition={selectedImage!==null}>
                <IconButton onClick={deleteImageConfirmation} color="secondary" aria-label="go back" component="span" >
                    <Delete fontSize="medium"/>
                </IconButton>              
              </Conditional>
              <Conditional condition={selectedImage!==null}>
                <Button color="primary" variant="outlined" onClick={setAsCover} >Set As cover image</Button>
              </Conditional>
            </Grid> 

            <Conditional condition={browseFile===true}>
            <Grid container justify='center' alignItems='flex-start'>
                <ImageUploader
                  withIcon={true}
                  buttonText="Choose images"
                  onChange={(e)=>handleChange('newImages',null,e)}
                  imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif",".webp"]}
                  maxFileSize={5242880}
                  withPreview={true}
                />
            </Grid>
          </Conditional>            
            
            
          </Grid>
         
          
        </DialogContent>
        <DialogActions>
          <Button onClick={reset} color="default">
            Reset
          </Button>
          <Button onClick={handleClose} color="secondary">
            Kembali
          </Button>
          <Button variant="contained" onClick={submit} color="primary">
            Simpan
          </Button>
        </DialogActions>


        <Dialog
        fullWidth={true}
        maxWidth={dialogWidth}
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
        </Dialog>
  </>
  
      );
      
  }else{
    return null
  }
  
}

export default ProductForm;

const useStyles = makeStyles((theme) => ({

  imageContainer:{
    marginTop:16,
    borderRadius:4,
    border:'1px solid #809bff',
    minHeight : 150
  },
  image:{
    width:'100%', 
    minHeight:100,     
    height:'auto',    
  },
  imageWrapper:{
    width:120, 
    height:120,
    margin:theme.spacing(1),
    borderRadius:theme.borderRadius,
    boxShadow:'3px 3px 5px 0px rgba(50, 50, 50, 0.35)',
    cursor:'pointer'
  },
  imageSelectedWrapper:{
    width:120, 
    height:120,
    height:'auto',
    margin:theme.spacing(1),
    borderRadius:theme.borderRadius,
    boxShadow:'3px 3px 5px 0px rgba(50, 50, 50, 0.35)',
    cursor:'pointer',
    border:'1px solid black'
  }
}));
